import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {BASE_URI} from "../../shared/Global";
import axios from "axios";
import {ConfiguratorContext} from "../../shared/contexts/ConfiguratorContext";
import Button from "@material-ui/core/Button";
import CardActionArea from "@material-ui/core/CardActionArea";
import {FINAL_PRODUCT, INFORMATION} from "../../shared/Global";
import {useParams, useHistory} from "react-router-dom";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import {jsPDF} from "jspdf";
import image from "../../shared/test.png"
import html2canvas from "html2canvas";

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
        marginTop: "2rem",
    },
    stepPaddingButton: {
        marginLeft: "1rem",
        marginTop: "1rem",
    },
    stepPaddingTitle: {
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    card: {
        width: "100%"
    },
    button: {
        width: "100%",
    },
    backButton: {
        marginTop: "2rem",
    },
    img: {
        display: "inline",
    },
    header: {
        display: "inline",
    },

}))

export default function SummaryDoneContainer(props) {
    const classes = useStyles()

    const {dealerModel, modelId,query,leadId} = useParams();
    const history = useHistory();

    useEffect(() => {
        if (FINAL_PRODUCT.price == 0) {
            let temp = `/${dealerModel}/${modelId}/builder`;
            history.push(temp);
        }
        INFORMATION.query = true
        exportToPDF();//TODO get the necessary information to do this
        context.setTransmissionData({})
    }, []);

    let {...context} = useContext(ConfiguratorContext);

    function _imageEncode(arrayBuffer, mimeType) {
        let u8 = new Uint8Array(arrayBuffer)
        let b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer), function (p, c) {
            return p + String.fromCharCode(c)
        }, ''))
        return "data:" + mimeType + ";base64," + b64encoded
    }

    const exportToPDF = () => {
        axios.get(`https://cors-anywhere.herokuapp.com/${INFORMATION.image}`, {
            responseType: 'arraybuffer'
        }).then(response => {
            let logo = _imageEncode(response.data, response.headers['content-type']);
            axios.get(`https://cors-anywhere.herokuapp.com/${FINAL_PRODUCT.image}`, {
                responseType: 'arraybuffer'
            }).then(response => {
                let vehicleImage = _imageEncode(response.data, response.headers['content-type']);

                let html = `
                    <!doctype html>
                    <html>
                    <head>
                        <meta charset="utf-8">
                        <title>A simple, clean, and responsive HTML invoice template</title>
                        
                        <style>
                        .invoice-box {
                            max-width: 800px;
                            margin: auto;
                            padding: 30px;
                            border: 1px solid #eee;
                            box-shadow: 0 0 10px rgba(0, 0, 0, .15);
                            font-size: 16px;
                            line-height: 24px;
                            font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
                            color: #555;
                        }
                        
                        .invoice-box table {
                            width: 100%;
                            line-height: inherit;
                            text-align: left;
                        }
                        
                        .invoice-box table td {
                            padding: 5px;
                            vertical-align: top;
                        }
                        
                        .invoice-box table tr td:nth-child(2) {
                            text-align: right;
                        }
                        
                        .invoice-box table tr.top table td {
                            padding-bottom: 20px;
                        }
                        
                        .invoice-box table tr.top table td.title {
                            font-size: 20px;
                            line-height: 20px;
                            color: #333;
                        }
                        
                        .invoice-box table tr.information table td {
                            padding-bottom: 20px;
                        }
                        
                        .invoice-box table tr.heading td {
                            background: #eee;
                            border-bottom: 1px solid #ddd;
                            font-weight: bold;
                        }
                        
                        .invoice-box table tr.details td {
                            padding-bottom: 20px;
                        }
                        
                        .invoice-box table tr.item td{
                            border-bottom: 1px solid #eee;
                        }
                        
                        .invoice-box table tr.item.last td {
                            border-bottom: none;
                        }
                        
                        .invoice-box table tr.total td:nth-child(2) {
                            border-top: 2px solid #eee;
                            font-weight: bold;
                        }
                        
                        /** RTL **/
                        .rtl {
                            direction: rtl;
                            font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
                        }
                        
                        .rtl table {
                            text-align: right;
                        }
                        
                        .rtl table tr td:nth-child(2) {
                            text-align: left;
                        }
                        </style>
                    </head>
                    
                    <body>
                        <div class="invoice-box">
                            <table cellpadding="0" cellspacing="0">
                                <tr class="top">
                                    <td colspan="2">
                                        <table>
                                            <tr>
                                              <td class="title">
                                                    <b>Vehicle Builder Brochure</b>
                                                </td>
                                                <td class="titleImage">
                                                    <img src='${logo}' style="width:100%; max-width:300px; float:right">
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                
                                <tr class="information">
                                    <td colspan="2">
                                        <table>
                                            <tr>
                                                <td>
                                                    Name: ${FINAL_PRODUCT.client.name}<br>
                                                    Mobile: ${FINAL_PRODUCT.client.telephone}<br>
                                                    Email: ${FINAL_PRODUCT.client.email}
                                                </td>
                                                
                                                <td>
                                                    Date: ${moment().format("YYYY-MM-DD")}<br>
                                                    Reference No: ${FINAL_PRODUCT.client.name}
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr class="information">
                                    <td colspan="2">
                                    <b>${FINAL_PRODUCT.title} ${FINAL_PRODUCT.subTitle}</b>
                                        <table>
                                            <tr>
                                                 <td class="titleImage">
                                                    <img src="${vehicleImage}" style="width:100%; max-width:400px;max-height: 250px;float:left">
                                                </td>
                                                <td >
                                                   Purchase Price:<br>
                                                   ${FINAL_PRODUCT.colourExterior.value}:<br>
                                                   ${FINAL_PRODUCT.colourInterior.value}:<br>
                                                   ${FINAL_PRODUCT.transmission.value}:<br>
                                                   ${FINAL_PRODUCT.accessories.value}:<br>
                                                   <b>TOTAL</b></p>
                                                </td>
                                                <td style=" float:right">
                                                   R  ${FINAL_PRODUCT.derivativePrice}<br>
                                                   R  ${FINAL_PRODUCT.colourExterior.price}<br>
                                                   R  ${FINAL_PRODUCT.colourInterior.price}<br>
                                                   R ${FINAL_PRODUCT.transmission.price}<br>
                                                   R ${FINAL_PRODUCT.accessories.price}<br>
                                                   <b>R ${FINAL_PRODUCT.price}</b>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr class="heading">
                                    <td>
                                        Payment Method
                                    </td>
                                    
                                    <td>
                                        Check #
                                    </td>
                                </tr>
                                
                                <tr class="details">
                                    <td>
                                        Check
                                    </td>
                                    
                                    <td>
                                        1000
                                    </td>
                                </tr>
                                
                                <tr class="heading">
                                    <td>
                                        Item
                                    </td>
                                    
                                    <td>
                                        Price
                                    </td>
                                </tr>
                                
                                <tr class="item">
                                    <td>
                                        Website design
                                    </td>
                                    
                                    <td>
                                        R 300.00
                                    </td>
                                </tr>
                                
                                <tr class="item">
                                    <td>
                                        Hosting (3 months)
                                    </td>
                                    
                                    <td>
                                       R 75.00
                                    </td>
                                </tr>
                                
                                <tr class="item last">
                                    <td>
                                        Domain name (1 year)
                                    </td>
                                    
                                    <td>
                                        R 10.00
                                    </td>
                                </tr>
                                
                                <tr class="total">
                                    <td></td>
                                    
                                    <td>
                                       Total: R 385.00
                                    </td>
                                </tr>
                              
                            </table>
                            <div style="font-size: 14px">
                                                   <p><b>Terms & Conditions</b></p>
                                               
                                                  <p>This pricing does not represent a formal offer by ${INFORMATION.title} or any of it's subsidiaries</p>
                                               
                                                 <p> Deal is subject to the approval terms and conditions of MFC, a division of Nedbank Limited An Authorised Financial Services and registered Credit
                                                    Provider. NCRCP16. The monthly payment excludes a once off initiation fee (R1 207.50) and excludes a monthly service fee (R69.00 per month). Deal
                                                    is for advertised specifications only; any additional accessories will be subject to separate terms and conditions. On road, costs are excluded. E&OE 
                    
                        </p></div></div>
                    </body>
                    </html>
              `
                let iframe = document.createElement('iframe');
                document.body.appendChild(iframe);

                let iframedoc = iframe.contentDocument || iframe.contentWindow.document;

                iframedoc.body.innerHTML = html;
                iframedoc.body.style.width = "780";
                iframedoc.body.style.height = "1500";

                html2canvas(iframedoc.body, {
                        "logging": true,
                        useCORS: true,
                        taintTest: false
                    }
                ).then((canvas) => {
                    document.body.removeChild(iframe);

                    let img = canvas.toDataURL("image/png");

                    let doc = new jsPDF();
                    doc.addImage(img, 'JPEG', 0, 0);
                    doc.save('Brochure.pdf');

                    let cancel;

                    let bodyFormData = new FormData();
                    bodyFormData.append('recipientEmail', FINAL_PRODUCT.client.email);
                    bodyFormData.append('subject', `${INFORMATION.title} Brochure`);
                    bodyFormData.append('body', `Please find attached the brochure for the ${FINAL_PRODUCT.title} ${FINAL_PRODUCT.subTitle} you built on the ${INFORMATION.title} platform`);
                    bodyFormData.append('attachment', new Blob([doc.output('blob')], {type: 'application/pdf'}), 'Brochure.pdf');

                    axios({
                        method: 'POST',
                        url: `${BASE_URI}/Email/Form`,
                        headers: {'Content-Type': 'multipart/form-data'},
                        data: bodyFormData,
                        cancelToken: new axios.CancelToken(c => cancel = c)
                    }).then((response) => {
                        console.log(response)
                    })
                }).catch(error => {
                    console.log("Error", error)
                });
            })
        })
    };

    return (
        <React.Fragment>
            <Grid container direction="row">
                <Grid item xs={12} md={7} lg={7}>
                    <Card className={classes.content} elevation={0}>
                        <CardContent>
                            <Typography align={"center"} variant={"h4"}>
                                Thank you. Your download will begin shortly
                                <hr style={{backgroundColor: INFORMATION.buttonColor, height: "2px", border: 0}}/>
                            </Typography>
                            <Typography align={"center"} className={classes.stepPaddingTitle} variant={"subtitle1"}>
                                Do you want to apply for this vehicle online?
                            </Typography>
                            <Grid container direction="row" justify={"center"}>
                                <Grid item className={classes.stepPaddingButton} xs={12} md={6} lg={6}>
                                    <Button className={classes.button} color="primary" variant={"contained"}
                                            onClick={() => {
                                                let temp = `/${dealerModel}/${modelId}/buy/tradeInQuestion/${leadId}`;
                                                history.push(temp);
                                            }}>
                                        Yes, take me there
                                    </Button>
                                </Grid>

                                <Grid item className={classes.stepPaddingButton} xs={12} md={6} lg={6}>
                                    <Button className={classes.button} color="primary" variant={"contained"}
                                            onClick={() => {
                                                let temp = `/${dealerModel}/${modelId}/build`;
                                                history.push(temp);
                                            }}>
                                        No, I do not want to apply
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item className={classes.stepPaddingImage} xs={12} md={5} lg={5}>
                    <Card className={classes.content}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                alt="Features"
                                height="350"
                                image={FINAL_PRODUCT['image']}
                                title={FINAL_PRODUCT['title']}
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
