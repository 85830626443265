import React, {useState, useContext, useEffect} from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {BASE_URI, LEAD, SET_CLIENT, SET_FINAL_PRODUCT, SET_LEAD} from "../../shared/Global";
import axios from "axios";
import {ConfiguratorContext} from "../../shared/contexts/ConfiguratorContext";
import Button from "@material-ui/core/Button";
import CardActionArea from "@material-ui/core/CardActionArea";
import {FINAL_PRODUCT, INFORMATION} from "../../shared/Global";
import {useParams, useHistory} from "react-router-dom";
import * as Yup from "yup";
import {Formik, Form, Field} from "formik";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import {Switch} from 'formik-material-ui'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
        marginTop: "2rem",
    },
    stepPaddingBelow: {
        marginRight: "0.5rem",
        marginTop: "0.5rem",
    },
    stepPaddingButton: {
        marginTop: "0.5rem",
    },
    stepPaddingTitle: {
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    card: {
        width: "100%"
    },
    button: {
        width: "100%",
    },
    backButton: {
        marginTop: "2rem",
    },
    image: {
        minWidth: "100%",
        height: 550,
    }
}));

export default function GetInfoContainer(props) {
    const classes = useStyles();
    const {a, i, leadId} = useParams();
    const {enqueueSnackbar} = useSnackbar();

    const history = useHistory();

    let { ...context } = useContext(ConfiguratorContext);

    useEffect(() => {

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios({
            method: 'GET',
            url: `${BASE_URI}/PostNBAuth/${leadId}/${a}/${i}`,
            data: {},
            cancelToken: source.token
        }).then((response) => {
            
            history.push(`/1/30/buy/finance/0/${leadId}`)
        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar(`Unable to complete request. ${error}`, { variant: 'error' });
        })
    }, []);

    return (
        <React.Fragment>
            <Grid container direction="row">
                <Grid item xs={12} md={7} lg={7}>
                    <Card className={classes.content} elevation={0}>
                        <CardContent>
                            <Typography align={"center"} variant={"h4"}>
                                <>
                                    Please wait...
                                </>
                                <hr style={{backgroundColor: INFORMATION.buttonColor, height: "2px", border: 0}}/>
                            </Typography>
                            <Typography align={"center"} className={classes.stepPaddingTitle} variant={"subtitle1"}>
                                We are waiting for feedback from Nedbank to see whether you qualify to continue the process...Please be patient.
                            </Typography>
                            
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item className={classes.stepPaddingImage} xs={12} md={4} lg={5}>
                    <Card className={classes.content}>
                        <CardActionArea>
                            <CardMedia className={classes.image}
                                       component="img"
                                       alt="Features"
                                       image={FINAL_PRODUCT['image']}
                                       title={FINAL_PRODUCT['title']}
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
