import React, {useState, useEffect, useRef} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {useSnackbar} from 'notistack'
import { useParams } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker } from 'formik-material-ui-pickers';
import * as Yup from 'yup'
import TextMaskCustom from '../../shared/TextMask'
import FormActions from '../../shared/FormActions'
import {BASE_URI, LOOKUP_URI} from '../../shared/Constants'
import axios from 'axios'
import SelectInput from '../../shared/AutoCompleteComponent'
import LoadingCardComponent from '../../shared/LoadingCardComponent'

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  }
}));

export default function WorkTabContainer({next, back, skip}) {
  const classes = useStyles();
  const initialValues = {
    employer: '',
    employmentStartDate: new Date(),
    employmentType: '',  
    employmentTypeId: '',
    industryType: '',  
    industryTypeId: '',
    occupation: '',  
    occupationTypeId: '',
    monthsAtEmployer: "",  
    employerWorkPhoneNumber: '',  
    employerWorkPhoneAreaCode: '',
    employerAddress1: '',
    employerAddress2: '',
    employerCountry: '',
    employerCountryId : '',
    employerCity: "",  
    employerCityId: 0,
    employerAddressCode: "",
    employerSuburb: "",
    employerProvince: "",
    employerProvinceId: "",
  }

  const [employmentTypes, setEmploymentTypes] = useState([])
  const [industryTypes, setIndustryTypes] = useState([])
  const [occupations, setOccupations] = useState([])
  const mountedRef = useRef(true)
  const { leadId } = useParams();
  const [isAddMode, setIsAddMode] = useState(true);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [formFields, setFormFields] = useState(initialValues)
  const [disableOccupationsList, setDisableOccupationsList] = useState(true)
  const [countries, setCountries] = useState([])
  const [provinceArr, setProvinces] = useState([])
  const [cities, setCities] = useState([])

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setLoading(true)
    const getCountryList = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/countries`, {
          cancelToken : source.token
        })
        setCountries(result.data)
      } catch (error) {
        if (axios.isCancel(error)) return
        enqueueSnackbar("Unable to get countries list", {variant: 'error'});
      }
    }

    const getWorkData = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/Applications/Work/${leadId}`, {
          cancelToken : source.token
        })
        setDisableOccupationsList(false)
        let formData = result.data
        setFormFields(formData)
        setIsAddMode(false)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) return
      }
    }

    const getIndustryTypes = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/industryType`, {
          cancelToken : source.token
        })
        setIndustryTypes(result.data)
      } catch (error) {
        if (axios.isCancel(error)) return
        enqueueSnackbar("Unable to get Industry list", {variant: 'error'});
      }
    }

    const getEmploymentTypes = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/employmentType`, {
          cancelToken : source.token
        })
        setEmploymentTypes(result.data)
      } catch (error) {
        if (axios.isCancel(error)) return
        enqueueSnackbar("Unable to get employment list", {variant: 'error'});
      }
    }

    const getRegionArr = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/region`, {
          cancelToken : source.token
        })
        setProvinces(result.data)
      } catch (error) {
        if (axios.isCancel(error)) return
        enqueueSnackbar("Unable to get province list", {variant: 'error'});
      }
    }

    getRegionArr().then(() => {
      if (!mountedRef.current) return null
    })

    getWorkData().then(() => {
      if (!mountedRef.current) return null
    })

    getIndustryTypes().then(() => {
      if (!mountedRef.current) return null
    })

    getEmploymentTypes().then(() => {
      if (!mountedRef.current) return null
    })

    getCountryList().then(() => {
      if (!mountedRef.current) return null
    })

    return () => {
      mountedRef.current = false
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[leadId]);

  function onSubmit(fields, { setStatus, setSubmitting }) {
    let tmpFields = {...fields}
    tmpFields.employerWorkPhoneAreaCode = fields.employerWorkPhoneNumber.substring(0, 3)
    tmpFields.employerCountryId = parseInt(fields.employerCountryId, 10)
    tmpFields.occupationTypeId = parseInt(fields.occupationTypeId, 10)
    tmpFields.industryTypeId = parseInt(fields.industryTypeId, 10)
    tmpFields.employerProvinceId = parseInt(fields.employerProvinceId, 10)
    tmpFields.employerCityId = parseInt(fields.employerCityId, 10)
    setStatus();
    if (isAddMode) {
      createWorkApplication(leadId, tmpFields, setSubmitting);
    } else {
      if (fields.id) {
        editWorkApplication(leadId, tmpFields, setSubmitting);
      } else {
        //enqueueSnackbar('Dealer ID not available', {variant: 'error'});
      }
    }
  }

  const createWorkApplication = async (id, fields, setSubmitting) => {
    try {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const result = await axios.post(`${BASE_URI}/Applications/Work/${id}`, fields, {
        cancelToken : source.token
      })
      enqueueSnackbar('Successfully uploaded work details', {variant: 'success'});
      next()
      return result;
    } catch (error) {
      enqueueSnackbar('Error uploading work details, please try again later.', {variant: 'error'});
    } finally {
      setSubmitting(false)
    }
  }


  const editWorkApplication = async (id, fields, setSubmitting) => {
    try {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const result = await axios.put(`${BASE_URI}/Applications/Work/${id}`, fields, {
        cancelToken : source.token
      })
      enqueueSnackbar('Successfully edited work details', {variant: 'success'});
      next()
      return result;
    } catch (error) {
      enqueueSnackbar('Error updating work details, please try again later.', {variant: 'error'});
    } finally {
      setSubmitting(false)
    }
  }


  const handleIndustryChange = async (e,  value) => {
    if(value && value.id) {
      getOccupations(value.id).then((res) => {
        setDisableOccupationsList(false)
        setOccupations(res.data)
      }).catch((err) => {
        setDisableOccupationsList(true)
        enqueueSnackbar("Unable to get occupations list", {variant: 'error'});
      })
    }
  }


  const getOccupations = (id) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    return axios.get(`${BASE_URI}/${LOOKUP_URI}/occupationTypes`, {
      cancelToken : source.token
    })
  }

  const handleProvinceChange = async (e,  value) => {
    if(value && value.id) {
      getCities(value.id).then((res) => {
        // setDisableOccupationsList(false)
        setCities(res.data)
      }).catch((err) => {
        enqueueSnackbar("Unable to get cities list", {variant: 'error'});
        // setDisableOccupationsList(true)
      })
    }
  }

  const getCities = (id) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    return axios.get(`${BASE_URI}/${LOOKUP_URI}/city/${id}`, {
      cancelToken : source.token
    })
  }

  return (
    <div className={classes.root}>
      <Formik
        initialValues={formFields}
        enableReinitialize={true}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          employer: Yup.string().required('Employer Name is required'),
          employmentStartDate: Yup.string().required('Employment Start Date is required'),
          employmentType: Yup.string().required('Employment is required'),
          industryType: Yup.string().required('Industry is required'),
          occupation: Yup.string().required('Occupation is required'),
          monthsAtEmployer: Yup.string().required('Months worked at Employer is required'),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            handleReset,
          } = props
          return (
            <React.Fragment>
              {
                loading && <LoadingCardComponent />
              }
              {
                !loading &&
                <Form>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Card style={{marginBottom: '1rem', marginTop: '1.5rem'}} elevation={5}>
                      <Grid spacing={4} className={classes.stepPadding} container direction="row">
                        <Grid item xs={12} style={{paddingBottom: 0}}>
                          <Toolbar disableGutters>
                            <Typography variant="h6" style={{flex: '1 1'}} component="div" gutterBottom>
                              Work Details
                            </Typography>
                          </Toolbar>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="employer"
                            fullWidth
                            name="employer"
                            required
                            label="Employer"
                            value={values.employer}
                            helperText={errors.employer && touched.employer ? errors.employer : ''}
                            error={ errors.employer && touched.employer}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Field component={DatePicker}
                                 autoOk
                                 required
                                 format="dd/MM/yyyy"
                                 label="Employment Start Date"
                                 name="employmentStartDate" />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Field
                            component={SelectInput}
                            props={props}
                            required
                            fullWidth
                            Label={"Employment"}
                            fieldName={"employmentType"}
                            fieldNameID={"employmentTypeId"}
                            data={employmentTypes}
                            onBlur={handleBlur("employmentType")}
                            helperText={errors.employmentType && touched.employmentType ? errors.employmentType : 'Start Typing'}
                            error={ errors.employmentType && touched.employmentType}
                            value={{
                              name: values.employmentType.toString(),
                              id: values.employmentTypeId.toString(),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Field
                            component={SelectInput}
                            props={props}
                            required
                            fullWidth
                            Label={"Industry Type"}
                            fieldName={"industryType"}
                            fieldNameID={"industryTypeId"}
                            data={industryTypes}
                            onChange={handleIndustryChange}
                            onBlur={handleBlur("industryType")}
                            helperText={errors.industryType && touched.industryType ? errors.industryType : 'Start Typing'}
                            error={ errors.industryType && touched.industryType}
                            value={{
                              name: values.industryType.toString(),
                              id: values.industryTypeId.toString(),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>

                          <Field
                            component={SelectInput}
                            props={props}
                            required
                            disabled={disableOccupationsList}
                            fullWidth
                            Label={"Occupation"}
                            fieldName={"occupation"}
                            fieldNameID={"occupationTypeId"}
                            data={occupations}
                            onBlur={handleBlur("occupation")}
                            helperText={errors.occupation && touched.occupation ? errors.occupation : 'Start Typing'}
                            error={ errors.occupation && touched.occupation}
                            value={{
                              name: values.occupation ? values.occupation.toString(): '',
                              id: values.occupationTypeId ? values.occupationTypeId.toString(): '',
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="monthsAtEmployer"
                            fullWidth
                            type="number"
                            name="monthsAtEmployer"
                            required
                            label="Months at Employer"
                            value={values.monthsAtEmployer}
                            helperText={errors.monthsAtEmployer && touched.monthsAtEmployer ? errors.monthsAtEmployer : ''}
                            error={ errors.monthsAtEmployer && touched.monthsAtEmployer}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            label="Work Phone Number"
                            value={values.employerWorkPhoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={errors.employerWorkPhoneNumber && touched.employerWorkPhoneNumber ? errors.employerWorkPhoneNumber : ''}
                            error={ errors.employerWorkPhoneNumber && touched.employerWorkPhoneNumber}
                            name="employerWorkPhoneNumber"
                            id="employerWorkPhoneNumber"
                            InputProps={{
                              inputComponent: TextMaskCustom,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Card>

                    <Card elevation={5}>
                      <Grid spacing={4} className={classes.stepPadding} container direction="row">
                        <Grid item xs={12} style={{paddingBottom: 0}}>
                          <Toolbar disableGutters>
                            <Typography variant="h6" style={{flex: '1 1'}} component="div" gutterBottom>
                              Work Address
                            </Typography>
                          </Toolbar>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="employerAddress1"
                            fullWidth
                            name="employerAddress1"
                            // required
                            label="Address 1"
                            value={values.employerAddress1}
                            helperText={errors.employerAddress1 && touched.employerAddress1 ? errors.employerAddress1 : ''}
                            error={ errors.employerAddress1 && touched.employerAddress1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="employerAddress2"
                            fullWidth
                            name="employerAddress2"
                            // required
                            label="Address 2"
                            value={values.employerAddress2}
                            helperText={errors.employerAddress2 && touched.employerAddress2 ? errors.employerAddress2 : ''}
                            error={ errors.employerAddress2 && touched.employerAddress2}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>

                        <Grid item xs={12} md={3}>
                          <Field
                            component={SelectInput}
                            props={props}
                            fullWidth
                            Label={"Country"}
                            fieldName={"employerCountry"}
                            fieldNameID={"employerCountryId"}
                            data={countries}
                            onBlur={handleBlur("employerCountry")}
                            helperText={errors.employerCountry && touched.employerCountry ? errors.employerCountry : 'Start Typing'}
                            error={ errors.employerCountry && touched.employerCountry}
                            value={{
                              name: values.employerCountry.toString(),
                              id: values.employerCountryId.toString(),
                            }}
                          />
                        </Grid>
                        <Grid item  xs={12} md={3}>
                          <Field
                            component={SelectInput}
                            props={props}
                            required
                            fullWidth
                            Label={"Province"}
                            fieldName={"employerProvince"}
                            fieldNameID={"employerProvinceId"}
                            data={provinceArr}
                            onChange={handleProvinceChange}
                            onBlur={handleBlur("employerProvince")}
                            helperText={errors.employerProvince && touched.employerProvince ? errors.employerProvince : 'Start Typing'}
                            error={ errors.employerProvince && touched.employerProvince}
                            value={{
                              name: values.employerProvince ? values.employerProvince.toString(): '',
                              id: values.employerProvinceId ? values.employerProvinceId.toString(): '',
                            }}
                          />

                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                id="employerCity"
                                name="employerCity"
                                label="City"
                                fullWidth
                                value={values.employerCity}
                                helperText={errors.employerCity && touched.employerCity ? errors.employerCity : ''}
                                error={errors.employerCity && touched.employerCity}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>

                          <TextField
                            id="employerSuburb"
                            name="employerSuburb"
                            label="Suburb"
                            required
                            fullWidth
                            value={values.employerSuburb}
                            helperText={errors.suburb && touched.employerSuburb ? errors.employerSuburb : ''}
                            error={ errors.employerSuburb && touched.employerSuburb}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="employerAddressCode"
                            fullWidth
                            name="employerAddressCode"
                            // required
                            label="Address Code"
                            value={values.employerAddressCode}
                            helperText={errors.employerAddressCode && touched.employerAddressCode ? errors.employerAddressCode : ''}
                            error={ errors.employerAddressCode && touched.employerAddressCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      </Grid>
                    </Card>
                    <FormActions back={back} reset={() => handleReset()} />
                  </MuiPickersUtilsProvider>
                </Form>

              }
            </React.Fragment>
          );
        }}
      </Formik>
    </div>
  );
}
