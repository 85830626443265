import React, {useState, useEffect, useRef} from "react";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import MenuItem from "@material-ui/core/MenuItem";
import {TextField as MaterialTextField} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import incomeTable, {expenseTable, incomeUnmarriedTable} from '../../shared/financeTabHelper'
import { TextField } from 'formik-material-ui';
import InputAdornment from "@material-ui/core/InputAdornment";
import {useSnackbar} from 'notistack'
import { useParams } from "react-router-dom";
import TableHeadersSort from '../../shared/TableHeadersSort'
import FormActions from '../../shared/FormActions'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {BASE_URI, LOOKUP_URI} from '../../shared/Constants'
import axios from 'axios'
import LoadingCardComponent from '../../shared/LoadingCardComponent'

const useStyles = makeStyles((theme) => ({
  root: {},
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  inputField: {
    marginBottom: '1.5rem'
  }
}));

export default function FinancialTabContainer({back, next, married}) {
  const classes = useStyles();
  const initialValues = {
    buyerIncome: {
      grossIncome: 0,
      netSalary: 0,
      earningsCarAllowance: 0,
      earningsCommission: 0,
      earningsOtherAllowance: 0,
      earningsOvertime: 0,
      earningsReimbursement: 0,
      earningsRentalIncome: 0,
      earningsMaintenanceIncome: 0,
      earningsOtherIncome: 0,
      account: 0,
      savings: 0,
      salaryDay: '', //NB check that its an input number max-31,
      incomeFrequencyId: '',
      incomeFrequency: ''
    },

    buyerMonthlyExpenses: {
      clothing: 0,
      utilities: 0,
      creditCard: 0,
      loansFromPayslip: 0,
      bond: 0,
      education: 0,
      entertainment: 0,
      furnitureAccount: 0,
      foodAndHousehold: 0,
      maintenance: 0,
      municipalRates: 0,
      other: 0,
      overdraft: 0,
      personalLoan: 0,
      policy: 0,
      telephone: 0,
      transport: 0,
      vehicle: 0,
    },
    spouseMonthlyExpenses: {
      loansFromPayslip: 0,
      bond: 0,
      creditCard: 0,
      education: 0,
      entertainment: 0,
      furnitureAccount: 0,
      foodAndHousehold: 0,
      maintenance: 0,
      municipalRates: 0,
      other: 0,
      overdraft: 0,
      personalLoan: 0,
      policy: 0,
      telephone: 0,
      transport: 0,
      vehicle: 0,
      utilities: 0,
      clothing: 0
    },
    spouseIncome: {
      grossIncome: 0,
      netSalary: 0,
      earningsCarAllowance: 0,
      earningsCommission: 0,
      earningsOtherAllowance: 0,
      earningsOvertime: 0,
      earningsReimbursement: 0,
      earningsRentalIncome: 0,
      earningsMaintenanceIncome: 0,
      earningsOtherIncome: 0,
    }
  }
  const mountedRef = useRef(true)
  const { leadId } = useParams();
  const [isAddMode, setIsAddMode] = useState(true);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [formFields, setFormFields] = useState(initialValues)
  const [frequencies, setFrequencies] = useState([])
  const headCells = [
    { id: "income", numeric: false, label: "Income", sort: true },
    { id: "applicant", numeric: false, label: "Applicant", sort: true },
    { id: "spouse", numeric: false, label: "Spouse", sort: true },
  ];
  const ExpenseHeadCells = [
    { id: "expenses", numeric: false, label: "Expenses", sort: true },
    { id: "applicant", numeric: false, label: "Applicant", sort: true },
    { id: "spouse", numeric: false, label: "Spouse", sort: true },
  ];
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [showSpouse, setShowSpouse] = useState(married);
  const dualTableCells = [
    { id: "income", numeric: false, label: "Income" },
    { id: "applicantIncome", numeric: false, label: " "},
    { id: "expenses", numeric: false, label: "Expenses" },
    { id: "applicantExpenses", numeric: false, label: " "},
  ]

  useEffect(() => {
    if(married) {
      setShowSpouse(married)
    }
  }, [married])

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setLoading(true)
    const getFinancialData = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/Applications/Finance/${leadId}`, {
          cancelToken : source.token
        })
        let formData = result.data
        setFormFields(formData)
        setShowSpouse(formData.isMarried)
        setIsAddMode(false)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) return
      }
    }

    const getIncomeFrequencies = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/IncomeFrequency`, {
          cancelToken : source.token
        })
        // let formData = result.data
        setFrequencies(result.data)
      } catch (error) {
        if (axios.isCancel(error)) return
      }
    }

    getIncomeFrequencies().then(() => {
      if (!mountedRef.current) return null
    })


    getFinancialData().then(() => {
      if (!mountedRef.current) return null
    })

    return () => {
      mountedRef.current = false
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[leadId]);

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const handleSelectChange = (e,  value, setFieldValue, fieldName, fieldId) => {
    setFieldValue(`buyerIncome.${fieldName}`, e.target.value)
    setFieldValue(`buyerIncome.${fieldId}`, value.props.id)
  }

  function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
    setStatus();
    if (isAddMode) {
      createFinanceApplication(leadId, fields, setSubmitting);
    } else {
      editFinanceApplication(leadId, fields, setSubmitting);
    }
  }

  const createFinanceApplication = async (id, fields, setSubmitting) => {
    try {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
        const result = await axios.post(`${BASE_URI}/Applications/Finance/${id}`, fields, {
        cancelToken : source.token
      })
      enqueueSnackbar('Successfully uploaded financial details', {variant: 'success'});
      next()
      return result;
    } catch (error) {
      enqueueSnackbar('Error uploading financial details, please try again later.', {variant: 'error'});
    } finally {
      setSubmitting(false)
    }
  }


  const editFinanceApplication = async (id, fields, setSubmitting) => {
    try {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const result = await axios.put(`${BASE_URI}/Applications/Finance/${id}`, fields, {
        cancelToken : source.token
      })
      enqueueSnackbar('Successfully edited financial details', {variant: 'success'});
      next()
      return result;
    } catch (error) {
      enqueueSnackbar('Error updating financial details, please try again later.', {variant: 'error'});
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div style={{width: '100%'}}>
      <Formik
        initialValues={formFields}
        isInitialValid={false}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
            handleReset,
            setFieldValue
          } = props
          const incomeLength = incomeUnmarriedTable().length
          const expenseLength = expenseTable().length

          return (
            <React.Fragment>
              {
                loading && <LoadingCardComponent/>
              }
              {
                !loading &&
                <Form>
                  <Card style={{marginBottom: '1rem', marginTop: '1.5rem'}} elevation={5}>
                    <Grid spacing={4} className={classes.stepPadding} container direction="row">
                      <Grid item xs={12} style={{paddingBottom: 0}}>
                        <Toolbar disableGutters>
                          <Typography variant="h6" style={{flex: '1 1'}} component="div" gutterBottom>
                            { !showSpouse ? 'Income and Expenses': 'Income'}
                          </Typography>
                        </Toolbar>
                      </Grid>

                      {
                        (matches && !showSpouse) &&
                        <Grid item xs={12}>
                          <TableContainer>
                            <Table aria-label="ranges table">
                              <TableHeadersSort headCells={dualTableCells} />
                              <TableBody>
                                {
                                  [...Array(incomeLength > expenseLength ? incomeLength:expenseLength)].map((x, i) =>
                                    <StyledTableRow>
                                      <StyledTableCell>
                                        {incomeUnmarriedTable()[i] ? incomeUnmarriedTable()[i].label: ' '}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {
                                          (incomeUnmarriedTable()[i] && incomeUnmarriedTable()[i].id !== 'incomeFrequency') &&
                                          <Field
                                            component={TextField}
                                            type="number"
                                            name={`buyerIncome.${incomeUnmarriedTable()[i].applicant.toString()}`}
                                            id={`buyerIncome.${incomeUnmarriedTable()[i].applicant.toString()}`}
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  {incomeUnmarriedTable()[i].adornment}
                                                </InputAdornment>
                                              ),
                                            }}
                                            helperText={errors[incomeUnmarriedTable()[i].applicant] && touched[incomeUnmarriedTable()[i].applicant] ? errors[incomeUnmarriedTable()[i].applicant] : ''}
                                            error={ errors[incomeUnmarriedTable()[i].applicant] && touched[incomeUnmarriedTable()[i].applicant]}
                                          />
                                        }
                                        {
                                          (incomeUnmarriedTable()[i] && incomeUnmarriedTable()[i].id === 'incomeFrequency') &&
                                          <MaterialTextField
                                            fullWidth
                                            id={`buyerIncome.incomeFrequency`}
                                            select
                                            required
                                            value={values?.buyerIncome?.incomeFrequency}
                                            helperText={errors?.buyerIncome?.incomeFrequency && touched?.buyerIncome?.incomeFrequency ? errors.buyerIncome.incomeFrequency : ''}
                                            error={ errors?.buyerIncome?.incomeFrequency && touched?.buyerIncome?.incomeFrequency}
                                            onBlur={handleBlur("buyerIncome.incomeFrequency")}
                                            onChange={(e,child) => handleSelectChange(e, child, setFieldValue, 'incomeFrequency', 'incomeFrequencyId')}
                                          >
                                            {frequencies.map((option) => (
                                              <MenuItem key={option.id} id={option.id} value={option.name}>
                                                {option.name}
                                              </MenuItem>
                                            ))}
                                          </MaterialTextField>
                                        }
                                      </StyledTableCell>

                                      <StyledTableCell>
                                        {expenseTable()[i] ? expenseTable()[i].label: ' '}
                                      </StyledTableCell>

                                      <StyledTableCell>
                                        {
                                          expenseTable()[i] &&
                                          <Field
                                            component={TextField}
                                            type="number"
                                            name={`buyerMonthlyExpenses.${expenseTable()[i].applicant.toString()}`}
                                            id={`buyerMonthlyExpenses.${expenseTable()[i].applicant.toString()}`}
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  {expenseTable()[i].adornment}
                                                </InputAdornment>
                                              ),
                                            }}
                                            helperText={errors[expenseTable()[i].applicant] && touched[expenseTable()[i].applicant] ? errors[expenseTable()[i].applicant] : ''}
                                            error={ errors[expenseTable()[i].applicant] && touched[expenseTable()[i].applicant]}
                                          />
                                        }
                                      </StyledTableCell>

                                    </StyledTableRow>
                                  )
                                }
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      }


                      { //DONE
                        (matches && showSpouse) &&
                        <Grid item xs={12}>
                          <TableContainer>
                            <Table aria-label="ranges table">
                              <TableHeadersSort headCells={headCells} />
                              <TableBody>
                                {incomeTable().map((row) => (
                                  <StyledTableRow key={row.id}>
                                    <StyledTableCell>
                                      {row.label}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <Field
                                        component={TextField}
                                        type="number"
                                        name={`buyerIncome.${row.applicant.toString()}`}
                                        id={`buyerIncome.${row.applicant.toString()}`}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              {row.adornment}
                                            </InputAdornment>
                                          ),
                                        }}
                                        helperText={errors[row.applicant] && touched[row.applicant] ? errors[row.applicant] : ''}
                                        error={ errors[row.applicant] && touched[row.applicant]}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <Field
                                        component={TextField}
                                        type="number"
                                        name={`spouseIncome.${row.spouse.toString()}`}
                                        id={`spouseIncome.${row.spouse.toString()}`}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              {row.adornment}
                                            </InputAdornment>
                                          ),
                                        }}
                                        helperText={errors[row.spouse] && touched[row.spouse] ? errors[row.spouse] : ''}
                                        error={ errors[row.spouse] && touched[row.spouse]}
                                      />
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}

                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Savings
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    <Field
                                      component={TextField}
                                      type="number"
                                      required
                                      name={`buyerIncome.savings`}
                                      id={`buyerIncome.savings`}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            R
                                          </InputAdornment>
                                        ),
                                      }}
                                      helperText={(errors.buyerIncome && errors.buyerIncome.savings && touched.savings && touched.buyerIncome.savings) ? errors.buyerIncome.savings: ''}
                                      error={(errors.buyerIncome && errors.buyerIncome.savings && touched.buyerIncome && touched.buyerIncome.savings)}
                                    />
                                  </StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Account
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    <Field
                                      component={TextField}
                                      type="number"
                                      required
                                      name={`buyerIncome.account`}
                                      id={`buyerIncome.account`}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            R
                                          </InputAdornment>
                                        ),
                                      }}
                                      helperText={(errors.buyerIncome && errors.buyerIncome.account && touched.buyerIncome && touched.buyerIncome.account) ? errors.buyerIncome.account: ''}
                                      error={(errors.buyerIncome && errors.buyerIncome.account && touched.buyerIncome && touched.buyerIncome.account)}
                                    />
                                  </StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Frequency
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    <MaterialTextField
                                      fullWidth
                                      id={`buyerIncome.incomeFrequency`}
                                      select
                                      required
                                      value={values?.buyerIncome?.incomeFrequency}
                                      helperText={errors?.buyerIncome?.incomeFrequency && touched?.buyerIncome?.incomeFrequency ? errors.buyerIncome.incomeFrequency : ''}
                                      error={ errors?.buyerIncome?.incomeFrequency && touched?.buyerIncome?.incomeFrequency}
                                      onBlur={handleBlur("buyerIncome.incomeFrequency")}
                                      onChange={(e,child) => handleSelectChange(e, child, setFieldValue, 'incomeFrequency', 'incomeFrequencyId')}
                                    >
                                      {frequencies.map((option) => (
                                        <MenuItem key={option.id} id={option.id} value={option.name}>
                                          {option.name}
                                        </MenuItem>
                                      ))}
                                    </MaterialTextField>
                                  </StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Salary Payment Day
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    <Field
                                      component={TextField}
                                      type="number"
                                      name={`buyerIncome.salaryDay`}
                                      required
                                      id={`buyerIncome.salaryDay`}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            Day
                                          </InputAdornment>
                                        ),
                                      }}
                                      helperText={(errors?.buyerIncome?.salaryDay && touched?.buyerIncome?.salaryDay) ? errors.buyerIncome.salaryDay: ''}
                                      error={errors?.buyerIncome?.salaryDay && touched?.buyerIncome?.salaryDay}
                                    />
                                  </StyledTableCell>
                                </StyledTableRow>

                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      }
                      {
                        !matches &&
                        <React.Fragment>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2" component="div">
                              {showSpouse ? 'Applicant': 'Income'}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{paddingBottom: 0}}>
                            {incomeTable().map((row) => (
                              <Field
                                key={`buyerIncome-${row.id}`}
                                component={TextField}
                                type="number"
                                className={classes.inputField}
                                label={`${row.label}`}
                                name={`buyerIncome.${row.applicant.toString()}`}
                                id={`buyerIncome.${row.applicant.toString()}`}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {row.adornment}
                                    </InputAdornment>
                                  ),
                                }}
                                helperText={errors[row.applicant] && touched[row.applicant] ? errors[row.applicant] : ''}
                                error={ errors[row.applicant] && touched[row.applicant]}
                              />
                            ))}
                          </Grid>
                          <Grid item xs={12} style={{paddingTop: 0}}>
                            <Field
                              component={TextField}
                              type="number"
                              label="Savings"
                              name={`buyerIncome.savings`}
                              id={`buyerIncome.savings`}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R
                                  </InputAdornment>
                                ),
                              }}
                              helperText={(errors.buyerIncome && errors.buyerIncome.savings && touched.savings && touched.buyerIncome.savings) ? errors.buyerIncome.savings: ''}
                              error={(errors.buyerIncome && errors.buyerIncome.savings && touched.buyerIncome && touched.buyerIncome.savings)}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              component={TextField}
                              type="number"
                              label="Account"
                              name={`buyerIncome.account`}
                              id={`buyerIncome.account`}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R
                                  </InputAdornment>
                                ),
                              }}
                              helperText={(errors.buyerIncome && errors.buyerIncome.account && touched.buyerIncome && touched.buyerIncome.account) ? errors.buyerIncome.account: ''}
                              error={(errors.buyerIncome && errors.buyerIncome.account && touched.buyerIncome && touched.buyerIncome.account)}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <MaterialTextField
                              fullWidth
                              id={`buyerIncome.incomeFrequency`}
                              select
                              label={'Income Frequency'}
                              required
                              value={values?.buyerIncome?.incomeFrequency}
                              helperText={errors?.buyerIncome?.incomeFrequency && touched?.buyerIncome?.incomeFrequency ? errors.buyerIncome.incomeFrequency : ''}
                              error={ errors?.buyerIncome?.incomeFrequency && touched?.buyerIncome?.incomeFrequency}
                              onBlur={handleBlur("buyerIncome.incomeFrequency")}
                              onChange={(e,child) => handleSelectChange(e, child, setFieldValue, 'incomeFrequency', 'incomeFrequencyId')}
                            >
                              {frequencies.map((option) => (
                                <MenuItem key={option.id} id={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </MaterialTextField>
                          </Grid>

                          <Grid item xs={12}>
                            <Field
                              component={TextField}
                              type="number"
                              name={`buyerIncome.salaryDay`}
                              label={'Salary Day'}
                              required
                              id={`buyerIncome.salaryDay`}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    Day
                                  </InputAdornment>
                                ),
                              }}
                              helperText={(errors?.buyerIncome?.salaryDay && touched?.buyerIncome?.salaryDay) ? errors.buyerIncome.salaryDay: ''}
                              error={errors?.buyerIncome?.salaryDay && touched?.buyerIncome?.salaryDay}
                            />
                          </Grid>

                          {
                            showSpouse &&
                            <React.Fragment>
                              <Grid item xs={12}>
                                <Typography variant="subtitle2" component="div">
                                  Spouse
                                </Typography>
                              </Grid>

                              <Grid item xs={12}>
                                {incomeTable().map((row) => (
                                  <Field
                                    key={`spouseIncome-${row.id}`}
                                    component={TextField}
                                    type="number"
                                    label={`${row.label}`}
                                    className={classes.inputField}
                                    name={`spouseIncome.${row.spouse.toString()}`}
                                    id={`spouseIncome.${row.spouse.toString()}`}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          {row.adornment}
                                        </InputAdornment>
                                      ),
                                    }}
                                    helperText={errors[row.spouse] && touched[row.spouse] ? errors[row.spouse] : ''}
                                    error={ errors[row.spouse] && touched[row.spouse]}
                                  />
                                ))}
                              </Grid>
                            </React.Fragment>
                          }
                          {
                            !showSpouse &&
                            <React.Fragment>
                              <Grid item xs={12}>
                                <Typography variant="subtitle2" component="div">
                                  Expenses
                                </Typography>
                              </Grid>
                              <Grid item xs={12} style={{paddingBottom: 0}}>
                                {expenseTable().map((row) => (
                                  <Field
                                    key={`buyerMonthlyExpenses-${row.id}`}
                                    component={TextField}
                                    type="number"
                                    className={classes.inputField}
                                    label={`${row.label}`}
                                    name={`buyerMonthlyExpenses.${row.applicant.toString()}`}
                                    id={`buyerMonthlyExpenses.${row.applicant.toString()}`}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          {row.adornment}
                                        </InputAdornment>
                                      ),
                                    }}
                                    helperText={errors[row.applicant] && touched[row.applicant] ? errors[row.applicant] : ''}
                                    error={errors[row.applicant] && touched[row.applicant]}
                                  />
                                ))}
                              </Grid>
                            </React.Fragment>
                          }
                        </React.Fragment>
                      }
                    </Grid>
                  </Card>

                  {
                    showSpouse &&
                    <Card style={{marginBottom: '1rem'}} elevation={5}>
                      <Grid spacing={4} className={classes.stepPadding} container direction="row">
                        <Grid item xs={12} style={{paddingBottom: 0}}>
                          <Toolbar disableGutters>
                            <Typography variant="h6" style={{flex: '1 1'}} component="div" gutterBottom>
                              Expenses
                            </Typography>
                          </Toolbar>
                        </Grid>
                        {
                          matches &&
                          <Grid item xs={12}>
                            <TableContainer>
                              <Table aria-label="ranges table">
                                <TableHeadersSort headCells={ExpenseHeadCells} />
                                <TableBody>
                                  {expenseTable().map((row) => (
                                    <StyledTableRow key={row.id}>
                                      <StyledTableCell>
                                        {row.label}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        <Field
                                          component={TextField}
                                          type="number"
                                          name={`buyerMonthlyExpenses.${row.applicant.toString()}`}
                                          id={`buyerMonthlyExpenses.${row.applicant.toString()}`}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                {row.adornment}
                                              </InputAdornment>
                                            ),
                                          }}
                                          helperText={errors[row.applicant] && touched[row.applicant] ? errors[row.applicant] : ''}
                                          error={ errors[row.applicant] && touched[row.applicant]}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        <Field
                                          component={TextField}
                                          type="number"
                                          name={`spouseMonthlyExpenses.${row.spouse.toString()}`}
                                          id={`spouseMonthlyExpenses.${row.spouse.toString()}`}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                {row.adornment}
                                              </InputAdornment>
                                            ),
                                          }}
                                          helperText={errors[row.spouse] && touched[row.spouse] ? errors[row.spouse] : ''}
                                          error={ errors[row.spouse] && touched[row.spouse]}
                                        />
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        }

                        {
                          !matches &&
                          <React.Fragment>
                            <Grid item xs={12}>
                              <Typography variant="subtitle2" component="div">
                                Applicant
                              </Typography>
                            </Grid>
                            <Grid item xs={12} style={{paddingBottom: 0}}>
                              {expenseTable().map((row) => (
                                <Field
                                  key={`buyerMonthlyExpenses-${row.id}`}
                                  component={TextField}
                                  type="number"
                                  className={classes.inputField}
                                  label={`${row.label}`}
                                  name={`buyerMonthlyExpenses.${row.applicant.toString()}`}
                                  id={`buyerMonthlyExpenses.${row.applicant.toString()}`}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {row.adornment}
                                      </InputAdornment>
                                    ),
                                  }}
                                  helperText={errors[row.applicant] && touched[row.applicant] ? errors[row.applicant] : ''}
                                  error={errors[row.applicant] && touched[row.applicant]}
                                />
                              ))}
                            </Grid>

                            <Grid item xs={12}>
                              <Typography variant="subtitle2" component="div">
                                Spouse
                              </Typography>
                            </Grid>

                            <Grid item xs={12} style={{paddingBottom: 0}}>
                              {expenseTable().map((row) => (
                                <Field
                                  key={`spouseMonthlyExpenses-${row.id}`}
                                  component={TextField}
                                  type="number"
                                  className={classes.inputField}
                                  label={`${row.label}`}
                                  name={`spouseMonthlyExpenses.${row.spouse.toString()}`}
                                  id={`spouseMonthlyExpenses.${row.spouse.toString()}`}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {row.adornment}
                                      </InputAdornment>
                                    ),
                                  }}
                                  helperText={errors[row.spouse] && touched[row.spouse] ? errors[row.spouse] : ''}
                                  error={ errors[row.spouse] && touched[row.spouse]}
                                />
                              ))}
                            </Grid>

                          </React.Fragment>
                        }
                      </Grid>
                    </Card>
                  }

                  <FormActions back={back} reset={() => handleReset()} />
                </Form>
              }
            </React.Fragment>
          );
        }}
      </Formik>
    </div>
  );
}
