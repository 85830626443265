import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker } from 'formik-material-ui-pickers';
import FormActions from '../../shared/FormActions'
import TextMaskCustom from '../../shared/TextMask'
import Hidden from '@material-ui/core/Hidden';
import axios from 'axios'
import { useParams } from "react-router-dom";
import {BASE_URI, LOOKUP_URI} from '../../shared/Constants'
import {useSnackbar} from 'notistack'
import SelectInput from '../../shared/AutoCompleteComponent'
import * as Yup from 'yup'
import LoadingCardComponent from '../../shared/LoadingCardComponent'

const useStyles = makeStyles((theme) => ({
  root: {},
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  }
}));

export default function SpouseTabContainer({next, back, married}) {
  const classes = useStyles();
  const [industryTypes, setIndustryTypes] = useState([])
  const [employmentTypes, setEmploymentTypes] = useState([])
  const [occupations, setOccupations] = useState([])
  const [disableOccupationsList, setDisableOccupationsList] = useState(true)
  const initialValues = {
    spouseFullNames: "",
    spouseSurname: "",
    spouseIdentificationNumber: "",
    spouseBirthDate: new Date(),
    spouseWorkPhoneAreaCode: "",
    spouseWorkPhoneNumber: "",
    spouseHomePhoneAreaCode: "",
    spouseHomePhoneNumber: "",
    spouseMobilePhoneAreaCode: "",
    spouseMobilePhoneNumber: "",

    //WORK Details
    spouseEmployerCompanyName: "",
    spouseIndustryTypeId: '',
    spouseIndustryType: '',
    spouseEmploymentType: "",
    spouseEmploymentTypeId: "",
    spouseOccupation: "",
    spouseOccupationId: "",
    spouseMonthsAtEmployer: 0,
    spouseEmployerWorkPhoneAreaCode: "",
    spouseEmployerWorkPhoneNumber: "",

    //NEXT of KIN
    nextOfKinRelationship: "",
    nextOfKinRelationshipId: "",
    nextOfKinTitle: "",
    nextOfKinTitleId: "",
    nextOfKinFullNames: "",
    nextOfKinSurname: "",
    nextOfKinMobilePhoneAreaCode: "",
    nextOfKinMobilePhoneNumber: "",
    nextOfKinHomePhoneAreaCode: '',
    nextOfKinHomePhoneNumber: "",
    nextOfKinWorkPhoneAreaCode: "",
    nextOfKinWorkPhoneNumber: "",
    nextOfKinAddress1: '',
    nextOfKinAddress2: '',
    nextOfKinSuburb: "",
    nextOfKinCity: "",
    nextOfKinCityId: 0,
    nextOfKinProvince: "",
    nextOfKinProvinceId: "",
    nextOfKinAddressCode: "",
  };
  const [formFields, setFormFields] = useState(initialValues)
  const { leadId } = useParams();
  const [isAddMode, setIsAddMode] = useState(true);
  const [loading, setLoading] = useState(true);
  const mountedRef = useRef(true)
  const [titles, setTitles] = useState([])
  const [relationship, setRelationships] = useState([])
  const [showSpouse, setShowSpouse] = useState(married);
  const [provinceArr, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if(married) {
      setShowSpouse(married)
    }
  }, [married])


  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setLoading(true)
    const getSpouseDetails = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/Applications/SpouseRelative/${leadId}`, {
          cancelToken : source.token
        })
        let formData = result.data
        if (result.data) {
          setFormFields(formData)
          setIsAddMode(false)
          setShowSpouse(result.data?.isMarried)
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) return
      }
    }

    getSpouseDetails().then(() => {
      if (!mountedRef.current) return null
    })

    return () => {
      mountedRef.current = false
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[leadId]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const getIndustryTypes = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/industryType`, {
          cancelToken : source.token
        })
        setIndustryTypes(result.data)
      } catch (error) {
        if (axios.isCancel(error)) return
        enqueueSnackbar("Unable to get Industry list", {variant: 'error'});
      }
    }

    const getEmploymentTypes = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/employmentType`, {
          cancelToken : source.token
        })
        setEmploymentTypes(result.data)
      } catch (error) {
        if (axios.isCancel(error)) return
        enqueueSnackbar("Unable to get employment list", {variant: 'error'});
      }
    }

    const getTitleList = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/title`, {
          cancelToken : source.token
        })
        setTitles(result.data)
      } catch (error) {
        if (axios.isCancel(error)) return
        enqueueSnackbar("Unable to get title list, please refresh", {variant: 'error'});
      }
    }

    const getRelationships = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/RelationshipType`, {
          cancelToken : source.token
        })
        setRelationships(result.data)
      } catch (error) {
        if (axios.isCancel(error)) return
        enqueueSnackbar("Unable to get relationship list, please refresh", {variant: 'error'});
      }
    }

    const getRegionArr = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/region`, {
          cancelToken : source.token
        })
        setProvinces(result.data)
      } catch (error) {
        if (axios.isCancel(error)) return
        enqueueSnackbar("Unable to get province list, please refresh", {variant: 'error'});
      }
    }

    getRegionArr().then(() => {
      if (!mountedRef.current) return null
    })

    getTitleList().then(() => {
      if (!mountedRef.current) return null
    })

    getRelationships().then(() => {
      if (!mountedRef.current) return null
    })

    getIndustryTypes().then(() => {
      if (!mountedRef.current) return null
    })

    getEmploymentTypes().then(() => {
      if (!mountedRef.current) return null
    })

    return () => {
      mountedRef.current = false
      source.cancel();
    };
  },[])

  const handleIndustryChange = async (e,  value) => {
    if(value && value.id) {
      getOccupations(value.id).then((res) => {
        setDisableOccupationsList(false)
        setOccupations(res.data)
      }).catch((err) => {
        enqueueSnackbar("Unable to get industry list, please refresh", {variant: 'error'});
        setDisableOccupationsList(true)
      })
    }
  }

  const getOccupations = (id) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    return axios.get(`${BASE_URI}/${LOOKUP_URI}/occupationTypes`, {
      cancelToken : source.token
    })
  }

  const handleSelectChange = (e,  value, setFieldValue, fieldName, fieldId) => {
    setFieldValue(fieldName, e.target.value)
    setFieldValue(fieldId, value.props.id)
  }

  function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
    console.log("Spouse Tab")
    console.log(fields)
    let tmpFields = {...fields}
    tmpFields.nextOfKinTitleId = parseInt(fields.nextOfKinTitleId, 10)
    tmpFields.nextOfKinRelationshipId = parseInt(fields.nextOfKinRelationshipId, 10)
    tmpFields.spouseOccupationId = fields.spouseOccupationId ? parseInt(fields.spouseOccupationId, 10): 0
    tmpFields.spouseEmploymentTypeId = fields.spouseEmploymentTypeId ? parseInt(fields.spouseEmploymentTypeId, 10): 0
    tmpFields.spouseIndustryTypeId = fields.spouseIndustryTypeId ? parseInt(fields.spouseIndustryTypeId, 10): 0
    tmpFields.nextOfKinCityId = parseInt(fields.nextOfKinCityId, 10)
    tmpFields.nextOfKinProvinceId = parseInt(fields.nextOfKinProvinceId, 10)
    setStatus();
    if (isAddMode) {
      createSpouseApplication(leadId, tmpFields, setSubmitting);
    } else {
      editSpouseApplication(leadId, tmpFields, setSubmitting);
    }
  }

  const createSpouseApplication = async (id, fields, setSubmitting) => {
    try {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const result = await axios.post(`${BASE_URI}/Applications/SpouseRelative/${id}`, fields, {
        cancelToken : source.token
      })
      enqueueSnackbar('Successfully uploaded spouse details', {variant: 'success'});
      next()
      return result;
    } catch (error) {
      enqueueSnackbar('Error uploading spouse details, please try again later.', {variant: 'error'});
    } finally {
      setSubmitting(false)
    }
  }


  const editSpouseApplication = async (id, fields, setSubmitting) => {
    try {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const result = await axios.put(`${BASE_URI}/Applications/SpouseRelative/${id}`, fields, {
        cancelToken : source.token
      })
      enqueueSnackbar('Successfully edited spouse details', {variant: 'success'});
      next()
      return result;
    } catch (error) {
      enqueueSnackbar('Error updating spouse details, please try again later.', {variant: 'error'});
    } finally {
      setSubmitting(false)
    }
  }

  const handleProvinceChange = async (e,  value) => {
    if(value && value.id) {
      getCities(value.id).then((res) => {
        // setDisableOccupationsList(false)
        setCities(res.data)
      }).catch((err) => {
        enqueueSnackbar("Unable to get city list, please refresh", {variant: 'error'});
        // setDisableOccupationsList(true)
      })
    }
  }

  const getCities = (id) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    return axios.get(`${BASE_URI}/${LOOKUP_URI}/city/${id}`, {
      cancelToken : source.token
    })
  }

  return (
    <div className={classes.root}>
      <Formik
        initialValues={formFields}
        isInitialValid={false}
        enableReinitialize={true}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          // employer: Yup.string().required('Employer Name is required'),
          // employmentStartDate: Yup.string().required('Employment Start Date is required'),
          // employmentType: Yup.string().required('Employment is required'),
          // industryType: Yup.string().required('Industry is required'),
          // occupation: Yup.string().required('Occupation is required'),
          // monthsAtEmployer: Yup.string().required('Months worked at Employer is required'),
          // nextOfKinStreetNumber: Yup.string().required('Employer Name is required'),
          // nextOfKinStreetName: Yup.string().required('Employer Name is required'),
          // nextOfKinSuburb: Yup.string().required('Employer Name is required'),
          // nextOfKinCity: "",
          // nextOfKinProvince: "",
          // nextOfKinAddressCode: "",
          // nextOfKinMobilePhoneNumber: "",
          // nextOfKinRelationship: "",
          // nextOfKinFullNames: "",
          // nextOfKinSurname: "",
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
            handleReset,
            setFieldValue
          } = props
          return (
            <React.Fragment>
              {
                loading && <LoadingCardComponent />
              }
              {
                !loading &&
                <Form>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {
                      showSpouse &&
                      <React.Fragment>
                        <Card style={{marginBottom: '1rem', marginTop: '1.5rem'}} elevation={5}>
                          <Grid spacing={4} className={classes.stepPadding} container direction="row">
                            <Grid item xs={12} style={{paddingBottom: 0}}>
                              <Toolbar disableGutters>
                                <Typography variant="h6" style={{flex: '1 1'}} component="div" gutterBottom>
                                  Spouse Personal Details
                                </Typography>
                              </Toolbar>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <TextField
                                id="spouseFullNames"
                                name="spouseFullNames"
                                label="Name"
                                fullWidth
                                value={values.spouseFullNames}
                                helperText={errors.spouseFullNames && touched.spouseFullNames ? errors.spouseFullNames : ''}
                                error={ errors.spouseFullNames && touched.spouseFullNames}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                id="spouseSurname"
                                name="spouseSurname"
                                label="Surname"
                                fullWidth
                                value={values.spouseSurname}
                                helperText={errors.spouseSurname && touched.spouseSurname ? errors.spouseSurname : ''}
                                error={ errors.spouseSurname && touched.spouseSurname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                id="spouseIdentificationNumber"
                                name="spouseIdentificationNumber"
                                label="ID Number"
                                fullWidth
                                value={values.spouseIdentificationNumber}
                                helperText={errors.spouseIdentificationNumber && touched.spouseIdentificationNumber ? errors.spouseIdentificationNumber : ''}
                                error={ errors.spouseIdentificationNumber && touched.spouseIdentificationNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Field component={DatePicker}
                                     autoOk
                                     fullWidth
                                     format="dd/MM/yyyy"
                                     label="Date of Birth"
                                     name="spouseBirthDate" />
                            </Grid>

                            {/*<Grid item xs={12} md={3}>*/}
                            {/*<TextField*/}
                            {/*id="spouseMobilePhoneAreaCode"*/}
                            {/*name="spouseMobilePhoneAreaCode"*/}
                            {/*label="Mobile Code"*/}
                            {/*type="number"*/}
                            {/*value={values.spouseMobilePhoneAreaCode}*/}
                            {/*helperText={errors.spouseMobilePhoneAreaCode && touched.spouseMobilePhoneAreaCode ? errors.spouseMobilePhoneAreaCode : ''}*/}
                            {/*error={ errors.spouseMobilePhoneAreaCode && touched.spouseMobilePhoneAreaCode}*/}
                            {/*onChange={handleChange}*/}
                            {/*onBlur={handleBlur}*/}
                            {/*/>*/}
                            {/*</Grid>*/}

                            <Grid item xs={12} md={4}>
                              <TextField
                                id="spouseMobilePhoneNumber"
                                name="spouseMobilePhoneNumber"
                                label="Mobile Number"
                                fullWidth
                                value={values.spouseMobilePhoneNumber}
                                helperText={errors.spouseMobilePhoneNumber && touched.spouseMobilePhoneNumber ? errors.spouseMobilePhoneNumber : ''}
                                error={ errors.spouseMobilePhoneNumber && touched.spouseMobilePhoneNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                  inputComponent: TextMaskCustom,
                                }}
                              />
                            </Grid>

                            {/*<Grid item xs={12} md={3}>*/}
                            {/*<TextField*/}
                            {/*id="spouseHomePhoneAreaCode"*/}
                            {/*name="spouseHomePhoneAreaCode"*/}
                            {/*label="Home Phone Code"*/}
                            {/*value={values.spouseHomePhoneAreaCode}*/}
                            {/*helperText={errors.spouseHomePhoneAreaCode && touched.spouseHomePhoneAreaCode ? errors.spouseHomePhoneAreaCode : ''}*/}
                            {/*error={ errors.spouseHomePhoneAreaCode && touched.spouseHomePhoneAreaCode}*/}
                            {/*onChange={handleChange}*/}
                            {/*onBlur={handleBlur}*/}
                            {/*/>*/}
                            {/*</Grid>*/}
                            <Grid item xs={12} md={4}>
                              <TextField
                                id="spouseHomePhoneNumber"
                                name="spouseHomePhoneNumber"
                                label="Home Number"
                                fullWidth
                                value={values.spouseHomePhoneNumber}
                                helperText={errors.spouseHomePhoneNumber && touched.spouseHomePhoneNumber ? errors.spouseHomePhoneNumber : ''}
                                error={ errors.spouseHomePhoneNumber && touched.spouseHomePhoneNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                  inputComponent: TextMaskCustom,
                                }}
                              />
                            </Grid>

                            {/*<Grid item xs={12} md={3}>*/}
                            {/*<TextField*/}
                            {/*id="spouseWorkPhoneAreaCode"*/}
                            {/*name="spouseWorkPhoneAreaCode"*/}
                            {/*label="Work Phone Code"*/}
                            {/*value={values.spouseWorkPhoneAreaCode}*/}
                            {/*helperText={errors.spouseWorkPhoneAreaCode && touched.spouseWorkPhoneAreaCode ? errors.spouseWorkPhoneAreaCode : ''}*/}
                            {/*error={ errors.spouseWorkPhoneAreaCode && touched.spouseWorkPhoneAreaCode}*/}
                            {/*onChange={handleChange}*/}
                            {/*onBlur={handleBlur}*/}
                            {/*/>*/}
                            {/*</Grid>*/}
                            <Grid item xs={12} md={4}>
                              <TextField
                                id="spouseWorkPhoneNumber"
                                name="spouseWorkPhoneNumber"
                                label="Work Number"
                                fullWidth
                                value={values.spouseWorkPhoneNumber}
                                helperText={errors.spouseWorkPhoneNumber && touched.spouseWorkPhoneNumber ? errors.spouseWorkPhoneNumber : ''}
                                error={ errors.spouseWorkPhoneNumber && touched.spouseWorkPhoneNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                  inputComponent: TextMaskCustom,
                                }}
                              />

                            </Grid>
                          </Grid>
                        </Card>

                        <Card style={{marginBottom: '1rem', marginTop: '1.5rem'}} elevation={5}>
                          <Grid spacing={4} className={classes.stepPadding} container direction="row">
                            <Grid item xs={12} style={{paddingBottom: 0}}>
                              <Toolbar disableGutters>
                                <Typography variant="h6" style={{flex: '1 1'}} component="div" gutterBottom>
                                  Work Details
                                </Typography>
                              </Toolbar>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <TextField
                                id="spouseEmployerCompanyName"
                                name="spouseEmployerCompanyName"
                                label="Company Name"
                                fullWidth
                                value={values.spouseEmployerCompanyName}
                                helperText={errors.spouseEmployerCompanyName && touched.spouseEmployerCompanyName ? errors.spouseEmployerCompanyName : ''}
                                error={ errors.spouseEmployerCompanyName && touched.spouseEmployerCompanyName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>

                              <Field
                                component={SelectInput}
                                props={props}
                                required
                                fullWidth
                                Label={"Employment"}
                                fieldName={"spouseEmploymentType"}
                                fieldNameID={"spouseEmploymentTypeId"}
                                data={employmentTypes}
                                onBlur={handleBlur("spouseEmploymentType")}
                                helperText={errors.spouseEmploymentType && touched.spouseEmploymentType ? errors.spouseEmploymentType : 'Start Typing'}
                                error={ errors.spouseEmploymentType && touched.spouseEmploymentType}
                                value={{
                                  name: values.spouseEmploymentType ? values.spouseEmploymentType.toString(): '',
                                  id: values.spouseEmploymentType ? values.spouseEmploymentTypeId.toString(): '',
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Field
                                component={SelectInput}
                                props={props}
                                required
                                fullWidth
                                Label={"Industry Type"}
                                fieldName={"spouseIndustryType"}
                                fieldNameID={"spouseIndustryTypeId"}
                                data={industryTypes}
                                onChange={handleIndustryChange}
                                onBlur={handleBlur("spouseIndustryType")}
                                helperText={errors.spouseIndustryType && touched.spouseIndustryType ? errors.spouseIndustryType : 'Start Typing'}
                                error={ errors.spouseIndustryType && touched.spouseIndustryType}
                                value={{
                                  name: values.spouseIndustryType ? values.spouseIndustryType.toString(): '',
                                  id: values.spouseIndustryType ? values.spouseIndustryTypeId.toString(): '',
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>

                              <Field
                                component={SelectInput}
                                props={props}
                                required
                                disabled={disableOccupationsList}
                                fullWidth
                                Label={"Occupation"}
                                fieldName={"spouseOccupation"}
                                fieldNameID={"spouseOccupationId"}
                                data={occupations}
                                onBlur={handleBlur("spouseOccupation")}
                                helperText={errors.spouseOccupation && touched.spouseOccupation ? errors.spouseOccupation : 'Start Typing'}
                                error={ errors.spouseOccupation && touched.spouseOccupation}
                                value={{
                                  name: values.spouseOccupation ? values.spouseOccupation.toString(): '',
                                  id: values.spouseOccupation ? values.spouseOccupationId.toString(): '',
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <TextField
                                id="spouseMonthsAtEmployer"
                                fullWidth
                                type="number"
                                name="spouseMonthsAtEmployer"
                                // required
                                label="Months at Employer"
                                value={values.spouseMonthsAtEmployer}
                                helperText={errors.spouseMonthsAtEmployer && touched.spouseMonthsAtEmployer ? errors.spouseMonthsAtEmployer : ''}
                                error={ errors.spouseMonthsAtEmployer && touched.spouseMonthsAtEmployer}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid>
                            {/*<Grid item  xs={12} md={3}>*/}
                            {/*<TextField*/}
                            {/*id="spouseEmployerWorkPhoneAreaCode"*/}
                            {/*fullWidth*/}
                            {/*type="number"*/}
                            {/*name="spouseEmployerWorkPhoneAreaCode"*/}
                            {/*// required*/}
                            {/*label="Work Phone Code"*/}
                            {/*value={values.spouseEmployerWorkPhoneAreaCode}*/}
                            {/*helperText={errors.spouseEmployerWorkPhoneAreaCode && touched.spouseEmployerWorkPhoneAreaCode ? errors.spouseEmployerWorkPhoneAreaCode : ''}*/}
                            {/*error={ errors.spouseEmployerWorkPhoneAreaCode && touched.spouseEmployerWorkPhoneAreaCode}*/}
                            {/*onChange={handleChange}*/}
                            {/*onBlur={handleBlur}*/}
                            {/*/>*/}
                            {/*</Grid>*/}
                            <Grid item xs={12} md={4}>
                              <TextField
                                fullWidth
                                label="Work Phone Number"
                                value={values.spouseEmployerWorkPhoneNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={errors.spouseEmployerWorkPhoneNumber && touched.spouseEmployerWorkPhoneNumber ? errors.spouseEmployerWorkPhoneNumber : ''}
                                error={ errors.spouseEmployerWorkPhoneNumber && touched.spouseEmployerWorkPhoneNumber}
                                name="spouseEmployerWorkPhoneNumber"
                                id="spouseEmployerWorkPhoneNumber"
                                InputProps={{
                                  inputComponent: TextMaskCustom,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Card>
                      </React.Fragment>
                    }

                    <Card style={{marginBottom: '1rem', marginTop: '1.5rem'}} elevation={5}>
                      <Grid spacing={4} className={classes.stepPadding} container direction="row">
                        <Grid item xs={12} style={{paddingBottom: 0}}>
                          <Toolbar disableGutters>
                            <Typography variant="h6" style={{flex: '1 1'}} component="div" gutterBottom>
                              Next Of Kin Personal Details
                            </Typography>
                          </Toolbar>
                        </Grid>

                        <Grid item xs={12} md={2}>
                          <TextField
                            fullWidth
                            id="nextOfKinRelationship"
                            select
                            //required
                            label="Relationship Type"
                            value={values.nextOfKinRelationship}
                            helperText={errors.nextOfKinRelationship && touched.nextOfKinRelationship ? errors.nextOfKinRelationship : ''}
                            error={ errors.nextOfKinRelationship && touched.nextOfKinRelationship}
                            onBlur={handleBlur("nextOfKinRelationship")}
                            onChange={(e,child) => handleSelectChange(e, child, setFieldValue, 'nextOfKinRelationship', 'nextOfKinRelationshipId')}
                          >
                            {relationship.map((option) => (
                              <MenuItem key={option.id} id={option.id} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                            fullWidth
                            id="nextOfKinTitle"
                            select
                            //required
                            label="Title"
                            value={values.nextOfKinTitle}
                            helperText={errors.nextOfKinTitle && touched.nextOfKinTitle ? errors.nextOfKinTitle : ''}
                            error={ errors.nextOfKinTitle && touched.nextOfKinTitle}
                            onBlur={handleBlur("nextOfKinTitle")}
                            onChange={(e,child) => handleSelectChange(e, child, setFieldValue, 'nextOfKinTitle', 'nextOfKinTitleId')}
                          >
                            {titles.map((option) => (
                              <MenuItem key={option.id} id={option.id} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <TextField
                            id="nextOfKinFullNames"
                            name="nextOfKinFullNames"
                            label="Full name"
                            fullWidth
                            value={values.nextOfKinFullNames}
                            helperText={errors.nextOfKinFullNames && touched.nextOfKinFullNames ? errors.nextOfKinFullNames : ""}
                            error={ touched.nextOfKinFullNames && errors.nextOfKinFullNames}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="nextOfKinSurname"
                            name="nextOfKinSurname"
                            label="Surname"
                            fullWidth
                            value={values.nextOfKinSurname}
                            helperText={errors.nextOfKinSurname && touched.nextOfKinSurname ? errors.nextOfKinSurname : ""}
                            error={ touched.nextOfKinSurname && errors.nextOfKinSurname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        {/*<Grid item xs={12} md={3}>*/}
                        {/*<TextField*/}
                        {/*id="nextOfKinMobilePhoneAreaCode"*/}
                        {/*name="nextOfKinMobilePhoneAreaCode"*/}
                        {/*label="Mobile Code"*/}
                        {/*type="number"*/}
                        {/*value={values.nextOfKinMobilePhoneAreaCode}*/}
                        {/*helperText={touched.nextOfKinMobilePhoneAreaCode && errors.nextOfKinMobilePhoneAreaCode*/}
                        {/*? errors.nextOfKinMobilePhoneAreaCode*/}
                        {/*: ""*/}
                        {/*}*/}
                        {/*error={touched.nextOfKinMobilePhoneAreaCode &&errors.nextOfKinMobilePhoneAreaCode}*/}
                        {/*onChange={handleChange}*/}
                        {/*onBlur={handleBlur}*/}
                        {/*/>*/}
                        {/*</Grid>*/}

                        <Grid item xs={12} md={4}>
                          <TextField
                            id="nextOfKinMobilePhoneNumber"
                            name="nextOfKinMobilePhoneNumber"
                            label="Mobile Number"
                            fullWidth
                            value={values.nextOfKinMobilePhoneNumber}
                            helperText={errors.nextOfKinMobilePhoneNumber && touched.nextOfKinMobilePhoneNumber ? errors.nextOfKinMobilePhoneNumber : ''}
                            error={ errors.nextOfKinMobilePhoneNumber && touched.nextOfKinMobilePhoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              inputComponent: TextMaskCustom,
                            }}
                          />
                        </Grid>
                        {/*<Grid item xs={12} md={3}>*/}
                        {/*<TextField*/}
                        {/*id="nextOfKinHomePhoneAreaCode"*/}
                        {/*name="nextOfKinHomePhoneAreaCode"*/}
                        {/*label="Home Code"*/}
                        {/*type="number"*/}
                        {/*value={values.nextOfKinHomePhoneAreaCode}*/}
                        {/*helperText={errors.nextOfKinHomePhoneAreaCode && touched.nextOfKinHomePhoneAreaCode ? errors.nextOfKinHomePhoneAreaCode : ''}*/}
                        {/*error={ errors.nextOfKinHomePhoneAreaCode && touched.nextOfKinHomePhoneAreaCode}*/}
                        {/*onChange={handleChange}*/}
                        {/*onBlur={handleBlur}*/}
                        {/*/>*/}
                        {/*</Grid>*/}
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="nextOfKinHomePhoneNumber"
                            name="nextOfKinHomePhoneNumber"
                            label="Home Number"
                            fullWidth
                            value={values.nextOfKinHomePhoneNumber}
                            helperText={errors.nextOfKinHomePhoneNumber && touched.nextOfKinHomePhoneNumber ? errors.nextOfKinHomePhoneNumber : ''}
                            error={ errors.nextOfKinHomePhoneNumber && touched.nextOfKinHomePhoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              inputComponent: TextMaskCustom,
                            }}
                          />
                        </Grid>
                        {/*<Grid item xs={12} md={3}>*/}
                        {/*<TextField*/}
                        {/*id="nextOfKinWorkPhoneAreaCode"*/}
                        {/*name="nextOfKinWorkPhoneAreaCode"*/}
                        {/*label="Work Code"*/}
                        {/*type="number"*/}
                        {/*value={values.nextOfKinWorkPhoneAreaCode}*/}
                        {/*helperText={errors.nextOfKinWorkPhoneAreaCode && touched.nextOfKinWorkPhoneAreaCode ? errors.nextOfKinWorkPhoneAreaCode : ''}*/}
                        {/*error={ errors.nextOfKinWorkPhoneAreaCode && touched.nextOfKinWorkPhoneAreaCode}*/}
                        {/*onChange={handleChange}*/}
                        {/*onBlur={handleBlur}*/}
                        {/*/>*/}
                        {/*</Grid>*/}
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="nextOfKinWorkPhoneNumber"
                            name="nextOfKinWorkPhoneNumber"
                            label="Work Number"
                            fullWidth
                            value={values.nextOfKinWorkPhoneNumber}
                            helperText={errors.nextOfKinWorkPhoneNumber && touched.nextOfKinWorkPhoneNumber ? errors.nextOfKinWorkPhoneNumber : ''}
                            error={ errors.nextOfKinWorkPhoneNumber && touched.nextOfKinWorkPhoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              inputComponent: TextMaskCustom,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="subtitle2" component="div">
                            Address Details
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <TextField
                            id="nextOfKinAddress1"
                            name="nextOfKinAddress1"
                            label="Address 1"
                            fullWidth
                            value={values.nextOfKinAddress1}
                            helperText={errors.nextOfKinAddress1 && touched.nextOfKinAddress1 ? errors.nextOfKinAddress1 : ''}
                            error={ errors.nextOfKinAddress1 && touched.nextOfKinAddress1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            id="nextOfKinAddress2"
                            name="nextOfKinAddress2"
                            label="Address 2"
                            fullWidth
                            value={values.nextOfKinAddress2}
                            helperText={errors.nextOfKinAddress2 && touched.nextOfKinAddress2 ? errors.nextOfKinAddress2 : ''}
                            error={ errors.nextOfKinAddress2 && touched.nextOfKinAddress2}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>

                        <Grid item xs={12} md={3}>
                          <TextField
                            id="nextOfKinAddressCode"
                            name="nextOfKinAddressCode"
                            label="Address Code"
                            fullWidth
                            value={values.nextOfKinAddressCode}
                            helperText={errors.nextOfKinAddressCode && touched.nextOfKinAddressCode ? errors.nextOfKinAddressCode : ''}
                            error={ errors.nextOfKinAddressCode && touched.nextOfKinAddressCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>

                        <Grid item xs={12} md={3}>
                          <Field
                            component={SelectInput}
                            props={props}
                            required
                            fullWidth
                            Label={"Province"}
                            fieldName={"nextOfKinProvince"}
                            fieldNameID={"nextOfKinProvinceId"}
                            data={provinceArr}
                            onChange={handleProvinceChange}
                            onBlur={handleBlur("nextOfKinProvince")}
                            helperText={errors.nextOfKinProvince && touched.nextOfKinProvince ? errors.nextOfKinProvince : 'Start Typing'}
                            error={ errors.nextOfKinProvince && touched.nextOfKinProvince}
                            value={{
                              name: values.nextOfKinProvince ? values.nextOfKinProvince.toString(): '',
                              id: values.nextOfKinProvinceId ? values.nextOfKinProvinceId.toString(): '',
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <TextField
                                id="nextOfKinCity"
                                name="nextOfKinCity"
                                label="City"
                                fullWidth
                                value={values.nextOfKinCity}
                                helperText={errors.nextOfKinCity && touched.nextOfKinCity ? errors.nextOfKinCity : ''}
                                error={errors.nextOfKinCity && touched.nextOfKinCity}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                        </Grid>

                        <Grid item xs={12} md={3}>
                          <TextField
                            id="nextOfKinSuburb"
                            name="nextOfKinSuburb"
                            label="Suburb"
                            fullWidth
                            value={values.nextOfKinSuburb}
                            helperText={errors.nextOfKinSuburb && touched.nextOfKinSuburb ? errors.nextOfKinSuburb : ''}
                            error={ errors.nextOfKinSuburb && touched.nextOfKinSuburb}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>

                      </Grid>
                    </Card>

                    <FormActions back={back} reset={() => handleReset()} />
                  </MuiPickersUtilsProvider>
                </Form>
              }
            </React.Fragment>
          );
        }}
      </Formik>
    </div>
  );
}
