import React, { useState, useContext, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { BASE_URI, LEAD, SET_CLIENT, SET_FINAL_PRODUCT, SET_LEAD } from "../../shared/Global";
import axios from "axios";
import { ConfiguratorContext } from "../../shared/contexts/ConfiguratorContext";
import Button from "@material-ui/core/Button";
import CardActionArea from "@material-ui/core/CardActionArea";
import { FINAL_PRODUCT, INFORMATION } from "../../shared/Global";
import { useParams, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import { Switch } from 'formik-material-ui'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SettingsBackupRestore from '@material-ui/icons/SettingsBackupRestore';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import Box from '@material-ui/core/Box';
import { useSnackbar } from "notistack";
import { CircularProgress } from "material-ui";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import ShoppingCartIcon from "../../shared/assets/shopping-cart.png"

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
        marginTop: "2rem",
    },
    stepPaddingBelow: {
        marginRight: "0.5rem",
        marginTop: "0.5rem",
    },
    stepPaddingButton: {
        marginTop: "0.5rem",
    },
    stepPaddingTitle: {
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    card: {
        width: "100%"
    },
    button: {
        width: "100%",
    },
    backButton: {
        marginTop: "2rem",
    },
    image: {
        width: "100%",
        height: "auto",
    },
    uppercase: {
        textTransform: 'uppercase'
    },
    content: {
        paddingTop: 0
    },
    intro: {
        marginBottom: '15px',
        alignItems: 'center'
    }
}));

export default function GetInfoContainer(props) {
    const classes = useStyles();
    const { dealer, leadGuid, renaultDigitalId } = useParams();
    const [creditCheck, setCreditCheck] = useState(false)
    const [remuneration, setRemuneration] = useState(false)
    const [termsAndConditions, setTermsAndConditions] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const [loader, setLoader] = useState(false)
    const [leadId, setLeadId] = useState(0)
    const [vehicle, setVehicle] = useState({});
    const [values, setValues] = useState({
        id: "",
        netSalary: "",
        totalExpenses: "",
        name: "",
        surname: "",
        phone: "",
        email: ""
    })

    const history = useHistory();

    let { ...context } = useContext(ConfiguratorContext);

    let idRegex = `(((\\d{2}((0[13578]|1[02])(0[1-9]|[12]\\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\\d|30)|02(0[1-9]|1\\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\\d{4})( |-)(\\d{3})|(\\d{7}))`
    let phoneRegExp = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;
    let digitsExp = /^\d+$/;

    const validationSchema = Yup.object({
        id: Yup.string("Enter an ID number").required(
            "ID number is required"
        ).matches(idRegex, "ID Number invalid"),
        name: Yup.string("Enter a name").required(
            "Name is required"
        ),
        email: Yup.string("Enter your email")
            .email("Enter a valid email")
            .required("Email is required"),
        netSalary: Yup.string("Enter your net salary")
            .required("Net Salary is required")
            .matches(digitsExp, "Please only enter digits without spaces"),
        totalExpenses: Yup.string("Enter your Expenses")
            .required("Expenses is required")
            .matches(digitsExp, "Please only enter digits without spaces"),
        phone: Yup.string("Enter your phone number")
            .required("Phone number is required")
            .matches(phoneRegExp, "Phone number is not valid")
    });

    const checkValid = (validForm) => {
        return (validForm && creditCheck && remuneration && termsAndConditions)
    }

    useEffect(() => {
        SET_FINAL_PRODUCT("", "", "", 0, { value: "", price: 0 },
            { value: "", price: 0, id: "" }, { value: "", price: 0, id: "" }, { value: "", price: 0, id: "" }, "", "", "");

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        if (leadGuid) {
            axios.get(`${BASE_URI}/leads/guid/${leadGuid}`, {
                cancelToken: source.token
            }).then((result) => {
                var lead = result.data;

                var client = {
                    firstName: lead.name,
                    name: lead.name,
                    email: lead.emailAddress,
                    telephone: lead.phone
                };

                context.setProduct({ client: client });

                SET_LEAD(parseInt(lead.id));
                setLeadId(lead.id);

                SET_FINAL_PRODUCT("", "", "", "",
                    "", "", "", "", "",
                    client, "");

                setValues({ name: lead.name, email: lead.emailAddress, phone: lead.phone })
            });
        }

        if (renaultDigitalId) {
            axios.get(`${BASE_URI}/renaultDigital/${renaultDigitalId}`, {
                cancelToken: source.token
            }).then((result) => {
                var vehicleInformation = result.data;

                setVehicle(vehicleInformation);
            });
        }
    }, [])


    function createLead(values) {

        setLoader(true);

        let client = {
            firstName: values.name,
            name: values.name + ' ' + values.lastName,
            email: values.email,
            telephone: values.phone
        };

        SET_FINAL_PRODUCT("", "", "", "",
            "", "", "", "", "",
            client, "");

        context.setProduct({ client: client });

        let params = {}

        params.leadStatusId = 1
        params.dealerId = parseInt(dealer)
        params.name = values.name + ' ' + values.lastName
        params.firstName = values.name
        params.lastName = values.lastName
        params.phoneNumber = FINAL_PRODUCT.client.telephone
        params.leadSourceId = 5
        params.TypeCode = "NEW"
        params.notes = ""
        params.id = leadId;

        if (renaultDigitalId) {
            params.leadRenaultDigitalId = parseInt(renaultDigitalId);
        }

        let m = new Date()
        m = m.getUTCFullYear() + "-" + (m.getUTCMonth() + 1) + "-" + m.getUTCDate() + " " + (('0' + m.getHours()).slice(-2)) + ":" + (('0' + m.getMinutes()).slice(-2)) + ":" + (('0' + m.getSeconds()).slice(-2));
        params.dateUpdated = new Date().toISOString();

        params.emailAddress = FINAL_PRODUCT.client.email // TODO lead Post has yet to capture this info

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios({
            method: 'POST',
            url: `${BASE_URI}/leads`,
            data: params,
            cancelToken: source.token
        }).then((response) => {
            SET_LEAD(response.data.id);
            values.leadId = response.data.id;

            submitForPQ(values);

        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to complete the request", { variant: 'error' });
            setLoader(false);

        })
    }

    function submitForPQ(values) {

        let params = {}
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        params.idNumber = values.id;
        params.leadId = parseInt(values.leadId);
        params.netSalary = parseInt(values.netSalary);
        params.totalExpenses = parseInt(values.totalExpenses);
        params.desiredAmount = 0;
        params.triggeredByAgent = true;

        axios({
            method: 'POST',
            url: `${BASE_URI}/applications/pq`,
            data: params,
            cancelToken: source.token
        }).then((response) => {

            window.location.href = response.data;

        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar(`Unable to complete request. ${error}`, { variant: 'error' });
            setLoader(false);
        })
    }

    return (
        <React.Fragment>
            <Grid container direction="row" alignItems={'center'}>
                <Grid item xs={12} md={6}>
                    <Card elevation={0}>
                        <CardContent classes={{ root: classes.content }}>
                            <Grid classes={{root: classes.intro}} container direction={"row"}>
                                <Grid item xs={12} sm={3} md={2}>
                                    <img src={ShoppingCartIcon} style={{width: 85, height: 85, marginBottom: 10}} />
                                </Grid>
                                <Grid item xs={12} sm={9} md={10}>
                                    <Box><Typography variant="h5" align={'left'} className={classes.uppercase}>Let's go</Typography></Box>
                                    <Box><Typography align={'left'} >Please complete your details</Typography></Box>
                                </Grid>
                            </Grid>
                            <Formik
                                initialValues={values}
                                enableReinitialize={true}
                                isInitialValid={false}
                                validationSchema={validationSchema}
                                onSubmit={async (values, actions) => {
                                    createLead(values)
                                }}
                            >
                                {(props) => {
                                    let {
                                        values: {
                                            id,
                                            netSalary,
                                            totalExpenses,
                                            name,
                                            lastName,
                                            phone,
                                            email,
                                        },
                                        touched,
                                        errors,
                                        isValid,
                                        submitForm,
                                        setFieldTouched,
                                        setFieldValue,
                                    } = props;
                                    return (
                                        <Form>
                                            <Grid container className={classes.root} direction="row">
                                                <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                    lg={12}>
                                                    <TextField
                                                        id="id"
                                                        name="id"
                                                        label="Your SA ID"
                                                        value={id}
                                                        fullWidth
                                                        variant="outlined"
                                                        helperText={touched.id ? errors.id : ""}
                                                        error={touched.id && Boolean(errors.id)}
                                                        onChange={(event) => {
                                                            setFieldValue("id", event.target.value)
                                                            setFieldTouched("id", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                >
                                                    <TextField
                                                        id="name"
                                                        name="name"
                                                        label="First Name"
                                                        value={name}
                                                        fullWidth
                                                        variant="outlined"
                                                        helperText={touched.name ? errors.name : ""}
                                                        error={touched.name && Boolean(errors.name)}
                                                        onChange={(event) => {
                                                            setFieldValue("name", event.target.value)
                                                            setFieldTouched("name", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                >
                                                    <TextField
                                                        id="lastName"
                                                        name="lastName"
                                                        label="Last Name"
                                                        value={lastName}
                                                        fullWidth
                                                        variant="outlined"
                                                        helperText={touched.lastName ? errors.lastName : ""}
                                                        error={touched.lastName && Boolean(errors.lastName)}
                                                        onChange={(event) => {
                                                            setFieldValue("lastName", event.target.value)
                                                            setFieldTouched("lastName", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                    lg={12}>
                                                    <TextField
                                                        id="email"
                                                        name="email"
                                                        type="email"
                                                        label="Email"
                                                        fullWidth
                                                        value={email}
                                                        variant="outlined"
                                                        helperText={touched.email ? errors.email : ""}
                                                        error={touched.email && Boolean(errors.email)}
                                                        onChange={(event) => {
                                                            setFieldValue("email", event.target.value)
                                                            setFieldTouched("email", true, false);
                                                        }}
                                                    />{" "}
                                                </Grid>
                                                <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                    lg={12}>
                                                    <TextField
                                                        id=" phone"
                                                        name="phone"
                                                        type="tel"
                                                        label="Telephone"
                                                        fullWidth
                                                        value={phone}
                                                        variant="outlined"
                                                        helperText={touched.phone ? errors.phone : ""}
                                                        error={touched.phone && Boolean(errors.phone)}
                                                        onChange={(event) => {
                                                            setFieldValue("phone", event.target.value)
                                                            setFieldTouched("phone", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPaddingButton} xs={12}>
                                                    <TextField
                                                        id="netSalary"
                                                        name="netSalary"
                                                        label="Your Nett Salary"
                                                        value={netSalary}
                                                        fullWidth
                                                        variant="outlined"
                                                        helperText={touched.netSalary ? errors.netSalary : ""}
                                                        error={touched.netSalary && Boolean(errors.netSalary)}
                                                        onChange={(event) => {
                                                            setFieldValue("netSalary", event.target.value)
                                                            setFieldTouched("netSalary", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPaddingButton} xs={12}>
                                                    <TextField
                                                        id="totalExpenses"
                                                        name="totalExpenses"
                                                        label="Your Total Expenses"
                                                        value={totalExpenses}
                                                        fullWidth
                                                        variant="outlined"
                                                        helperText={touched.totalExpenses ? errors.totalExpenses : ""}
                                                        error={touched.totalExpenses && Boolean(errors.totalExpenses)}
                                                        onChange={(event) => {
                                                            setFieldValue("totalExpenses", event.target.value)
                                                            setFieldTouched("totalExpenses", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                    lg={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Field component={Switch}
                                                                color="primary"
                                                                type="checkbox"
                                                                name="creditCheck"
                                                                onClick={() => {
                                                                    setCreditCheck(creditCheck ? false : true)
                                                                }}
                                                            />
                                                        }
                                                        label="Do you consent to a credit check?"
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                    lg={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Field component={Switch}
                                                                color="primary"
                                                                type="checkbox"
                                                                name="remuneration"
                                                                onClick={() => {
                                                                    setRemuneration(remuneration ? false : true)
                                                                }}
                                                            />
                                                        }
                                                        label="Do you earn more than R7000 per month?"
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                    lg={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Field component={Switch}
                                                                color="primary"
                                                                type="checkbox"
                                                                name="termsAndConditions"
                                                                onClick={() => {
                                                                    setTermsAndConditions(termsAndConditions ? false : true)
                                                                }}
                                                            />
                                                        }
                                                        label="Do you agree to the terms and conditions?"
                                                    />
                                                </Grid>

                                                <Grid container direction="row">
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                        lg={12}>

                                                        {!loader &&
                                                            <Button className={classes.button} variant={"contained"}
                                                                color="primary"
                                                                disabled={!checkValid(isValid)}
                                                                onClick={() => {
                                                                    submitForm();
                                                                }}
                                                            >
                                                                Checkout and Drive
                                                        </Button>
                                                        }
                                                        {loader == true && (
                                                            <Button className={classes.button} variant={"contained"}
                                                                color="primary"
                                                                disabled={true}
                                                            >
                                                                <CircularProgress />
                                                            </Button>
                                                        )}

                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item className={classes.stepPaddingImage} xs={12} md={6}>
                    <Card className={classes.content} elevation={0}>
                        <CardActionArea>
                            <CardMedia className={classes.image}
                                component="img"
                                alt={vehicle.vehicle}
                                image={vehicle.imageUrl}
                                title={vehicle.vehicle}
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
