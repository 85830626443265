import React, {useState, useContext, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {ConfiguratorContext} from "../../shared/contexts/ConfiguratorContext";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CheckIcon from '@material-ui/icons/Check';
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import {FINAL_PRODUCT, INFORMATION, SET_BANNER, SET_FINAL_PRODUCT} from "../../shared/Global";
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        marginLeft: "2rem",
        marginRight: "2rem",
        marginTop: "2rem"
    },
    stepPaddingButtons: {
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    stepPaddingTransmission: {
        marginLeft: "1em",
        marginRight: "1em",
        marginTop: "0.5em",
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    backButton: {
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    cardImage: {
        height: 300,
        width: '50%',
        margin: '0 auto',
        '@media (max-width: 815px)': {
            height: 'auto',
            width: '100%'
        }
    },
    transmissionContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    transmissionType: {
        textTransform: 'uppercase',
        fontSize: '0.9rem',
        marginTop: '0.8rem'
    }
}));

export default function TransmissionContainer(props) {
    const classes = useStyles();

    const [loading, setLoading] = useState(true);

    const [checked, setChecked] = useState("");
    const [types, setTypes] = useState([{title: "", image: ""}]);

    const [indexInfo, setIndexInfo] = useState(0);

    let { ...context } = useContext(ConfiguratorContext);

    useEffect(() => {

        console.log(INFORMATION);
        let temp = [];
        let tempManual = INFORMATION.manual.image == "" ? false : temp.push(INFORMATION.manual);
        let tempAutomatic = INFORMATION.automatic.image == "" ? false : temp.push(INFORMATION.automatic);

        setTypes(temp);
        let index = temp.findIndex(x => x["title"] == FINAL_PRODUCT.transmission.value);

        if (index != -1) {
            setChecked(temp[index]['title']);
            setIndexInfo(index)
        }

        if (temp.length == 1) {
            setChecked(temp[0]["title"])
            context.setTransmissionData(temp[0]);
            setIndexInfo(0);
            SET_FINAL_PRODUCT("", "", "", "",
                "", "", {value: temp[0]["title"], price: temp[0]["price"], id: 0},
                "", "", "", "");
        }

        SET_BANNER("", "",
            "Select your transmission",
            ""
        );

        context.setTransmissionData({})

    }, []);

    const isDisabled = () => {
        return (checked !== "");
    }

    return (
        <React.Fragment>
            <Grid item className={classes.backButton} xs={12} md={3} lg={3}>
                <Button variant={"contained"} color="primary"
                        onClick={() => {
                            props.props("Derivative", "", "Colour")
                        }}>
                    Back
                </Button>
            </Grid>
            <Grid container direction="row" justify={"center"}>
                <Grid item className={classes.stepPadding} xs={12}>
                    <Card elevation={0}>
                        <Typography align={"center"} variant={'h6'} gutterBottom style={{fontWeight: 700}}>
                            {types[indexInfo]['title']}
                        </Typography>
                        <CardMedia className={classes.cardImage}
                                   component="img"
                                   alt={types[indexInfo]['title']}
                                   image={types[indexInfo]['image']}
                        />
                        <CardContent className={classes.transmissionContainer}>
                                {types.map((type, index) => (
                                    <Box className={classes.stepPaddingTransmission} key={index}>
                                        <Button value={type.title} style={{
                                            height: '70px',
                                            width: '70px', 
                                            minWidth: '70px', 
                                            borderRadius: '26%'
                                        }}
                                                variant={"contained"}
                                                onClick={(v) => {
                                                    setChecked(v.currentTarget.value)
                                                    context.setTransmissionData(type)
                                                    setIndexInfo(index);
                                                    let id = (type.title == "Automatic" ? 1 : 0)
                                                    SET_FINAL_PRODUCT("", "", "", "",
                                                        "", "", {value: type.title, price: type.price, id: id},
                                                        "", "", "", "");
                                                    SET_BANNER("", "", "", "");
                                                }}
                                        >
                                            {checked == type.title ?
                                                <CheckIcon/> : ""}
                                        </Button>
                                        <Typography className={classes.transmissionType}>{type.title}</Typography>
                                    </Box>
                                ))}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item className={classes.stepPadding} xs={12} md={12} lg={12}>
                    <Card className={classes.content} elevation={0}>
                        {(types.length > 1) && (
                            <Grid container direction="row" justify="center">
                                <Typography gutterBottom variant="caption">
                                    * Please note Automatic transmission has an additional cost
                                </Typography>
                            </Grid>
                        )}
                        <CardContent>
                            <Grid container direction="row" justify="center">
                                <Button variant={"contained"} color="primary"
                                        disabled={!isDisabled()}
                                        onClick={() => {
                                            SET_BANNER("", "", "Select your accessories", "");
                                            props.props("Transmission", "", "Accessories")
                                        }}>
                                    Continue
                                </Button>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>
        </React.Fragment>
    );
}
