import React, {useState, useContext, useEffect} from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {BASE_URI, LEAD, SET_CLIENT, SET_FINAL_PRODUCT, SET_LEAD} from "../../shared/Global";
import axios from "axios";
import {ConfiguratorContext} from "../../shared/contexts/ConfiguratorContext";
import Button from "@material-ui/core/Button";
import CardActionArea from "@material-ui/core/CardActionArea";
import {FINAL_PRODUCT, INFORMATION} from "../../shared/Global";
import {useParams, useHistory} from "react-router-dom";
import * as Yup from "yup";
import {Formik, Form, Field} from "formik";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {CircularProgress} from "material-ui";
import {useSnackbar} from 'notistack'

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
        marginTop: "2rem",
    },
    stepPaddingButton: {
        marginTop: "0.5rem",
    },
    stepPaddingTitle: {
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    card: {
        width: "100%"
    },
    button: {
        width: "100%",
    },
    backButton: {
        marginTop: "2rem",
    },
    image: {
        minWidth: "100%",
        height: 550,
    }
}));

export default function GetInfoContainer(props) {
    const classes = useStyles();
    const {dealerModel, modelId, query} = useParams();
    const [loader, setLoader] = useState(false)

    const {enqueueSnackbar} = useSnackbar();

    const history = useHistory();

    useEffect(() => {
        if (FINAL_PRODUCT.price == 0) {
            let temp = `/${dealerModel}/${modelId}/builder`;
            history.push(temp);
        }
        INFORMATION.query = true
        context.setTransmissionData({})
    }, []);

    let {...context} = useContext(ConfiguratorContext);

    const phoneRegExp = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;

    const validationSchema = Yup.object({
        name: Yup.string("Enter a name").required(
            "Name is required"
        ),
        email: Yup.string("Enter your email")
            .email("Enter a valid email")
            .required("Email is required"),
        phone: Yup.string("Enter your phone number")
            .required("Phone number is required")
            .matches(phoneRegExp, "Phone number is not valid"),
    });

    let values = {
        name: "",
        phone: "",
        email: "",
    };

    async function newState(values) {
        SET_FINAL_PRODUCT("", "", "", "",
            "", "", "", "", "",
            {
                name: values.name,
                email: values.email,
                telephone: values.phone
            }, "");
    }

    function createLead(locationString) {
        let params = {}

        params.leadStatusId = 1
        params.dealerId = parseInt(dealerModel)
        params.name = FINAL_PRODUCT.client.name
        params.phoneNumber = FINAL_PRODUCT.client.telephone
        params.leadSourceId = 5
        params.TypeCode = "NEW"
        params.notes = ""
        
        let m = new Date()
        m = m.getUTCFullYear() + "-" + (m.getUTCMonth() + 1) + "-" + m.getUTCDate() + " " + (('0' + m.getHours()).slice(-2)) + ":" + (('0' + m.getMinutes()).slice(-2)) + ":" + (('0' + m.getSeconds()).slice(-2));
        params.dateUpdated = new Date().toISOString();

        params.emailAddress = FINAL_PRODUCT.client.email // TODO lead Post has yet to capture this info

        setLoader(true)

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        axios({
            method: 'POST',
            url: `${BASE_URI}/leads`,
            data: params,
            cancelToken: source.token
        }).then((response) => {
            SET_LEAD(response.data.id);

            let vehicleParam = {}
            vehicleParam.LeadId = response.data.id
            vehicleParam.ModelId = FINAL_PRODUCT.carVariantId.modelId
            vehicleParam.OwnedModelId = FINAL_PRODUCT.carVariantId.dealerModelId
            vehicleParam.OwnedVariantId = FINAL_PRODUCT.carVariantId.id
            vehicleParam.AccessoryId = FINAL_PRODUCT.accessories.id != "" ? FINAL_PRODUCT.accessories.id : 0
            vehicleParam.OwnedVariantExteriorsId = FINAL_PRODUCT.colourExterior.id
            vehicleParam.OwnedVariantInteriorsId = FINAL_PRODUCT.colourInterior.id
            vehicleParam.Transmission = FINAL_PRODUCT.transmission.id;
            vehicleParam.price = FINAL_PRODUCT.price;

            axios({
                method: 'POST',
                url: `${BASE_URI}/leadVehicles`,
                data: vehicleParam,
                cancelToken: source.token
            }).then((responseSecond) => {
                console.log(responseSecond)
                history.push(locationString + `${response.data.id}`)

            }).catch(error => {
              if (axios.isCancel(error)) return
                        enqueueSnackbar("Unable to get complete the request", {variant: 'error'});

            })
        }).catch(error => {
           if (axios.isCancel(error)) return
                        enqueueSnackbar("Unable to complete the request", {variant: 'error'});

        })
    }

    return (
        <React.Fragment>
            <Grid container direction="row">
                <Grid item xs={12} md={7} lg={7}>
                        <Card className={classes.content} elevation={0}>
                            <CardContent>
                                <Typography align={"center"} variant={"h4"}>
                                    {query == "enquire" && (
                                        <>
                                            Let's go...
                                        </>
                                    )}
                                    {query == "buy" && (
                                        <>
                                            You are one step closer to owning the
                                            <br/>{FINAL_PRODUCT.title}
                                        </>
                                    )}
                                    {query == "summary" && (
                                        <>
                                            Download a summary of the <br/>{FINAL_PRODUCT.title}
                                        </>
                                    )}
                                    <hr style={{backgroundColor: INFORMATION.buttonColor, height: "2px", border: 0}}/>
                                </Typography>
                                <Typography align={"center"} className={classes.stepPaddingTitle} variant={"subtitle1"}>

                                    Please fill in your details
                                </Typography>
                                <Formik
                                    initialValues={values}
                                    isInitialValid={false}
                                    onSubmit={async (values, actions) => {
                                        await newState(values)
                                        console.log("Done submitting", FINAL_PRODUCT)
                                    }}
                                    validationSchema={validationSchema}
                                >
                                    {(props) => {
                                        let {
                                            values: {
                                                name,
                                                phone,
                                                email,
                                            },
                                            touched,
                                            errors,
                                            handleBlur,
                                            handleChange,
                                            isSubmitting,
                                            isValid,
                                            submitForm,
                                            setFieldTouched,
                                            setFieldValue,
                                            resetForm,
                                            onSubmit
                                        } = props;
                                        return (
                                            <Form>
                                                <Grid container className={classes.root} direction="row">
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                          lg={12}>
                                                        <TextField
                                                            id="name"
                                                            name="name"
                                                            label="Your Name"
                                                            value={name}
                                                            fullWidth
                                                            variant="outlined"
                                                            helperText={touched.name ? errors.name : ""}
                                                            error={touched.name && Boolean(errors.name)}
                                                            onChange={(event) => {
                                                                setFieldValue("name", event.target.value)
                                                                setFieldTouched("name", true, false);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                          lg={12}>
                                                        <TextField
                                                            id=" email"
                                                            name="email"
                                                            type="email"
                                                            label="Email"
                                                            fullWidth
                                                            value={email}
                                                            variant="outlined"
                                                            helperText={touched.email ? errors.email : ""}
                                                            error={touched.email && Boolean(errors.email)}
                                                            onChange={(event) => {
                                                                setFieldValue("email", event.target.value)
                                                                setFieldTouched("email", true, false);
                                                            }}
                                                        />{" "}
                                                    </Grid>
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                          lg={12}>
                                                        <TextField
                                                            id=" phone"
                                                            name="phone"
                                                            type="tel"
                                                            label="Telephone"
                                                            fullWidth
                                                            value={phone}
                                                            variant="outlined"
                                                            helperText={touched.phone ? errors.phone : ""}
                                                            error={touched.phone && Boolean(errors.phone)}
                                                            onChange={(event) => {
                                                                setFieldValue("phone", event.target.value)
                                                                setFieldTouched("phone", true, false);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid container direction="row">
                                                        <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                              lg={12}>
                                                            {query == "enquire" && !loader && (
                                                                <Button className={classes.button} variant={"contained"}
                                                                        color="primary"
                                                                        disabled={!isValid}
                                                                        onClick={() => {
                                                                            submitForm().then(r => {
                                                                                createLead(`/${dealerModel}/${modelId}/${query}/done/`)
                                                                            })
                                                                        }}
                                                                >
                                                                    Enquire Now
                                                                </Button>
                                                            )}

                                                            {query == "buy" && !loader && (
                                                                <Button className={classes.button} variant={"contained"}
                                                                        color="primary"
                                                                        disabled={!isValid}
                                                                        onClick={() => {
                                                                            submitForm().then(r => {
                                                                                createLead(`/${dealerModel}/${modelId}/buy/tradeInQuestion/`)
                                                                            })
                                                                        }}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            )}

                                                            {query == "summary" && !loader && (
                                                                <Button className={classes.button} variant={"contained"}
                                                                        color="primary"
                                                                        disabled={!isValid}
                                                                        onClick={() => {
                                                                            submitForm().then(r => {
                                                                                createLead(`/${dealerModel}/${modelId}/summary/done/`)
                                                                            });
                                                                        }}
                                                                >
                                                                    Download Now
                                                                </Button>
                                                            )}
                                                            {loader == true && (
                                                                <Button className={classes.button} variant={"contained"}
                                                                        color="primary"
                                                                        disabled={true}
                                                                >
                                                                    <CircularProgress />
                                                                </Button>
                                                            )}
                                                        </Grid>
                                                        <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                              lg={12}>
                                                            <Button variant={"contained"} color="primary"
                                                                    className={classes.button}
                                                                    onClick={() => {
                                                                        let temp = `/${dealerModel}/${modelId}/build`;
                                                                        INFORMATION.query = false
                                                                        context.setTransmissionData({})
                                                                        history.push(temp);
                                                                    }}>
                                                                Cancel
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </CardContent>
                        </Card>
                </Grid>
                <Grid item className={classes.stepPaddingImage} xs={12} md={4} lg={5}>
                    <Card className={classes.content}>
                        <CardActionArea>
                            <CardMedia className={classes.image}
                                       component="img"
                                       alt="Features"
                                       image={FINAL_PRODUCT['image']}
                                       title={FINAL_PRODUCT['title']}
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
