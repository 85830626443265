import React, {useState, useContext, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from "@material-ui/core/CardActionArea";
import {FINAL_PRODUCT, INFORMATION} from "../../shared/Global";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
        marginTop: "2rem",
    },
    stepPaddingButton: {
        marginLeft: "1rem",
        marginTop: "1rem",
    },
    stepPaddingTitle: {
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    stepPaddingImage: {
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    card: {
        width: "100%"
    },
    button: {
        width: "100%",
    },
    backButton: {
        marginTop: "2rem",
    }
}));

export default function PQContainerDecline(props) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid container direction="row">
                <Grid item xs={12}>
                    <Card className={classes.content} elevation={0}>
                        <CardContent>
                            <Typography align={"center"} variant={"h4"}>
                                Thank you for your application.
                                <hr style={{backgroundColor: INFORMATION.buttonColor, height: "2px", border: 0}}/>
                            </Typography>
                            <Typography  align={"center"} className={classes.stepPaddingTitle} variant={"subtitle1"}>
                               Unfortunately we were not able to prequalify you at this stage. Please try again later.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
