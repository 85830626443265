import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FacebookIcon from '@material-ui/icons/Facebook';
import IconButton from '@material-ui/core/IconButton';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: '#333'
    },
    container: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(8)
    },
    iconsWrapper: {
        height: 120,
    },
    icons: {
        display: 'flex',
    },
    icon: {
        width: 48,
        height: 48,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        color: '#000000'
    },
    list: {
        margin: 0,
        listStyle: 'none',
        padding: 0,
    },
    listItem: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },
    language: {
        marginTop: theme.spacing(1),
        width: 150,
    },
    footerIconSection: {
        backgroundColor: '#1F2532'
    },
    footerIconSectionLink: {
        color: '#ffffff'
    },
    footerIconSectionIcon: {
        color: '#fbce32'
    },
    footerLink: {
        color: '#ffffff',

        '&:hover': {
            color: '#ffffff'
        }
    }
}));

export default function Footer() {
    const classes = useStyles();

    return (
        <Fragment>
            <Typography component="footer" className={classes.root}>
                <Container className={classes.container}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Typography classes={{root: classes.footerLink}} variant="h6" marked="left" gutterBottom>
                                Renault
                            </Typography>
                            <ul className={classes.list}>
                                <li className={classes.listItem}>
                                    <Link className={classes.footerLink} href="https://www.renaultvirtual.co.za/our-vehicles">Renault Range</Link>
                                </li>
                                <li className={classes.listItem}>
                                    <Link className={classes.footerLink} href="https://www.renaultvirtual.co.za/special-deals">Renault Offers</Link>
                                </li>
                                <li className={classes.listItem}>
                                    <Link className={classes.footerLink} href="https://www.renaultvirtual.co.za">Book A Test Drive</Link>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Typography classes={{root: classes.footerLink}} variant="h6" marked="left" gutterBottom>
                                <Link className={classes.footerLink} href="https://www.renaultvirtual.co.za/contact-us">Contact Us</Link>
                            </Typography>
                            <ul className={classes.list}>
                                <li className={classes.listItem}>
                                    <Link className={classes.footerLink} href="https://www.renaultvirtual.co.za">Book A Service</Link>
                                </li>
                                <li className={classes.listItem}>
                                    <Link className={classes.footerLink} href="mailto:customercare@renaultretail.co.za">Customer Care</Link>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Typography classes={{root: classes.footerLink}} variant="h6" marked="left" gutterBottom>
                                Legal
                            </Typography>
                            <ul className={classes.list}>
                                <li className={classes.listItem}>
                                    <Link className={classes.footerLink} href="https://www.renaultvirtual.co.za/terms">Terms And Conditions</Link>
                                </li>
                                <li className={classes.listItem}>
                                    <Link className={classes.footerLink} href="https://www.renaultvirtual.co.za/cookies">Cookie Policy</Link>
                                </li>
                                <li className={classes.listItem}>
                                    <Link className={classes.footerLink} href="https://paia.motuscorp.co.za">PAIA</Link>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Grid
                                container
                                direction="column"
                                justify="flex-start"
                                className={classes.iconsWrapper}
                                spacing={2}
                            >
                                <Grid item className={classes.icons}>
                                    <IconButton className={classes.icon}>
                                        <Link href="https://www.facebook.com/renaultvirtual" className={classes.footerLink} target="_blank">
                                            <FacebookIcon />
                                        </Link>
                                    </IconButton>
                                    <IconButton className={classes.icon}>
                                        <Link href="https://www.instagram.com/renault_sa/" className={classes.footerLink} target="_blank">
                                            <InstagramIcon />
                                        </Link>
                                    </IconButton>
                                    <IconButton className={classes.icon}>
                                        <Link href="https://www.youtube.com/user/RenaultSouthAfrica" className={classes.footerLink} target="_blank">
                                            <YouTubeIcon />
                                        </Link>
                                    </IconButton>
                                    <IconButton className={classes.icon}>
                                        <Link
                                            href="https://twitter.com/renault_sa"
                                            className={classes.footerLink}
                                            target="_blank"
                                        >
                                            <TwitterIcon />
                                        </Link>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Typography>
        </Fragment>
    );
}
