import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Typography } from "@material-ui/core";
import { Switch } from 'formik-material-ui';
import { DatePicker } from 'formik-material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import axios from 'axios'
import { useParams } from "react-router-dom";
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'
import TextMaskCustom from '../../shared/TextMask'
import { BASE_URI, LOOKUP_URI, PHONE_REGEX } from '../../shared/Constants'
import FormActions from '../../shared/FormActions'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Hidden from '@material-ui/core/Hidden';
import SelectInput from '../../shared/AutoCompleteComponent'
import LoadingCardComponent from '../../shared/LoadingCardComponent'

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    },
}));

export default function CustomerTabContainer({ next }) {
    const classes = useStyles();
    const mountedRef = useRef(true)
    const { leadId } = useParams();

    const initialValues = {
        sameAsResident: false,
        initials: '',
        birthCountry: '',
        birthCountryId: '',
        email: '',
        ethnicGroup: '',
        ethnicGroupId: '',
        firstName: '',
        homePhoneNumber: '',
        homePhoneAreaCode: '',
        mobilePhoneAreaCode: '',
        cellphone: '',
        identityNumber: '',
        lastName: '',
        gender: '',
        genderId: '',
        maritalStatus: '',
        maritalStatusId: '',
        maritalType: '',
        maritalTypeId: '',
        dateMarried: new Date(),
        nationality: '',
        nationalityCountryId: '',
        title: '',
        titleId: '',
        address1: '',
        address2: '',
        city: '',
        cityId: 0,
        postalAddress1: '',
        postalAddress2: '',
        postalCity: '',
        postalCityId: 0,
        postalPostCode: '',
        postalSuburb: '',
        postCode: '',
        receiveMailAtAddress: true,
        residentialStatus: '',
        residentialStatusId: '',
        residentSince: new Date(),
        suburb: '',
        province: '',
        provinceId: 0,
        postalProvince: '',
        postalProvinceId: 0
    }
    const [formFields, setFormFields] = useState(initialValues)
    const [isAddMode, setIsAddMode] = useState(true);
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const [titles, setTitles] = useState([])
    const [maritalStatuses, setMaritalStatuses] = useState([])
    const [maritalTypes, setMaritalTypes] = useState([])
    const [countries, setCountries] = useState([])
    const [provinceArr, setProvinces] = useState([])
    const [cities, setCities] = useState([])
    const [postalCities, setPostalCities] = useState([])
    const [ethnicGroups, setEthnicGroups] = useState([])
    const [residentialStatus, setResidentialStatus] = useState([])
    const [genderArr, setGenderArr] = useState([])
    const [togglePostalDetails, setTogglePostalDetails] = useState(false)


    const getMaritalTypes = (id) => {
        if (!id) {
            return;
        }

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        return axios.get(`${BASE_URI}/${LOOKUP_URI}/maritalType/${id}`, {
            cancelToken: source.token
        })
    }

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setLoading(true)
        const getCustomerData = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/Applications/Customer/public/${leadId}`, {
                    cancelToken: source.token
                })
                let formData = result.data
                setFormFields(formData)
                setIsAddMode(false)
                setLoading(false)

                getMaritalTypes(result.data.maritalStatusId).then((res) => {
                    setMaritalTypes(res.data)
                })

            } catch (error) {
                setLoading(false)
                if (axios.isCancel(error)) return
            }
        }
        getCustomerData().then(() => {
            if (!mountedRef.current) return null
        })

        return () => {
            mountedRef.current = false
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadId]);

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const getCountryList = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/countries`, {
                    cancelToken: source.token
                })
                setCountries(result.data)
            } catch (error) {
                if (axios.isCancel(error)) return
                enqueueSnackbar("Unable to get country list, please refresh", { variant: 'error' });
            }
        }

        const getTitleList = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/title`, {
                    cancelToken: source.token
                })
                setTitles(result.data)
            } catch (error) {
                if (axios.isCancel(error)) return
                enqueueSnackbar("Unable to get title list, please refresh", { variant: 'error' });
            }
        }

        const getMaritalStatuses = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/maritalStatus`, {
                    cancelToken: source.token
                })
                setMaritalStatuses(result.data)
            } catch (error) {
                if (axios.isCancel(error)) return
                enqueueSnackbar("Unable to get Marital status list, please refresh", { variant: 'error' });
            }
        }

        const getResidentialStatus = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/residentialStatus`, {
                    cancelToken: source.token
                })
                setResidentialStatus(result.data)
            } catch (error) {
                if (axios.isCancel(error)) return
                enqueueSnackbar("Unable to get residential status list, please refresh", { variant: 'error' });
            }
        }

        const getEthnicGroups = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/ethnicGroup`, {
                    cancelToken: source.token
                })
                setEthnicGroups(result.data)
            } catch (error) {
                if (axios.isCancel(error)) return
                enqueueSnackbar("Unable to get ethnic groups list, please refresh", { variant: 'error' });
            }
        }

        const getGenderArr = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/Gender`, {
                    cancelToken: source.token
                })
                setGenderArr(result.data)
            } catch (error) {
                if (axios.isCancel(error)) return
                enqueueSnackbar("Unable to get gender list, please refresh", { variant: 'error' });
            }
        }

        const getRegionArr = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/region`, {
                    cancelToken: source.token
                })
                setProvinces(result.data)
            } catch (error) {
                if (axios.isCancel(error)) return
                enqueueSnackbar("Unable to get province list, please refresh", { variant: 'error' });
            }
        }

        getCountryList().then(() => {
            if (!mountedRef.current) return null
        })

        getRegionArr().then(() => {
            if (!mountedRef.current) return null
        })

        getTitleList().then(() => {
            if (!mountedRef.current) return null
        })

        getMaritalStatuses().then(() => {
            if (!mountedRef.current) return null
        })

        getEthnicGroups().then(() => {
            if (!mountedRef.current) return null
        })

        getGenderArr().then(() => {
            if (!mountedRef.current) return null
        })

        getResidentialStatus().then(() => {
            if (!mountedRef.current) return null
        })

        return () => {
            mountedRef.current = false
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSelectChange = (e, value, setFieldValue, fieldName, fieldId) => {
        setFieldValue(fieldName, e.target.value)
        setFieldValue(fieldId, value.props.id)
    }

    function onSubmit(fields, { setStatus, setSubmitting }) {
        let tmpFields = { ...fields }
        tmpFields.homePhoneAreaCode = fields.homePhoneNumber ? fields.homePhoneNumber.substring(0, 3) : ''
        tmpFields.mobilePhoneAreaCode = fields.cellphone.substring(0, 3)
        tmpFields.ethnicGroupId = parseInt(fields.ethnicGroupId, 10)
        tmpFields.titleId = parseInt(fields.titleId, 10)
        tmpFields.residentialStatusId = parseInt(fields.residentialStatusId, 10)
        tmpFields.maritalTypeId = fields.maritalTypeId ? parseInt(fields.maritalTypeId, 10) : 0
        tmpFields.genderId = parseInt(fields.genderId, 10)
        tmpFields.birthCountryId = parseInt(fields.birthCountryId, 10)
        tmpFields.maritalStatusId = parseInt(fields.maritalStatusId, 10)
        tmpFields.dateMarried = fields.maritalStatus === 'Unmarried' ? null : fields.dateMarried
        setStatus();
        if (isAddMode) {
            createCustomerApplication(leadId, tmpFields, setSubmitting);
        } else {
            editCustomerApplication(leadId, tmpFields, setSubmitting);
        }
    }

    const createCustomerApplication = async (id, fields, setSubmitting) => {
        try {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            const result = await axios.post(`${BASE_URI}/Applications/Customer/${id}`, fields, {
                cancelToken: source.token
            })
            enqueueSnackbar('Successfully uploaded personal details', { variant: 'success' });
            next(fields.maritalStatus === "Married")
            return result;
        } catch (error) {
            enqueueSnackbar('Error uploading personal details, please try again later.', { variant: 'error' });
        } finally {
            setSubmitting(false)
        }
    }


    const editCustomerApplication = async (id, fields, setSubmitting) => {
        try {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            const result = await axios.put(`${BASE_URI}/Applications/Customer/${id}`, fields, {
                cancelToken: source.token
            })
            enqueueSnackbar('Successfully edited personal details', { variant: 'success' });
            next(fields.maritalStatus === "Unmarried")
            return result;
        } catch (error) {
            enqueueSnackbar('Error editing personal details, please try again later.', { variant: 'error' });
        } finally {
            setSubmitting(false)
        }
    }

    const handleProvinceChange = async (e, value) => {
        if (value && value.id) {
            getCities(value.id).then((res) => {
                // setDisableOccupationsList(false)
                setCities(res.data)
            }).catch((err) => {
                // setDisableOccupationsList(true)
                enqueueSnackbar("Unable to get city list, please refresh", { variant: 'error' });
            })
        }
    }

    const handlePostalProvinceChange = async (e, value) => {
        if (value && value.id) {
            getCities(value.id).then((res) => {
                // setDisableOccupationsList(false)
                setPostalCities(res.data)
            }).catch((err) => {
                enqueueSnackbar("Unable to get city list, please refresh", { variant: 'error' });
                // setDisableOccupationsList(true)
            })
        }
    }


    const getCities = (id) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        return axios.get(`${BASE_URI}/${LOOKUP_URI}/city/${id}`, {
            cancelToken: source.token
        })
    }

    const handleMaritalChange = async (e, value, setFieldValue) => {
        setFieldValue('maritalStatus', e.target.value)
        setFieldValue('maritalStatusId', value.props.id)
        if (value && value.props.id) {
            getMaritalTypes(value.props.id).then((res) => {
                
                setMaritalTypes(res.data)
            }).catch((err) => {
                
            })
        }
    }

    
    return (
        <div className={classes.root}>
            <Formik
                initialValues={formFields}
                isInitialValid={false}
                enableReinitialize={true}
                onSubmit={onSubmit}
                validationSchema={Yup.object().shape({
                    sameAsResident: Yup.boolean(),
                    firstName: Yup.string().required('First Name is required'),
                    lastName: Yup.string().required('Surname is required'),
                    email: Yup.string().email('Email is invalid').required('Email is required'),
                    ethnicGroup: Yup.string().required('Ethnic Group is required'),
                    birthCountry: Yup.string().required('Birth Country is required'),
                    cellphone: Yup.string().matches(PHONE_REGEX, 'Mobile number is not valid').required('Mobile number is required'),
                    identityNumber: Yup.string().required('ID Number is required'),
                    maritalStatus: Yup.string().required('Marital Status is required'),
                    
                    nationality: Yup.string().required('Nationality is required'),
                    title: Yup.string().required('Title is required'),
                    // preferredStatementAddress: Yup.string().required('Statement Address is required'),
                    // preferredContactAddress: Yup.string().required('Contact Address is required'),
                    address1: Yup.string().required('Address is required'),
                    city: Yup.string().required('City is required'),
                    postCode: Yup.string().required('Postal Code is required'),
                    residentialStatus: Yup.string().required('Residential Status is required'),
                    residentSince: Yup.string().required('Resident since is required'),
                    suburb: Yup.string().required('Suburb is required'),
                })}
            >
                {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        handleBlur,
                        handleChange,
                        isSubmitting,
                        handleReset,
                        setFieldValue
                    } = props
                    return (
                        <React.Fragment>
                            {
                                loading && <LoadingCardComponent />
                            }
                            {
                                !loading &&
                                <Form>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Card style={{ marginBottom: '1rem', marginTop: '1.5rem' }} elevation={5}>
                                            <Grid spacing={4} className={classes.stepPadding} container direction="row">
                                                <Grid item xs={12} style={{ paddingBottom: 0 }}>
                                                    <Toolbar disableGutters>
                                                        <Grid item container>
                                                            <Grid item xs={12} md={4}>
                                                                <Typography variant="h6" id="tableTitle"
                                                                    component="div">
                                                                    Personal Details
                                                                </Typography>
                                                            </Grid>

                                                            {/*//@TODO DealerNet OptionalFields*/}
                                                            {/*<Grid item container justify={ matches ? `flex-end`: 'flex-start'} xs={12} md={8}>*/}
                                                            {/*<FormControlLabel*/}
                                                            {/*control={*/}
                                                            {/*<Field component={Switch}*/}
                                                            {/*color="primary"*/}
                                                            {/*type="checkbox"*/}
                                                            {/*name="hasForeignTaxObligation" />*/}
                                                            {/*}*/}
                                                            {/*label="Foreign Tax Obligation"*/}
                                                            {/*/>*/}
                                                            {/*<FormControlLabel*/}
                                                            {/*control={*/}
                                                            {/*<Field component={Switch}*/}
                                                            {/*color="primary"*/}
                                                            {/*type="checkbox"*/}
                                                            {/*name="hasUsNationality" />*/}
                                                            {/*}*/}
                                                            {/*label="US Citizen"*/}
                                                            {/*/>*/}
                                                            {/*<FormControlLabel*/}
                                                            {/*control={*/}
                                                            {/*<Field component={Switch}*/}
                                                            {/*color="primary"*/}
                                                            {/*type="checkbox"*/}
                                                            {/*name="hasMultiNationality" />*/}
                                                            {/*}*/}
                                                            {/*label="Dual Citizenship"*/}
                                                            {/*/>*/}
                                                            {/*</Grid>*/}
                                                        </Grid>
                                                    </Toolbar>
                                                </Grid>

                                                <Grid item xs={12} md={6} container alignContent="flex-start"
                                                    spacing={3}>
                                                    <Grid item xs={12} md={3}>
                                                        <TextField
                                                            fullWidth
                                                            id="title"
                                                            select
                                                            required
                                                            label="Title"
                                                            value={values.title}
                                                            helperText={errors.title && touched.title ? errors.title : ''}
                                                            error={errors.title && touched.title}
                                                            onBlur={handleBlur("title")}
                                                            onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'title', 'titleId')}
                                                        >
                                                            {titles.map((option) => (
                                                                <MenuItem key={option.id} id={option.id}
                                                                    value={option.name}>
                                                                    {option.name}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>

                                                    </Grid>

                                                    <Grid item xs={12} md={3}>
                                                        <TextField
                                                            id="initials"
                                                            required
                                                            fullWidth
                                                            name="initials"
                                                            label="Initials"
                                                            value={values.initials}
                                                            helperText={errors.initials && touched.initials ? errors.initials : ''}
                                                            error={errors.initials && touched.initials}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            id="firstName"
                                                            fullWidth
                                                            name="firstName"
                                                            required
                                                            label="First Name"
                                                            value={values.firstName}
                                                            helperText={errors.firstName && touched.firstName ? errors.firstName : ''}
                                                            error={errors.firstName && touched.firstName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            id="gender"
                                                            select
                                                            label="Gender"
                                                            value={values.gender}
                                                            helperText={errors.gender && touched.gender ? errors.gender : ''}
                                                            error={errors.gender && touched.gender}
                                                            onBlur={handleBlur("gender")}
                                                            onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'gender', 'genderId')}
                                                        >
                                                            {genderArr.map((option) => (
                                                                <MenuItem key={option.id} id={option.id}
                                                                    value={option.name}>
                                                                    {option.name}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>

                                                    {/*@TODO Home Language*/}
                                                    {/*<Grid item xs={12} md={6}>*/}
                                                    {/*<TextField*/}
                                                    {/*fullWidth*/}
                                                    {/*id="homeLanguage"*/}
                                                    {/*select*/}
                                                    {/*label="Home Language"*/}
                                                    {/*value={values.homeLanguage}*/}
                                                    {/*helperText={errors.homeLanguage && touched.homeLanguage ? errors.homeLanguage : ''}*/}
                                                    {/*error={ errors.homeLanguage && touched.homeLanguage}*/}
                                                    {/*onBlur={handleBlur("homeLanguage")}*/}
                                                    {/*onChange={(e,child) => handleSelectChange(e, child, setFieldValue, 'homeLanguage', 'homeLanguageId')}*/}
                                                    {/*>*/}
                                                    {/*{languagesArr.map((option) => (*/}
                                                    {/*<MenuItem key={option.id} id={option.id} value={option.name}>*/}
                                                    {/*{option.name}*/}
                                                    {/*</MenuItem>*/}
                                                    {/*))}*/}
                                                    {/*</TextField>*/}
                                                    {/*</Grid>*/}

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            id="identityNumber"
                                                            label="ID Number"
                                                            required
                                                            name="identityNumber"
                                                            fullWidth
                                                            value={values.identityNumber}
                                                            helperText={errors.identityNumber && touched.identityNumber ? errors.identityNumber : ''}
                                                            error={errors.identityNumber && touched.identityNumber}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="maritalStatus"
                                                            select
                                                            required
                                                            label="Marital Status"
                                                            value={values.maritalStatus}
                                                            helperText={errors.maritalStatus && touched.maritalStatus ? errors.maritalStatus : ''}
                                                            error={errors.maritalStatus && touched.maritalStatus}
                                                            onBlur={handleBlur("maritalStatus")}
                                                            onChange={(e, child) => handleMaritalChange(e, child, setFieldValue)}
                                                        >
                                                            {maritalStatuses.map((option) => (
                                                                <MenuItem key={option.id} id={option.id}
                                                                    value={option.name}>
                                                                    {option.name}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>

                                                </Grid>

                                                {/*COL2*/}
                                                <Grid item container xs={12} md={6} alignContent="flex-start"
                                                    spacing={3}>
                                                    <Grid item xs={12} md={12}>
                                                        <TextField
                                                            id="lastName"
                                                            name="lastName"
                                                            label="Surname"
                                                            required
                                                            fullWidth
                                                            value={values.lastName}
                                                            helperText={errors.lastName && touched.lastName ? errors.lastName : ''}
                                                            error={errors.lastName && touched.lastName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <TextField
                                                            id=" email"
                                                            name="email"
                                                            type="email"
                                                            label="Email"
                                                            required
                                                            fullWidth
                                                            value={values.email}
                                                            helperText={errors.email && touched.email ? errors.email : ''}
                                                            error={errors.email && touched.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </Grid>
                                                    <Hidden xsDown>
                                                        <Grid item md={12} style={{ minHeight: '72px' }}></Grid>
                                                    </Hidden>
                                                    {
                                                        values.maritalStatus === 'Married' &&
                                                        <React.Fragment>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    id="maritalType"
                                                                    select
                                                                    required
                                                                    label="Marital Type"
                                                                    value={values.maritalType}
                                                                    helperText={errors.maritalType && touched.maritalType ? errors.maritalType : ''}
                                                                    error={errors.maritalType && touched.maritalType}
                                                                    onBlur={handleBlur("maritalType")}
                                                                    onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'maritalType', 'maritalTypeId')}
                                                                >
                                                                    {maritalTypes.map((option) => (
                                                                        <MenuItem key={option.id} id={option.id}
                                                                            value={option.name}>
                                                                            {option.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={12} md={3}>
                                                                <Field component={DatePicker}
                                                                    autoOk
                                                                    fullWidth
                                                                    format="dd/MM/yyyy"
                                                                    label="Date Married"
                                                                    name="dateMarried" />
                                                            </Grid>
                                                        </React.Fragment>
                                                    }
                                                </Grid>

                                                <Grid item xs={12} md={4}>
                                                    <Field
                                                        component={SelectInput}
                                                        props={props}
                                                        fullWidth
                                                        required
                                                        Label={"Birth Country"}
                                                        fieldName={"birthCountry"}
                                                        fieldNameID={"birthCountryId"}
                                                        data={countries}
                                                        onBlur={handleBlur("birthCountry")}
                                                        helperText={errors.birthCountry && touched.birthCountry ? errors.birthCountry : 'Start Typing'}
                                                        error={errors.birthCountry && touched.birthCountry}
                                                        value={{
                                                            name: values.birthCountry ? values.birthCountry.toString() : '',
                                                            id: values.birthCountryId ? values.birthCountryId.toString() : '',
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={4}>
                                                    <Field
                                                        component={SelectInput}
                                                        props={props}
                                                        fullWidth
                                                        required
                                                        Label={"Nationality"}
                                                        fieldName={"nationality"}
                                                        fieldNameID={"nationalityCountryId"}
                                                        data={countries}
                                                        onBlur={handleBlur("nationality")}
                                                        helperText={errors.nationality && touched.nationality ? errors.nationality : 'Start Typing'}
                                                        error={errors.nationality && touched.nationality}
                                                        value={{
                                                            name: values.nationality ? values.nationality.toString() : '',
                                                            id: values.nationalityCountryId ? values.nationalityCountryId.toString() : '',
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        fullWidth
                                                        id="ethnicGroup"
                                                        select
                                                        required
                                                        label="Ethnic Group"
                                                        value={values.ethnicGroup}
                                                        helperText={errors.ethnicGroup && touched.ethnicGroup ? errors.ethnicGroup : ''}
                                                        error={errors.ethnicGroup && touched.ethnicGroup}
                                                        onBlur={handleBlur("ethnicGroup")}
                                                        onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'ethnicGroup', 'ethnicGroupId')}
                                                    >
                                                        {ethnicGroups.map((option) => (
                                                            <MenuItem key={option.id} id={option.id}
                                                                value={option.name}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </Card>

                                        <Card style={{ marginBottom: '1rem' }} elevation={5}>
                                            <Grid spacing={4} className={classes.stepPadding} container direction="row">
                                                <Grid item xs={12} style={{ paddingBottom: 0 }}>
                                                    <Toolbar disableGutters>
                                                        <Typography style={{ flex: '1 1' }} variant="h6" id="tableTitle"
                                                            component="div">
                                                            Contact Details
                                                        </Typography>
                                                    </Toolbar>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        fullWidth
                                                        label="Mobile Number"
                                                        required
                                                        value={values.cellphone}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={errors.cellphone && touched.cellphone ? errors.cellphone : ''}
                                                        error={errors.cellphone && touched.cellphone}
                                                        name="cellphone"
                                                        id="cellphone"
                                                        InputProps={{
                                                            inputComponent: TextMaskCustom,
                                                        }}
                                                    />

                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        fullWidth
                                                        label="Home Phone Number"
                                                        value={values.homePhoneNumber}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={errors.homePhoneNumber && touched.homePhoneNumber ? errors.homePhoneNumber : ''}
                                                        error={errors.homePhoneNumber && touched.homePhoneNumber}
                                                        name="homePhoneNumber"
                                                        id="homePhoneNumber"
                                                        InputProps={{
                                                            inputComponent: TextMaskCustom,
                                                        }}
                                                    />
                                                </Grid>

                                            </Grid>
                                        </Card>

                                        <Card style={{ marginBottom: '1rem' }} elevation={5}>
                                            <Grid spacing={4} className={classes.stepPadding} container direction="row">
                                                <Grid item xs={12} style={{ paddingBottom: 0 }}>
                                                    <Toolbar disableGutters>
                                                        <Grid item container>
                                                            <Grid item xs={12} md={4}>
                                                                <Typography variant="h6" component="div" gutterBottom>
                                                                    Residential Details
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item container
                                                                justify={matches ? `flex-end` : 'flex-start'} xs={12}
                                                                md={8}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Field component={Switch}
                                                                            color="primary"
                                                                            type="checkbox"
                                                                            name="receiveMailAtAddress" />
                                                                    }
                                                                    label="Receive Mail at Address"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Toolbar>
                                                </Grid>

                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        id="address1"
                                                        name="address1"
                                                        required
                                                        label="Address 1"
                                                        fullWidth
                                                        value={values.address1}
                                                        helperText={errors.address1 && touched.address1 ? errors.address1 : ''}
                                                        error={errors.address1 && touched.address1}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        id="address2"
                                                        name="address2"
                                                        label="Address 2"
                                                        fullWidth
                                                        value={values.address2}
                                                        helperText={errors.address2 && touched.address2 ? errors.address2 : ''}
                                                        error={errors.address2 && touched.address2}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <TextField
                                                        id="postalCode"
                                                        name="postCode"
                                                        label="Postal Code"
                                                        required
                                                        fullWidth
                                                        value={values.postCode}
                                                        helperText={errors.postCode && touched.postCode ? errors.postCode : ''}
                                                        error={errors.postCode && touched.postCode}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={4}>
                                                    <Field
                                                        component={SelectInput}
                                                        props={props}
                                                        required
                                                        fullWidth
                                                        Label={"Province"}
                                                        fieldName={"province"}
                                                        fieldNameID={"provinceId"}
                                                        data={provinceArr}
                                                        onChange={handleProvinceChange}
                                                        onBlur={handleBlur("province")}
                                                        helperText={errors.province && touched.province ? errors.province : 'Start Typing'}
                                                        error={errors.province && touched.province}
                                                        value={{
                                                            name: values.province ? values.province.toString() : '',
                                                            id: values.provinceId ? values.provinceId.toString() : '',
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        id="city"
                                                        name="city"
                                                        label="City"
                                                        fullWidth
                                                        value={values.city}
                                                        helperText={errors.city && touched.city ? errors.city : ''}
                                                        error={errors.city && touched.city}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        id="suburb"
                                                        name="suburb"
                                                        label="Suburb"
                                                        required
                                                        fullWidth
                                                        value={values.suburb}
                                                        helperText={errors.suburb && touched.suburb ? errors.suburb : ''}
                                                        error={errors.suburb && touched.suburb}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>

                                                <Hidden xsDown>
                                                    <Grid item md={4}></Grid>
                                                </Hidden>

                                                <Grid item xs={12} md={3}>
                                                    <TextField
                                                        fullWidth
                                                        id="residentialStatus"
                                                        select
                                                        required
                                                        label="Residential Status"
                                                        value={values.residentialStatus}
                                                        helperText={errors.residentialStatus && touched.residentialStatus ? errors.residentialStatus : ''}
                                                        error={errors.residentialStatus && touched.residentialStatus}
                                                        onBlur={handleBlur("residentialStatus")}
                                                        onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'residentialStatus', 'residentialStatusId')}
                                                    >
                                                        {residentialStatus.map((option) => (
                                                            <MenuItem key={option.id} id={option.id}
                                                                value={option.name}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>

                                                <Grid item xs={12} md={3}>
                                                    <Field component={DatePicker}
                                                        autoOk
                                                        format="dd/MM/yyyy"
                                                        label="Resident Since"
                                                        name="residentSince" />
                                                </Grid>

                                            </Grid>
                                        </Card>

                                        <Card elevation={5}>
                                            <Grid spacing={4} className={classes.stepPadding} container direction="row">
                                                <Grid item xs={12} style={{ paddingBottom: 0 }}>
                                                    <Toolbar disableGutters>
                                                        <Grid item container>
                                                            <Grid item xs={12} md={4}>
                                                                <Typography variant="h6" component="div" gutterBottom>
                                                                    Postal Details
                                </Typography>
                                                            </Grid>
                                                            <Grid item container justify={matches ? `flex-end` : 'flex-start'} xs={12} md={8}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Field component={Switch}
                                                                            color="primary"
                                                                            type="checkbox"
                                                                            name="sameAsResident"
                                                                            onClick={() => setTogglePostalDetails(!togglePostalDetails)}
                                                                        />
                                                                    }
                                                                    label="Same as Resident Details"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Toolbar>
                                                </Grid>

                                                {!togglePostalDetails && <>
                                                    <Grid item xs={12} md={4}>
                                                        <TextField
                                                            id="postalAddress1"
                                                            name="postalAddress1"
                                                            required={!togglePostalDetails}
                                                            label="Postal Address 1"
                                                            fullWidth
                                                            value={values.postalAddress1}
                                                            helperText={errors.postalAddress1 && touched.postalAddress1 ? errors.postalAddress1 : ''}
                                                            error={errors.postalAddress1 && touched.postalAddress1}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <TextField
                                                            id="postalAddress2"
                                                            name="postalAddress2"
                                                            label="Postal Address 2"
                                                            fullWidth
                                                            value={values.postalAddress2}
                                                            helperText={errors.postalAddress2 && touched.postalAddress2 ? errors.postalAddress2 : ''}
                                                            error={errors.postalAddress2 && touched.postalAddress2}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={2}>
                                                        <TextField
                                                            id="postalPostCode"
                                                            name="postalPostCode"
                                                            required={!togglePostalDetails}
                                                            label="Postal Code"
                                                            fullWidth
                                                            value={values.postalPostCode}
                                                            helperText={errors.postalPostCode && touched.postalPostCode ? errors.postalPostCode : ''}
                                                            error={errors.postalPostCode && touched.postalPostCode}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={4}>
                                                        <Field
                                                            component={SelectInput}
                                                            props={props}
                                                            required={!togglePostalDetails}
                                                            fullWidth
                                                            Label={"Province"}
                                                            fieldName={"postalProvince"}
                                                            fieldNameID={"postalProvinceId"}
                                                            data={provinceArr}
                                                            onChange={handlePostalProvinceChange}
                                                            onBlur={handleBlur("postalProvince")}
                                                            helperText={errors.postalProvince && touched.postalProvince ? errors.postalProvince : 'Start Typing'}
                                                            error={errors.postalProvince && touched.postalProvince}
                                                            value={{
                                                                name: values.postalProvince ? values.postalProvince.toString() : '',
                                                                id: values.postalProvinceId ? values.postalProvinceId.toString() : '',
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={4}>
                                                        <TextField
                                                            id="postalCity"
                                                            name="postalCity"
                                                            label="City"
                                                            fullWidth
                                                            value={values.postalCity}
                                                            variant="outlined"
                                                            helperText={errors.postalCity && touched.postalCity ? errors.postalCity : ''}
                                                            error={errors.postalCity && touched.postalCity}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />

                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <TextField
                                                            id="postalSuburb"
                                                            name="postalSuburb"
                                                            label="Suburb"
                                                            fullWidth
                                                            required={!togglePostalDetails}
                                                            value={values.postalSuburb}
                                                            helperText={errors.postalSuburb && touched.postalSuburb ? errors.postalSuburb : ''}
                                                            error={errors.postalSuburb && touched.postalSuburb}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </Grid>
                                                </>}

                                            </Grid>
                                        </Card>

                                        <FormActions reset={() => handleReset()} />
                                    </MuiPickersUtilsProvider>
                                </Form>
                            }
                        </React.Fragment>
                    );
                }}
            </Formik>
        </div>
    );
}
