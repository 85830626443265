import React, { useState, useContext, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { BASE_URI } from "../../shared/Global";
import axios from "axios";
import { ConfiguratorContext } from "../../shared/contexts/ConfiguratorContext";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import CardActionArea from "@material-ui/core/CardActionArea";
import { FINAL_PRODUCT, LEAD } from "../../shared/Global";
import CardHeader from "@material-ui/core/CardHeader";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import { useParams, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { CircularProgress } from "material-ui";
import { useSnackbar } from "notistack";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { CurrencyValue } from "../../shared/TextMask";
import ShoppingCartIcon from "../../shared/assets/shopping-cart.png"

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        marginLeft: "2rem",
        marginRight: "2rem",
        marginTop: "2rem",
    },
    stepPaddingButton: {
        marginLeft: "0.5rem",
        marginTop: "0.5rem",
    },
    stepPaddingImage: {
        marginLeft: "1rem",
        marginRight: "2rem",
    },
    left: {
        float: "left"
    },
    right: {
        float: "right"
    },
    card: {
        width: "100%"
    },
    button: {
        width: "100%",
    },
    backButton: {
        marginTop: "2rem",
    },
    image: {
        width: "100%",
        height: "auto",
    },
    uppercase: {
        textTransform: 'uppercase'
    },
    header: {
        borderBottom: '1px solid #dedede',
        marginBottom: '40px',
        paddingBottom: '24px'
    },
    table: {
        '& tr > th:last-child': {
            fontWeight: 700
        },
        '& tr > td:last-child': {
            fontWeight: 700
        }
    },
    bold: {
        fontWeight: 700
    },
    link: {
        color: '#000000',
        textDecoration: 'underline',
        '&:hover': {
            color: '#000000',
            textDecoration: 'underline',
        }
    },
    cardHeader: {
        paddingBottom: 0
    },
    subheader: {
        fontSize: '1.3rem',
        color: '#000000'
    }

}));

export default function RenaultBrandContainer() {
    const classes = useStyles();
    const { dealerModel, renaultDigitalId } = useParams();
    const history = useHistory();
    const [loader, setLoader] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const [vehicle, setVehicle] = useState({});

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios.get(`${BASE_URI}/renaultDigital/${renaultDigitalId}`, {
            cancelToken: source.token
        }).then((result) => {
            var vehicleInformation = result.data;

            setVehicle(vehicleInformation);
        });
    }, []);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            // '&:nth-of-type(odd)': {
            //     backgroundColor: theme.palette.action.hover,
            // },
        },
    }))(TableRow);
    const StyledTableRowLast = withStyles((theme) => ({
        root: {
            backgroundColor: "lightgrey",
        },
    }))(TableRow);
    const StyledTableRowFirst = withStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.common.black,
            color: '#ffffff',
            '& > th': {
                color: '#ffffff',
            }
        },
    }))(TableRow);
    const StyledTableCell = withStyles((theme) => ({
        head: {
            // backgroundColor: theme.palette.common.black,
            // color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const createVehicleLead = () => {

        history.push('/' + dealerModel + '/step1/renaultDigital/' + renaultDigitalId);
    }

    const getTotalPrice = () => {

        var total = cleanPrice(vehicle.vehiclePrice);
        var exteriorPrice = cleanPrice(vehicle.exteriorPrice);
        var transmissionPrice = cleanPrice(vehicle.transmissionPrice);
        var accessoryPrice = cleanPrice(findAccessoriesPrice(vehicle.accessories));

        return total + exteriorPrice + transmissionPrice + accessoryPrice;
    }

    const cleanPrice = (price) => {

        if (!price) {
            return 0;
        }

        var value = price.replace("R ", "");
        value = value.replace("R", "")
        value = value.replace(" ", "")
        value = value.replace(".00", "")

        if (value.length == 0) {
            return 0;
        }

        return parseInt(value);
    }

    const findAccessoriesPrice = (accessory) => {
        if (!accessory) {
            return 0;
        }

        var idx = accessory.indexOf('R ');

        return accessory.substr(idx);
    }

    const findAccessoriesText = (accessory) => {

        if (!accessory) {
            return '';
        }

        var idx = accessory.indexOf('R ');

        return accessory.substr(0, idx);
    }

    return (
        <React.Fragment>
            <Grid container direction="row">
                <Grid item className={classes.stepPaddingImage} xs={12} md={6} lg={6}>
                    <Grid container direction={"row"}>
                        <Grid item xs={12} sm={3} md={2}>
                            <img src={ShoppingCartIcon} style={{width: 85, height: 85, marginBottom: 10}} />
                        </Grid>
                        <Grid item xs={12} sm={9} md={10}>
                            <Typography variant={'h5'}>
                                {vehicle.vehicle}
                            </Typography>
                            <Typography className={classes.bold} paragraph={true}>
                                Congratulations on customizing your New {vehicle.vehicle}!
                            </Typography>
                            <Typography paragraph={true}>
                                We look forward to having you join the family. If you’re happy
                                with your customization choices and ready to finance your
                                New {vehicle.vehicle} proceed to Checkout and Drive. If you want to make
                                changes <a href="https://www.renaultdigital.co.za/builder" className={classes.link}>click here</a>.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Card className={classes.content} elevation={0}>
                        <CardActionArea>
                            <CardMedia className={classes.image}
                                component="img"
                                alt="Features"
                                image={vehicle.imageUrl}
                                title={vehicle.vehicle}
                            />
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item xs={12} md={5} lg={5}>
                    <Grid container direction="row">
                        <Card elevation={0} className={classes.card}>
                            <CardActionArea>
                                <CardHeader
                                    subheader={vehicle.vehicle}
                                    classes={{ root: classes.cardHeader, subheader: classes.subheader }}
                                />
                                <CardContent>
                                    <TableContainer component={Paper} elevation={0}>
                                        <Table className={classes.table} aria-label="customized table">
                                            <TableHead>
                                                <StyledTableRowFirst>
                                                    <StyledTableCell>Purchase Price:</StyledTableCell>
                                                    <StyledTableCell
                                                        align="right"><CurrencyValue value={vehicle.vehiclePrice} /></StyledTableCell>
                                                </StyledTableRowFirst>
                                            </TableHead>
                                            <TableBody>

                                                {vehicle.exteriorColor != "" && (
                                                    <StyledTableRow>
                                                        <StyledTableCell>{vehicle.exteriorColor}</StyledTableCell>
                                                        <StyledTableCell
                                                            align="right"><CurrencyValue value={vehicle.exteriorPrice} returnOnZero={true} /></StyledTableCell>
                                                    </StyledTableRow>)}
                                                {vehicle.interiorColor != "" && (
                                                    <StyledTableRow>
                                                        <StyledTableCell>{vehicle.interiorColor}</StyledTableCell>
                                                        <StyledTableCell
                                                            align="right"><CurrencyValue value={0} returnOnZero={true} /></StyledTableCell>
                                                    </StyledTableRow>)}
                                                {vehicle.transmission != "" && (
                                                    <StyledTableRow>
                                                        <StyledTableCell>{vehicle.transmission}</StyledTableCell>
                                                        <StyledTableCell
                                                            align="right"><CurrencyValue value={vehicle.transmissionPrice} returnOnZero={true} /></StyledTableCell>
                                                    </StyledTableRow>)}
                                                {vehicle.accessories != "" && (
                                                    <StyledTableRow>
                                                        <StyledTableCell>{findAccessoriesText(vehicle.accessories)}</StyledTableCell>
                                                        <StyledTableCell
                                                            align="right"><CurrencyValue value={findAccessoriesPrice(vehicle.accessories)} returnOnZero={true} /></StyledTableCell>
                                                    </StyledTableRow>)}
                                                <StyledTableRowLast>
                                                    <StyledTableCell>Total:</StyledTableCell>
                                                    <StyledTableCell
                                                        align={"right"}><CurrencyValue value={getTotalPrice(vehicle.vehiclePrice)} /></StyledTableCell>
                                                </StyledTableRowLast>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                                <CardActions>
                                    <Grid container direction="row">
                                        <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>

                                            {!loader &&
                                                <Button className={classes.button} color="primary" variant={"contained"}
                                                    onClick={createVehicleLead}>
                                                    Checkout And Drive
                                            </Button>
                                            }

                                            {loader == true && (
                                                <Button className={classes.button} variant={"contained"}
                                                    color="primary"
                                                    disabled={true}
                                                >
                                                    <CircularProgress />
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
