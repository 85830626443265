import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { BASE_URI } from "../../shared/Global";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CheckIcon from '@material-ui/icons/Check';
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ImageCarousel from "../../shared/ImageCarousal";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { useHistory, useParams } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Carousel } from "antd";
import { INFORMATION, SET_BANNER, SET_FINAL_PRODUCT } from "../../shared/Global";
import { useSnackbar } from 'notistack'
import { useMediaQuery } from 'react-responsive';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    content: {
        display: 'flex !important',
        justifyContent: 'space-between',
        flexDirection: 'column',
        margin: '1rem',
        '&:hover': {
            '& $button': {
                display: "block"
            }
        }
    },
    stepPadding: {
        marginLeft: "2rem",
        marginRight: "2rem",
        marginTop: "2rem"
    },
    carouselContainer: {
        '& .slick-slide': {
            '& > div': {
                display: 'flex'
            }
        },
        '& .slick-dots': {
            '& li button': {
                height: 10,
                width: 10,
                border: '1px solid blue',
                borderRadius: 100
            },
            '& li.slick-active': {
                width: '16px',
            }
        },
    },
    backButton: {
        marginTop: "1rem",
        marginBottom: "1rem",
        marginRight: "1rem"
    },
    cardImage: {
        height: "200px",
        width: '100%',
    },
    uppercase: {
        textTransform: 'uppercase'
    }
}));

export default function AccessoriesContainer(props) {
    const classes = useStyles();

    const history = useHistory();
    const { dealerModel, modelId } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(true);

    const [accessories, setAccessories] = useState([]);

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        setLoading(true);
        const params = {
            "dealerVariantId": INFORMATION.dealerVariantId
        }
        axios({
            method: 'GET',
            url: `${BASE_URI}/accessories`,
            params,
            cancelToken: source.token
        }).then((response) => {

            if (response.data?.list) {
                setAccessories(response.data?.list)
            }
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get accessories, please refresh", { variant: 'error' });

            setLoading(false)
        })

        SET_BANNER("", "",
            "Select your accessory option",
            ""
        )
    }, []);

    function CarouselArrow(props) {
        const { className, style, onClick, left } = props;
        const iconStyle = { margin: '1rem', height: '4rem', width: '4rem' }
        return (
            <IconButton disableRipple onClick={onClick} style={{ color: '#bdbdbd' }} className={className}>
                {
                    left && <ChevronLeftIcon style={iconStyle} />
                }
                {
                    !left && <ChevronRightIcon style={iconStyle} />
                }
            </IconButton>
        );
    }

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    return (
        <React.Fragment>
            <Grid
                justify="space-between" // Add it here :)
                container
            >
                <Grid item>
                    <Button variant={"contained"} color="primary" className={classes.backButton}
                        onClick={() => {
                            props.props("Colour", "", "Transmission")
                        }}>
                        Back
                    </Button>
                </Grid>

                <Grid item>
                    <Button variant={"contained"} color="primary" className={classes.backButton}
                        onClick={() => {
                            let temp = `/${dealerModel}/${modelId}/build`;
                            history.push(temp);
                        }}>
                        Continue
                    </Button>
                </Grid>
            </Grid>

            {!accessories.length && !loading && (
                <Grid container justify="center" className="p-5">
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="textSecondary">
                            No Accessory packs available
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {loading == true && (
                <Grid container justify="center">
                    <Grid item xs={1}>
                        <Box mt={3}>
                            <CircularProgress />
                        </Box>
                    </Grid>
                </Grid>
            )}
            {(!loading && accessories && accessories.length > 0) && (
                <React.Fragment>
                    <Typography variant="h5">Do you want to add accessories?</Typography>
                    <Carousel slidesPerRow={isMobile ? 1 : 3}
                        nextArrow={<CarouselArrow />}
                        prevArrow={<CarouselArrow left />}
                        arrows
                        dots={false}
                        className={classes.carouselContainer} effect="fade">
                        {
                            accessories.map((tile) => {
                                return (
                                    <Card className={classes.content} elevation={0}>
                                        <CardContent style={{ padding: 0 }}>
                                            <GridList cols={tile.features.length <= 3 ? tile.features.length : 4}
                                                cellHeight={200}>
                                                {
                                                    tile.features.map((feature, index) => (
                                                        <GridListTile key={index}>
                                                            <CardMedia className={classes.cardImage}
                                                                component="img"
                                                                alt={feature['name']}
                                                                image={feature['image']}
                                                            />
                                                        </GridListTile>
                                                    ))
                                                }
                                            </GridList>
                                            <GridList cols={1} cellHeight={70}>
                                                <GridListTile>
                                                    <GridListTileBar
                                                        title={tile.title}
                                                        className={classes.uppercase}
                                                        subtitle={
                                                            <Grid container direction="column">
                                                                <Grid item>
                                                                    R {tile.price}
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    />
                                                </GridListTile>
                                            </GridList>
                                            <Box pt={3} pl={3}>
                                                <Typography variant="subtitle1">
                                                    Features:
                                            </Typography>
                                                <List dense>
                                                    {
                                                        tile?.features.map((feature, index) => {
                                                            return (
                                                                <ListItem key={index}>
                                                                    <ListItemIcon>
                                                                        <CheckIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={feature.name} />
                                                                </ListItem>
                                                            )
                                                        })
                                                    }
                                                </List>
                                            </Box>
                                        </CardContent>
                                        <CardActions style={{ minHeight: 60 }}>
                                            <Grid container direction="row"
                                                justify="center"
                                                alignItems="center">
                                                <Button variant={"contained"} color="primary" className={classes.button}
                                                    onClick={() => {
                                                        SET_FINAL_PRODUCT("", "", "",
                                                            "", "", "",
                                                            "", {
                                                            value: tile.title,
                                                            price: tile.price,
                                                            id: tile.id
                                                        }, "", "", "")
                                                        let temp = `/${dealerModel}/${modelId}/build`;
                                                        history.push(temp);
                                                    }}>
                                                    Select
                                            </Button>
                                            </Grid>
                                        </CardActions>
                                    </Card>
                                )
                            })
                        }
                    </Carousel>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
