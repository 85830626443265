import React, { useEffect } from 'react';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';
import clsx from 'clsx';
import PersonIcon from '@material-ui/icons/Person';
import WorkIcon from '@material-ui/icons/Work';
import GroupIcon from '@material-ui/icons/Group';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CustomerTabContainer from './CustomerTabContainer'
import WorkTabContainer from './WorkTabContainer'
import SpouseTabContainer from './SpouseTabContainer'
import FinancialTabContainer from './FinancialTabContainer'
import BankTabContainer from './BankTabContainer'
import LinearProgress from '@material-ui/core/LinearProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useParams, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  heading: {
    marginBottom: theme.breakpoints.up('sm') ? '1rem' : '2rem'
  }
}));

function getSteps() {
  return ['Personal Particulars', 'Employment', 'Relative', 'Income & expenses', 'Banking'];
}


const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
          backgroundColor: '#E20A17',
    },
  },
  completed: {
    '& $line': {
          backgroundColor: '#E20A17'
    },
  },
  line: {
    height: 4,
    border: 0,
      backgroundColor: '#E20A17',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
      backgroundColor: '#E20A17',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
      backgroundColor: '#E20A17',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <PersonIcon />,
    2: <WorkIcon />,
    3: <GroupIcon />,
    4: <CreditCardIcon />,
    5: <AccountBalanceIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? <Check className={classes.completed} /> : icons[String(props.icon)]}
    </div>
  );
}

export default function ApplicationContainer() {
  const classes = useStyles();
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  const [progress, setProgress] = React.useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { applicationStep } = useParams();
  const history = useHistory();
  const [activeStep, setActiveStep] = React.useState(applicationStep ? parseInt(applicationStep, 10) : 0);
  const { dealerModel, modelId, leadId } = useParams();

  //TEST marital status
  const [isMarried, setIsMarried] = React.useState(false)

  const handleScroll = () => {
    const anchor = document.querySelector('#back-to-top-anchor');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = (setMarried) => {
    if (setMarried !== undefined) {
      setIsMarried(setMarried)
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setProgress((oldProgress) => {
      if (oldProgress === 100) {
        return 0;
      }
      const diff = oldProgress + 25;
      return Math.min(diff, 100);
    });
    handleScroll()

    if (activeStep === 4) {
      history.push(`/${dealerModel}/${modelId}/enquire/documents/${leadId}`);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    handleScroll()
    setProgress((oldProgress) => {
      if (oldProgress === 0) {
        return 0;
      }
      const diff = oldProgress - 25;
      return Math.min(diff, 100);
    });
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function getStepContent(step) {
    switch (parseInt(step, 10)) {
      case 0:
        return <CustomerTabContainer next={handleNext} back={handleBack} skip={handleSkip} />;
      case 1:
        return <WorkTabContainer next={handleNext} back={handleBack} skip={handleSkip} />;
      case 2:
        return <SpouseTabContainer next={handleNext} back={handleBack} married={isMarried} />;
      case 3:
        return <FinancialTabContainer next={handleNext} back={handleBack} married={isMarried} />;
      case 4:
        return <BankTabContainer next={handleNext} back={handleBack} />;
      default:
        return 'Unknown step';
    }
  }

  useEffect(() => {
    if (applicationStep) {
      let appStep = parseInt(applicationStep, 10)
      setProgress((appStep * 25) > 100 ? 100 : (appStep * 25))
      setActiveStep(appStep)
    }
  }, [applicationStep])

  return (
    <Grid container direction='row'>

      <Grid item container xs={12} justify="center" alignItems="center">
        <Typography variant="h4" className={classes.heading} id="back-to-top-anchor">
          Finance Application
        </Typography>
      </Grid>
      <Grid item container xs={12} justify="center" alignItems="center">
        <Typography variant="h6">
          Congratulations, you have been pre-approved please complete the finance application
        </Typography>
      </Grid>

      {
        matches ?
          <Grid item xs={12}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid> :
          <Grid item container
            style={{ marginLeft: matches ? '5rem' : '1rem', marginRight: matches ? '5rem' : '1rem' }}
            justify="center" alignItems="center" alignContent="center" xs={12}>
            <Grid item xs={12}>
              <LinearProgress color="primary" variant="determinate" value={progress} />
            </Grid>
          </Grid>
      }

      <Grid item container xs={12}>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
            <div style={{ width: '100%' }}>
              {getStepContent(activeStep)}
            </div>
          )}
      </Grid>
    </Grid>
  );
}