import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URI } from "../../shared/Global";
import { ConfiguratorContext } from "../../shared/contexts/ConfiguratorContext";
import { FINAL_PRODUCT, INFORMATION, SET_BANNER, SET_FINAL_PRODUCT, LEAD } from "../../shared/Global";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Carousel } from "antd";
import { useSnackbar } from 'notistack'
import { useMediaQuery } from 'react-responsive';
import Box from '@material-ui/core/Box';
import { CurrencyValue } from "../../shared/TextMask";

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        marginLeft: "2rem",
        marginRight: "2rem",
        marginTop: "2rem",
        marginBottom: "4rem",
    },
    content: {
        display: 'flex !important',
        justifyContent: 'space-between',
        flexDirection: 'column',
        margin: '1rem',
        '&:hover': {
            '& $button': {
                opacity: 0.8
            }
        }
    },
    carouselContainer: {
        '& .slick-slide': {
            '& > div': {
                display: 'flex'
            }
        },
        '& .slick-dots': {
            '& li button': {
                height: 10,
                width: 10,
                border: '1px solid blue',
                borderRadius: 100
            },
            '& li.slick-active': {
                width: '16px',
            }
        },
    },
    button: {
        opacity: 0,
        marginBottom: "1.5rem",
        marginTop: "1rem"
    },
    vechileTitle: {
        fontWeight: 700
    },
    uppercase: {
        textTransform: 'uppercase'
    },
    disclaimer: {
        fontSize: '0.8rem',
        opacity: '0.8'
    }
}));

export default function RangeContainer() {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [cars, setCars] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    let { ...context } = useContext(ConfiguratorContext);

    const history = useHistory();
    const { dealerModel } = useParams();

    useEffect(() => {

        let params = {};
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        if (dealerModel) {
            params.dealerId = dealerModel;
        }
        axios({
            method: 'GET',
            url: `${BASE_URI}/dealerModels`,
            params,
            cancelToken: source.token
        }).then((response) => {
            if (response.data?.list) {
                setCars(response.data.list);
            }
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get models, please refresh", { variant: 'error' });
        })

    }, [dealerModel])

    function CarouselArrow(props) {
        const { className, style, onClick, left } = props;
        const iconStyle = { margin: '3rem', height: '4rem', width: '4rem' }
        return (
            <IconButton disableRipple onClick={onClick} style={{ color: '#bdbdbd' }} className={className}>
                {
                    left && <ChevronLeftIcon style={iconStyle} />
                }
                {
                    !left && <ChevronRightIcon style={iconStyle} />
                }
            </IconButton>
        );
    }

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    return (
        <React.Fragment>
            <Grid container direction="row" justify={"center"}>
                <Grid item className={classes.stepPadding} xs={12} md={6} lg={6}>
                    <Typography gutterBottom variant="h4" align={"center"} className={classes.uppercase}>
                        Congratulations
                    </Typography>
                    <Typography align={"center"} variant="h5" style={{ fontWeight: 400 }}>
                        {context.product.client.firstName}, let the fun begin. You can select from any of the following vehicles that you have been pre-qualified for.
                    </Typography>
                </Grid>
            </Grid>
            <Typography variant="h5" ml={2}><span style={{ color: '#fbce32' }} className={classes.uppercase}>Step 2</span> - Your Vehicle</Typography>
            <div align={"center"}>
                Please swipe to see more.
            </div>
            {(loading == false && cars.length > 0 && (
                <Carousel slidesPerRow={isMobile ? 1 : 3}
                    nextArrow={<CarouselArrow />}
                    prevArrow={<CarouselArrow left />}
                    arrows
                    dots={false}
                    className={classes.carouselContainer} effect="fade">
                    {
                        cars.map((car) => {
                            return (
                                <Card key={car.title} className={classes.content} elevation={0}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            alt="Variant"
                                            height="210"
                                            image={car.image}
                                            title={car.variant}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h5" className={classes.vechileTitle}>
                                                {car['title']}
                                            </Typography>
                                            <Typography variant="h6">
                                                From <CurrencyValue value={car['price']} />
                                            </Typography>
                                        </CardContent>
                                        <CardActions >
                                            <Grid container direction="row" justify="center" alignItems="center">
                                                <Button variant={"contained"}
                                                    color="primary"
                                                    className={classes.uppercase}
                                                    onClick={() => {

                                                        SET_FINAL_PRODUCT(car.title, car.model, "", car.price, "",
                                                            "", "", "", car['id'], "", "");

                                                        SET_BANNER(car.make + " " + car.title, car.model,
                                                            `Select your ${car.model} model`, car.image)

                                                        context.handleMenuClick("Derivative")

                                                        let temp = `/${dealerModel}/${car['id']}/builder`;
                                                        history.push(temp);
                                                    }}>
                                                    {/* Build your new {car['title']} */}
                                                    Select
                                                </Button>
                                            </Grid>
                                        </CardActions>
                                    </CardActionArea>
                                </Card>
                            )
                        })
                    }
                </Carousel>
            ))}
            <Box mt={4} className={classes.disclaimer}>Disclaimer: Please note that this process returns and indicative finance approval and amount. Finance approval is subject to standard terms and conditions.</Box>
            {(loading == false && cars.length <= 0 && (
                <Grid container direction="row">
                    No Models available for this dealer.
                </Grid>
            ))}
            {(loading && (
                <Grid container item justify='center' alignContent='center'>
                    <CircularProgress size={80} />
                </Grid>
            ))}
        </React.Fragment>
    );
}
