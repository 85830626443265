import React, {useState, useContext, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {BASE_URI} from "../../shared/Global";
import axios from "axios";
import {ConfiguratorContext} from "../../shared/contexts/ConfiguratorContext";
import Button from "@material-ui/core/Button";
import {FINAL_PRODUCT, INFORMATION} from "../../shared/Global";
import {useParams, useHistory} from "react-router-dom";
import * as Yup from "yup";
import {Formik, Form} from "formik";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        marginLeft: "2rem",
        marginRight: "2rem",
        marginTop: "2rem",
    },
    stepPaddingButton: {
        marginTop: "2rem",
    },
    card: {
        width: "100%"
    },
    button: {
        width: "100%",
    },
    backButton: {
        marginTop: "2rem",
    }
}));

export default function TradeInContainer(props) {
    const classes = useStyles();

    const {dealerModel, modelId, query, leadId} = useParams();
    const [modelData, setModelData] = useState([]);
    const [makeData, setMakeData] = useState([]);
    const [variantData, setVariantData] = useState([]);
    const [financeInstitutionData, setFinanceInstitutionData] = useState([]);
    const [idToGetModel, setIdToGetModel] = useState();
    const [idToGetVariant, setIdToGetVariant] = useState();

    const {enqueueSnackbar} = useSnackbar();

    const history = useHistory();

    useEffect(() => {
        if (FINAL_PRODUCT.price == 0) {
            let temp = `/${dealerModel}/${modelId}/builder`;
            //history.push(temp);
        }
        getMake()
        getInstitutes()

        INFORMATION.query = true
        context.setTransmissionData({})

    }, []);

    const getMake = () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        axios({
            method: 'GET',
            url: `${BASE_URI}/makes`,
            cancelToken: source.token
        }).then((response) => {
            setMakeData(response.data.list);
        }).catch((error) => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get vehicle makes, please refresh", {variant: 'error'});
        })
    }

    const getInstitutes = () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        axios({
            method: 'GET',
            url: `${BASE_URI}/ApplicationLookup/bank`,
            cancelToken: source.token
        }).then((response) => {
            setFinanceInstitutionData(response.data);
        }).catch((error) => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get finance Institutions, please refresh", {variant: 'error'});
        })
    }

    useEffect(() => {
        getModel(idToGetModel)
    }, [idToGetModel]);

    useEffect(() => {
        getVariant(idToGetVariant)
    }, [idToGetVariant]);

    const getModel = (id) => {
        const params = {};
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        if (idToGetModel) {
            params.makeId = id;

            axios({
                method: 'GET',
                url: `${BASE_URI}/models?makeId=${params.makeId}`,
                cancelToken: source.token
            }).then((response) => {
                if (response.data?.list) {
                    setModelData(response.data?.list)
                }
            }).catch(error => {
                if (axios.isCancel(error)) return
                enqueueSnackbar("Unable to get model list, please refresh", {variant: 'error'});
            })
        }
    }

    const getVariant = (id) => {
        const params = {};
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios({
            method: 'GET',
            url: `${BASE_URI}/variants?modelId=${id}`,
            cancelToken: source.token
        }).then((response) => {
            if (response.data?.list) {
                setVariantData(response.data?.list)
            }
        })
    }

    let {...context} = useContext(ConfiguratorContext);

    let yearRegex = `^(19|20)\\d{2}$`;
    const validationSchema = Yup.object({
        make: Yup.string("Select your vehicle make").required(
            "Make is required"
        ),
        model: Yup.string("Select your vehicle model").required(
            "Model is required"
        ),
        year: Yup.string("Enter your vehicle's year")
            .trim()
            .matches(yearRegex, "Year is invalid")
            .required("year is required"),
        mileage: Yup.string("Enter your vehicles' mileage")
            .required("Mileage is required"),
        financeInstitution: Yup.string("Select your Finance Institution")
            .required("Finance Institution is required"),
    });

    const values = {
        make: "",
        makeId: 0,
        model: "",
        modelId: 0,
        variant: "",
        variantId: "",
        year: "",
        mileage: "",
        financeInstitution: "",
        financeInstitutionId: 0,
        estimatedSettlement: 0
    };

    const submitTradeIn = (tradeValues) => {
        let params = {}
        console.log(tradeValues)
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        params.makeId = tradeValues.makeId;
        params.modelId = tradeValues.modelId;
        params.variantId = tradeValues.variantId;
        params.year = tradeValues.year;
        params.mileage = tradeValues.mileage;
        params.financeInstitutionId = tradeValues.financeInstitutionId;
        params.estimatedSettlement = parseInt(tradeValues.estimatedSettlement);
        params.leadId = parseInt(leadId);

        axios({
            method: 'POST',
            url: `${BASE_URI}/TradeApplication`,
            data: params,
            cancelToken: source.token
        }).then((responseSecond) => {
            let temp = `/${dealerModel}/${modelId}/${query}/financeQuestion/${leadId}`;
            history.push(temp);
        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar(`Unable to  complete the trade in application. ${error}`, {variant: 'error'});
        })
    }

    const handleMakeChange = (e) => {
        setModelData([]);
        if (e != null)
            setIdToGetModel(e.id);
    };

    const handleModelChange = (e) => {
        setVariantData([]);
        if (e != null)
            setIdToGetVariant(e.id);
    };

    return (
        <React.Fragment>
            <Grid container direction="row">
                <Grid item xs={12} md={4} lg={12}>
                    <Card className={classes.content} elevation={0}>
                        <CardContent>
                            <Typography align={"center"} variant={"h4"}>
                                Please Tell us more about your vehicle, you wish to trade in.
                                <hr style={{backgroundColor: INFORMATION.buttonColor, height: "2px", border: 0}}/>
                            </Typography>
                            <Formik
                                initialValues={values}
                                isInitialValid={false}
                                onSubmit={async (values, actions) => {
                                   await submitTradeIn(values)
                                }}
                                validationSchema={validationSchema}
                            >
                                {(props) => {
                                    const {
                                        values: {
                                            make,
                                            makeId,
                                            model,
                                            modelIdForm,
                                            variant,
                                            variantId,
                                            year,
                                            mileage,
                                            financeInstitution,
                                            financeInstitutionId,
                                            estimatedSettlement
                                        },
                                        touched,
                                        errors,
                                        handleChange,
                                        isValid,
                                        setFieldTouched,
                                        setFieldValue,
                                    } = props;
                                    return (
                                        <Form>
                                            <Grid container className={classes.root} direction="row">
                                                <Grid item className={classes.stepPadding} xs={12} md={5} lg={3}>
                                                    <Autocomplete
                                                        id="make"
                                                        name="make"
                                                        value={{
                                                            id: makeId.toString(),
                                                            name: make.toString(),
                                                        }}
                                                        options={makeData}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionSelected={(option, value) => {
                                                            return option.name === value.name;
                                                        }}
                                                        onChange={(event, value) => {
                                                            setFieldValue("make", value ? value.name : "");
                                                            setFieldValue("makeId", value ? value.id : "");
                                                            handleMakeChange(value);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                error={
                                                                    touched.make &&
                                                                    Boolean(errors.make)
                                                                }
                                                                helperText={
                                                                    touched.make ? errors.make : ""
                                                                }
                                                                variant="outlined"
                                                                label="Select Your Vehicle Make"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5} lg={3}>
                                                    <Autocomplete
                                                        id="model"
                                                        name="model"
                                                        value={{
                                                            id: modelId.toString(),
                                                            title: model.toString(),
                                                        }}
                                                        options={modelData}
                                                        disabled={modelData.length <= 0}
                                                        getOptionLabel={(option) => option.title}
                                                        getOptionSelected={(option, value) => {
                                                            return option.title === value.title;
                                                        }}
                                                        onChange={(event, value) => {
                                                            setFieldValue("model", value ? value.title : "");
                                                            setFieldValue("modelId", value ? value.id : "");

                                                            handleModelChange(value);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                error={
                                                                    touched.model &&
                                                                    Boolean(errors.model)
                                                                }
                                                                helperText={
                                                                    touched.model ? errors.model : ""
                                                                }
                                                                variant="outlined"
                                                                label="Select Your Vehicle Model"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5} lg={3}>
                                                    <Autocomplete
                                                        id="variant"
                                                        name="variant"
                                                        value={{
                                                            id: variantId.toString(),
                                                            variantName: variant.toString(),
                                                        }}
                                                        options={variantData}
                                                        disabled={variantData.length <= 0}
                                                        getOptionLabel={(option) => option.variantName}
                                                        getOptionSelected={(option, value) => {
                                                            return option.variantName === value.variantName;
                                                        }}
                                                        onChange={(event, value) => {
                                                            handleChange(event);
                                                            setFieldValue("variant", value ? value.variantName : "");
                                                            setFieldValue("variantId", value ? value.id : "");
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                error={
                                                                    touched.variant &&
                                                                    Boolean(errors.variant)
                                                                }
                                                                helperText={
                                                                    touched.variant ? errors.variant : ""
                                                                }
                                                                variant="outlined"
                                                                label="Select Your Vehicle variant"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5} lg={3}>
                                                    <TextField
                                                        id="year"
                                                        name="year"
                                                        label="Year"
                                                        fullWidth
                                                        value={year}
                                                        variant="outlined"
                                                        helperText={touched.year ? errors.year : ""}
                                                        error={touched.year && Boolean(errors.year)}
                                                        onChange={(event, value) => {
                                                            handleChange(event);
                                                            setFieldTouched("year", true, false);
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item className={classes.stepPadding} xs={12} md={5} lg={3}>
                                                    <TextField
                                                        id="mileage"
                                                        name="mileage"
                                                        label="Mileage"
                                                        fullWidth
                                                        value={mileage}
                                                        variant="outlined"
                                                        helperText={touched.mileage ? errors.mileage : ""}
                                                        error={touched.mileage && Boolean(errors.mileage)}
                                                        onChange={(event, value) => {
                                                            handleChange(event);
                                                            setFieldTouched("mileage", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5} lg={5}>
                                                    <Autocomplete
                                                        id="financeInstitution"
                                                        name="financeInstitution"
                                                        value={{
                                                            id: financeInstitutionId,
                                                            name: financeInstitution.toString(),
                                                        }}
                                                        options={financeInstitutionData}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionSelected={(option, value) => {
                                                            return option.name === value.name;
                                                        }}
                                                        onChange={(event, value) => {
                                                            handleChange(event);
                                                            setFieldValue("financeInstitution", value ? value.name : "");
                                                            setFieldValue("financeInstitutionId", value ? value.id : "");
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                error={
                                                                    touched.financeInstitution &&
                                                                    Boolean(errors.financeInstitution)
                                                                }
                                                                helperText={
                                                                    touched.financeInstitution ? errors.financeInstitution : ""
                                                                }
                                                                variant="outlined"
                                                                label="Select Your Finance Institution"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5} lg={3}>
                                                    <TextField
                                                        id="estimatedSettlement"
                                                        name="estimatedSettlement"
                                                        type="number"
                                                        label="Estimated settlement of your trade in"
                                                        fullWidth
                                                        value={estimatedSettlement}
                                                        variant="outlined"
                                                        helperText={touched.estimatedSettlement ? errors.estimatedSettlement : ""}
                                                        error={touched.estimatedSettlement && Boolean(errors.estimatedSettlement)}
                                                        onChange={(event, value) => {
                                                            handleChange(event);
                                                            setFieldTouched("estimatedSettlement", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                
                                                <Grid container direction="row" justify={"center"}>
                                                    <Grid item className={classes.stepPaddingButton} xs={12} md={4}
                                                          lg={5}>
                                                        <Grid item className={classes.stepPaddingButton} xs={12} md={4}
                                                              lg={12}>
                                                            <Button className={classes.button} variant={"contained"}
                                                                    color="primary" type="submit"
                                                                    disabled={!isValid}
                                                            >
                                                                Continue
                                                            </Button>
                                                        </Grid>
                                                        <Grid item className={classes.stepPaddingButton} xs={12} md={4}
                                                              lg={12}>
                                                            <Button variant={"contained"} color="primary"
                                                                    className={classes.button}
                                                                    onClick={() => {
                                                                        let temp = `/${dealerModel}/${modelId}/${query}/tradeInQuestion/${leadId}`;
                                                                        INFORMATION.query = false
                                                                        context.setTransmissionData({})
                                                                        history.push(temp);
                                                                    }}>
                                                                Back
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
