export const BASE_URI ="https://c2-marketdealer-leadcentre.azurewebsites.net/api";
export const LOOKUP_URI="applicationlookup";
export const PHONE_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const IMAGE_UPLOADER_CONFIG = {
  maxWidth: 1024,
  maxHeight: 1024,
  compressFormat: 'JPEG',
  quality: 500,
  rotation: 0,
  outputType: 'base64'
};