import React, {useState, useEffect, useRef} from 'react';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import {Grid} from '@material-ui/core';
import { Formik, Form, Field } from "formik";
import {useSnackbar} from 'notistack'
import { useParams, useHistory } from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DocumentUploadInput from '../../shared/DocumentUploadInput'
import ImageService from '../../shared/ImageService'
import FormActions from '../../shared/FormActions'
import * as Yup from "yup";
import axios from 'axios'
import { BASE_URI } from '../../shared/Constants'


export default function DocumentUploadForm() {
  // const classes = useStyles();
  const theme = useTheme();
  // /:dealerModel/:modelId/:query/documents/:leadId
  // /:dealerModel/:modelId/:query/cashDone
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const initialValues = {
    id: '',
    proofOfAddress: '',
    drivers: '',
    bankStatement: '',
    payslip: ''
  }
  const [formFields, setFormFields] = useState(initialValues)
  const mountedRef = useRef(true)
  const { dealerModel, modelId, leadId } = useParams();
  const history = useHistory();
  const isAddMode = !leadId;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    enqueueSnackbar("Your Application was submitted!", {variant: 'success'});
  }, [])



  function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
    setStatus();
    console.log("Documents")
      console.log(fields)

      uploadDocuments(fields);

      

    if (isAddMode) {

    } else {

    }
    }

    async function uploadDocuments(fields)
    {
        var promises = [];

        Object.keys(fields).map(async function (key, index) {
            var base64Image = (fields[key] instanceof File) ? await ImageService.getBase64(fields[key]) : fields[key];

            base64Image = ImageService.parseBase64(base64Image);

            var promise = axios.post(`${BASE_URI}/ApplicationDocument`,
                {
                    DocumentType: key,
                    LeadId: parseInt(leadId),
                    Base64Image: base64Image,
                    FileName: (fields[key] instanceof File) ? fields[key].name : ''
                }
            );

            promises.push(promise);
        });

        Promise.all(promises).then(() => {
            enqueueSnackbar("Documents uploaded successfully!", { variant: 'success' });
        })
    }

  return (
        <Formik
          initialValues={formFields}
          enableReinitialize={true}
          onSubmit={onSubmit}
          validationSchema={Yup.object().shape({
            id: Yup.string().required('ID document is required'),
            proofOfAddress: Yup.string().required('Proof of Address is required'),
            drivers: Yup.string().required('Drivers License is required'),
            bankStatement: Yup.string().required('Bank Statement is required'),
            payslip: Yup.string().required('Payslip is required'),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              handleChange,
              isSubmitting,
              handleReset,
              setFieldValue
            } = props
            return (
              <Form>
                <Grid container spacing={4} direction='row'>
                  <Grid item container xs={12} justify="center" alignItems="center" direction="column">
                      <Typography variant="h4" style={{marginBottom: '1rem'}}>
                        Congratulations
                      </Typography>
                      <Typography variant="h5" style={{marginBottom: '1rem'}}>
                        Your finance has been approved and you are only minutes away from collecting your new vehicle
                      </Typography>
                      {/* <Typography variant="h4" style={{marginBottom: '1rem'}}>
                        Upload Documents
                      </Typography> */}
                      <Typography variant="h6"
                                  color="textSecondary"
                                  align={matches ? 'inherit': 'center'}
                                  style={{marginBottom: '2rem'}}>
                        {/* Please note you are required to submit all documents before your able to proceed. */}
                        We require the following documents to conclude your purchase
                      </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <DocumentUploadInput title='Copy of ID Document'
                                      required
                                fieldName={'id'}
                                error={errors.id && touched.id}/>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <DocumentUploadInput title='Copy of Driver License'
                                         required
                                         fieldName={'drivers'}
                                         error={errors.drivers && touched.drivers}/>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <DocumentUploadInput title='Payslip'
                                         required
                                         fieldName={'payslip'}
                                         error={errors.payslip && touched.payslip}/>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <DocumentUploadInput title='Copy of Bank Statement'
                                         required
                                         fieldName={'bankStatement'}
                                         error={errors.bankStatement && touched.bankStatement}/>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <DocumentUploadInput title='Proof of Address'
                                         required
                                         fieldName={'proofOfAddress'}
                                         error={errors.proofOfAddress && touched.proofOfAddress}/>
                  </Grid>

                </Grid>

                <FormActions reset={() => handleReset(initialValues)} />
              </Form>
            );
          }}
        </Formik>
  );
}
