import React, {useState, useContext, useEffect, useRef} from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {BASE_URI} from "../../shared/Global";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Carousel} from "antd";
import {ConfiguratorContext} from "../../shared/contexts/ConfiguratorContext";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import {BANNER, FINAL_PRODUCT, INFORMATION, SET_BANNER, SET_FINAL_PRODUCT, SET_INFORMATION} from "../../shared/Global";
import {useHistory, useParams} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {useSnackbar} from "notistack";
import { useMediaQuery } from 'react-responsive';
import { CurrencyValue } from "../../shared/TextMask";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& .MuiTextField-root": {
            margin: theme.spacing(2),
            width: "45ch",
        },
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        display: 'flex !important',
        justifyContent: 'space-between',
        flexDirection: 'column',
        margin: '1rem',
        '&:hover': {
            '& $button': {
                opacity: 0.8
            }
        }
    },
    carouselContainer: {
        '& .slick-slide': {
            '& > div': {
                display: 'flex'
            }
        },
        '& .slick-dots': {
            '& li button': {
                height: 10,
                width: 10,
                border: '1px solid blue',
                borderRadius: 100
            },
            '& li.slick-active': {
                width: '16px',
            }
        },
    },
    stepPadding: {
        marginLeft: "2rem",
        marginRight: "2rem",
        marginTop: "2rem"
    },
    button: {
        marginBottom: "2rem"
    },
    backButton: {
        marginTop: "1rem",
        marginBottom: "1rem"
    },
    cardImage: {
        width: '100%',
        objectFit: 'cover'
    },
    uppercase: {
        textTransform: 'uppercase'
    }

}));

const tmpDerivativeArr = [
    {
        id: 0,
        imageUpload: 'https://source.unsplash.com/ZRns2R5azu0/500x500',
        derivative: '1.6l Sportage 5dr ABS',
        title: 'New Renault Sportage',
        price: 100000,
        automaticImage: 'https://source.unsplash.com/ZRns2R5azu0/500x500',
        manualImage: 'https://source.unsplash.com/ZRns2R5azu0/500x500',
    },
    {
        id: 1,
        imageUpload: 'https://source.unsplash.com/ZRns2R5azu0/500x500',
        derivative: '1.0l Tiguan 5dr ABS',
        title: 'New Renault Tiguan',
        price: 400000,
        automaticImage: 'https://source.unsplash.com/ZRns2R5azu0/500x500',
        manualImage: 'https://source.unsplash.com/ZRns2R5azu0/500x500',
    },
    {
        id: 2,
        imageUpload: 'https://source.unsplash.com/ZRns2R5azu0/500x500',
        derivative: '2.0l Duster 5dr ABS',
        title: 'New Renault Duster',
        price: 440000,
        automaticImage: 'https://source.unsplash.com/ZRns2R5azu0/500x500',
        manualImage: 'https://source.unsplash.com/ZRns2R5azu0/500x500',
    },
    {
        id: 3,
        imageUpload: 'https://source.unsplash.com/ZRns2R5azu0/500x500',
        derivative: '5.0l Test 5dr ABS',
        title: 'New Renault Test',
        price: 660000,
        automaticImage: 'https://source.unsplash.com/ZRns2R5azu0/500x500',
        manualImage: 'https://source.unsplash.com/ZRns2R5azu0/500x500',
    }
]


export default function DerivativeContainer(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [variants, setVariants] = useState([]);

    const {enqueueSnackbar} = useSnackbar();

    const {dealerModel, modelId} = useParams();
    const history = useHistory();

    let {...context} = useContext(ConfiguratorContext);

    function CarouselArrow(props) {
        const {className, style, onClick, left} = props;
        const iconStyle = {margin: '3rem', height: '4rem', width: '4rem'}
        return (
            <IconButton disableRipple onClick={onClick} style={{color: '#bdbdbd'}} className={className}>
                {
                    left && <ChevronLeftIcon style={iconStyle}/>
                }
                {
                    !left && <ChevronRightIcon style={iconStyle}/>
                }
            </IconButton>
        );
    }

    useEffect(() => {

        setVariants(tmpDerivativeArr)
    }, [])

    const getVariants = () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setLoading(true);
        const params = {
            "dealerModelId": modelId
        }
        axios({
            method: 'GET',
            url: `${BASE_URI}/dealervariants`,
            params,
            cancelToken:  source.token
        }).then((response) => {

            if (response.data?.list) {
                setVariants(response.data?.list)
            }
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get variants list, please refresh", {variant: 'error'});
            setLoading(false)
        })
    }
    useEffect(() => {
        getVariants();
        SET_BANNER("", "",
            `Select your ${FINAL_PRODUCT.title} option`,
            ""
        );
        context.setTransmissionData({})

    }, [])

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    return (
        <div>
            <Button variant={"contained"} color="primary" className={classes.backButton}
                    onClick={() => {
                        INFORMATION.query = false
                        history.push(`/${INFORMATION.dealerId}/range`);
                    }}>
                Back
            </Button>
            {loading === false && variants.length <= 0 && (
                <Card className={classes.root}>
                    <CardActionArea>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                No Variants for this model
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            )}
            {loading == true && variants.length <= 0 && (
                <Card className={classes.root}>
                    <CardActionArea>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                <CircularProgress/>
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            )}
            {(!loading && variants && variants.length > 0) && (
                <React.Fragment>
                    <Typography variant="h5">What is your preferred model?</Typography>
                    <Carousel slidesPerRow={isMobile ? 1 : 3}
                            nextArrow={<CarouselArrow/>}
                            prevArrow={<CarouselArrow left/>}
                            arrows
                            dots={false}
                            className={classes.carouselContainer} effect="fade">
                        {
                            variants.map((variant) => {
                                return (
                                    <Card key={variant.title} className={classes.content} elevation={0}>
                                        <CardActionArea>
                                            <CardMedia className={classes.cardImage}
                                                    component="img"
                                                    alt="Variant"
                                                    image={variant.image}
                                                    title={variant.title}
                                                        height="210"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="h2" align={'center'} className={classes.uppercase}>
                                                    {variant.title}
                                                </Typography>
                                                {/* <Typography variant="body2" color="textSecondary" component="p">
                                                    {variant.variantName}
                                                </Typography> */}
                                                <Typography variant="h6" align={'center'}>
                                                    From <CurrencyValue value={variant.priceRange} />
                                                </Typography>
                                                <Typography>
                                                    <ul>
                                                        <li>Bold structured front grille</li>
                                                        <li>SUV styled headlamps with Silver Streak LED DRLs</li>
                                                        <li>Digital instrument cluster</li>
                                                        <li>Front power windows</li>
                                                        <li>Single DIN Radio with FM/AM tuner, Bluetooth audio streaming and hands-free calls</li>
                                                    </ul>
                                                </Typography>
                                            </CardContent>
                                        <CardActions style={{minHeight: 60}}>
                                            <Grid container direction="row" justify="center" alignItems="center">
                                                <Button className={classes.button} color="primary" variant={"contained"}
                                                        onClick={() => {

                                                            SET_FINAL_PRODUCT(variant.title,
                                                                variant.variantName,
                                                                variant.image,
                                                                variant.priceRange,
                                                                "",
                                                                "",
                                                                "",
                                                                "",
                                                                "",
                                                                "",
                                                                {
                                                                    id: variant.id,
                                                                    modelId: variant.modelId,
                                                                    dealerModelId: variant.dealerModelId
                                                                }
                                                            );

                                                            SET_BANNER(variant.title, variant.variantName,
                                                                "Select your colour option",
                                                                variant.image
                                                            )
                                                            SET_INFORMATION("", "", "", "",
                                                                "", "", "",
                                                                {
                                                                    title: "Manual",
                                                                    image: variant.manualImage,
                                                                    price: variant.manualPrice
                                                                },
                                                                {
                                                                    title: "Automatic",
                                                                    image: variant.automaticImage,
                                                                    price: variant.automaticPrice
                                                                }, variant.id, "");
                                                            props.props("Derivative", "Form Values", "Colour")
                                                        }}>
                                                    Configure
                                                </Button>
                                            </Grid>
                                        </CardActions>
                                        </CardActionArea>
                                    </Card>
                                )
                            })
                        }
                    </Carousel>
                </React.Fragment>
            )}
        </div>
    );
}
