import React, {useState, useContext, useEffect} from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import DerivativeContainer from "./DerivativeContainer";
import {ConfiguratorContext} from "../../shared/contexts/ConfiguratorContext";
import ColourContainer from "./ColourContainer";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Button from "@material-ui/core/Button";
import {useHistory, useParams} from "react-router-dom";
import TransmissionContainer from "./TransmissionContainer";
import AccessoriesContainer from "./AccessoriesContainer";
import YourBrandContainer from "./YourBrandContainer";
import BannerContainer from "./BannerContainer";
import {INFORMATION} from "../../shared/Global";
import {useViewport} from "../../shared/contexts/ViewPortContext";

const useStyles = makeStyles((theme) => ({
    root: {
        background: "#e0e0e0",
        color: "black",
        border: '1px solid rgba(0, 0, 0, 0.05)'
    },
    button: {
        "&.active": {
            background: 'black',
        },
    },
    content: {
        paddingTop: theme.spacing(3),
    },
    toolbar: {
        backgroundColor: '#fff',
        display: 'flex',
        '& > button': {
            flexGrow: 1,
            height: '64px',
            paddingLeft: '27px',
            '&:after': {
                content: '""',
                position: 'absolute',
                display: 'inline-block',
                width: '64px',
                height: '64px',
                top: 0,
                right: '-31.5px',
                backgroundColor: '#fff',
                borderTopRightRadius: '5px',
                transform: 'scale(0.707) rotate(45deg)',
                boxShadow: '1px -1px rgba(0, 0, 0, 0.25)',
                zIndex: 1,
                transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
            },
            '& > span': {
                zIndex: 2
            },
            '& > span > span': {
                display: 'none'
            }
        },
        '& > button:last-child:after': {
            display: 'none'
        },
        '& > button:hover:after': {
            backgroundColor: '#f5f5f5'    
        },
        '& > .MuiButton-textSecondary': {
            backgroundColor: '#fbce32',
            '&:after': {
                backgroundColor: '#fbce32'
            },
            '&:hover:after': {
                backgroundColor: '#fbce32'
            }
        }
    }

}));

export default function BuilderContainer(props) {
    const classes = useStyles();

    const {width} = useViewport();
    const breakpoint = 730;

    let { ...context } = useContext(ConfiguratorContext);

    const history = useHistory();

    const handleNext = (name, values, next) => {
        context.handleNext(name, values, next);
    }

    return (
        <React.Fragment>
            <AppBar position="static" className={classes.root} elevation={0}>
                {width < breakpoint && (
                    <>
                        <Button
                            variant="text"
                            color={"default"}
                            value={"Range"}
                            onClick={(e) => {
                                INFORMATION.query = false
                                history.push(`/${INFORMATION.dealerId}/range`);
                            }}
                            className={classes.button}
                            endIcon={<NavigateNextIcon></NavigateNextIcon>}
                        >
                            Range
                        </Button>
                        {context.buttonElement(context.derivative["name"])}
                        {context.buttonElement(context.colour["name"])}
                        {context.buttonElement(context.transmission["name"])}
                        {context.buttonElement(context.accessories["name"])}
                        {context.buttonElement(context.yourBrand["name"], INFORMATION.name)}
                    </>
                )}

                {width >= breakpoint && (
                    <Toolbar disableGutters className={classes.toolbar}>
                        <Button
                            variant="text"
                            color={"default"}
                            value={"Range"}
                            onClick={(e) => {
                                INFORMATION.query = false
                                history.push(`/${INFORMATION.dealerId}/range`);
                            }}
                            className={classes.button}
                            endIcon={<NavigateNextIcon></NavigateNextIcon>}
                        >
                            Range
                        </Button>
                        {context.buttonElement(context.derivative["name"])}
                        {context.buttonElement(context.colour["name"])}
                        {context.buttonElement(context.transmission["name"])}
                        {context.buttonElement(context.accessories["name"])}
                        {context.buttonElement(context.yourBrand["name"], INFORMATION.name)}
                    </Toolbar>
                )}

            </AppBar>
            <BannerContainer/>

            <div className={classes.content}>
                {context.derivative['isActive'] == true && (
                    <DerivativeContainer props={handleNext}/>
                )}
                {context.colour['isActive'] == true && (
                    <ColourContainer props={handleNext}/>
                )}
                {context.transmission['isActive'] == true && (
                    <TransmissionContainer props={handleNext}/>
                )}
                {context.accessories['isActive'] == true && (
                    <AccessoriesContainer props={handleNext}/>
                )}
                {context.yourBrand['isActive'] == true && (
                    <YourBrandContainer props={handleNext}/>
                )}
            </div>
        </React.Fragment>
    );
}
