import React, { useState, useContext, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Image from 'material-ui-image'
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import { FINAL_PRODUCT, INFORMATION } from "./Global";
import { ConfiguratorContext } from "./contexts/ConfiguratorContext";
import logo from './assets/logo.png';
import mobileLogo from './assets/mobile-logo.svg'
import Container from "@material-ui/core/Container";
import { useMediaQuery as mq } from 'react-responsive'
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    image: {
        maxWidth: 160,
        '@media screen and (max-width: 717px)' : {
            marginTop: '18px'
        }
    },
    imageSingle: {
        height: 50,
        width: "70%",
    },
    grid: {
        paddingTop: 5,
    },
    paddingTitle: {
        paddingTop: 15
    },
    logo: {
        justifyContent: 'flex-end'
    },
    uppercase: {
        textTransform: 'uppercase'
    }
}));

export default function TopBarContainer() {
    const classes = useStyles();

    let { ...context } = useContext(ConfiguratorContext);
    const [information, setInformation] = useState(localStorage.getItem("information") != null ? localStorage.getItem("information") : "{}")
    let tempInformation = JSON.parse(information);

    const isMobile = mq({ query: '(max-width: 717px)' })

    return (
        <div className={classes.root}>
            <AppBar position="sticky" color={"transparent"}>
                <Container maxWidth="lg">
                    <Grid container direction="row" className={classes.grid} justify={"center"}>

                        {INFORMATION.query != true && INFORMATION && (
                            <React.Fragment>
                                <Link href="https://www.renaultdigital.co.za">
                                <Grid item xs={8} md={9} lg={9}>
                                    <Grid container direction="row" style={{ paddingTop: 10 }}>
                                        <Grid item xs={11} md={10} lg={10}>
                                            <Typography variant="h5" align={'left'}>
                                                <span style={{fontWeight: 400}}>Renault</span> Digital showroom
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={11} md={10} lg={10}>
                                            <Typography variant="subtitle2" align={'left'} color="textSecondary" paragraph>
                                                Shop, customize and purchase your vehicle from the comfort of your home.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    </Grid>
                                    </Link>
                                <Grid item xs={4} md={3} lg={3} alignItems={'center'}>
                                    <Grid container direction="row">
                                        <Grid item xs={12} md={12} lg={12} justify={'flex-end'}>
                                            <Toolbar disableGutters classes={{ root: classes.logo }}>
                                                <Link href="https://www.renaultdigital.co.za">
                                                <img src={isMobile ? mobileLogo : logo} className={classes.image} alt="Renault Virtual" />
                                                    {/*<img src={INFORMATION.image != "" ? INFORMATION.image : tempInformation.image } className={classes.image} alt="logo"/>*/}
                                                    </Link>
                                            </Toolbar>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                
                            </React.Fragment>
                        )}
                        {INFORMATION.query == true && (
                            
                            <>
                                fff
                                <Grid item xs={8} md={8} lg={8}>
                                    <Grid container direction="row">
                                        <Grid item xs={1} md={3} lg={3}>
                                        </Grid>
                                        <Grid item xs={9} md={9} lg={9} className={classes.paddingTitle}>
                                            <Typography variant="h6">
                                                {FINAL_PRODUCT.title != "" ? INFORMATION.title : tempInformation.title}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                    <Grid container direction="row">
                                        <Grid item xs={1} md={10} lg={10}>
                                            <Toolbar>
                                                <img src={logo} className={classes.image} alt="Renault Virtual" />
                                                {/*<img src={INFORMATION.image != "" ? INFORMATION.image : tempInformation.image} className={classes.imageSingle} alt="logo"/>*/}
                                            </Toolbar>
                                        </Grid>
                                        <Grid item xs={1} md={2} lg={2}>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Container>
            </AppBar>
        </div>
    );
}
