import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router, Route} from "react-router-dom";
import {ConfiguratorProvider} from "./shared/contexts/ConfiguratorContext";
import {ViewportProvider} from "./shared/contexts/ViewPortContext";

ReactDOM.render(
    <React.StrictMode>
        <ConfiguratorProvider>
            <ViewportProvider>
                <Router>
                    <Route path="/:dealerModel"
                           component={App}
                    />
                </Router>
            </ViewportProvider>
        </ConfiguratorProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
