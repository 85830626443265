import React, {useState, useContext, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {BASE_URI} from "../../shared/Global";
import axios from "axios";
import {ConfiguratorContext} from "../../shared/contexts/ConfiguratorContext";
import Button from "@material-ui/core/Button";
import CardActionArea from "@material-ui/core/CardActionArea";
import {FINAL_PRODUCT, INFORMATION} from "../../shared/Global";
import {useParams, useHistory} from "react-router-dom";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
        marginTop: "2rem",
    },
    stepPaddingButton: {
        marginLeft: "1rem",
        marginTop: "1rem",
    },
    stepPaddingImage: {
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    card: {
        width: "100%"
    },
    button: {
        width: "100%",
    },
    backButton: {
        marginTop: "2rem",
    }
}));

export default function TradeInQuestionContainer(props) {
    const classes = useStyles();

    const {dealerModel, modelId, query, leadId} = useParams();
    const [queryData, setQueryData] = useState([]);
    const history = useHistory();

    useEffect(() => {
        console.log("INSIDE finance Question")
        if (FINAL_PRODUCT.price == 0) {
            let temp = `/${dealerModel}/${modelId}/builder`;
            history.push(temp);
        }
        INFORMATION.query = true
        context.setTransmissionData({})
    }, []);

    let {...context} = useContext(ConfiguratorContext);

    return (
        <React.Fragment>
            <Grid container direction="row">
                <Grid item xs={12} md={4} lg={7}>
                    <Card className={classes.content} elevation={0}>
                        <CardContent>
                            <Typography align={"center"} variant={"h4"}>
                                Do you want to apply for finance?
                                <hr style={{backgroundColor: INFORMATION.buttonColor, height: "2px", border: 0}}/>
                            </Typography>
                            <Grid container direction="row" justify={"center"}>
                                <Grid item className={classes.stepPaddingButton} xs={12} md={4} lg={6}>
                                    <Button className={classes.button} color="primary" variant={"contained"}
                                            onClick={() => {
                                                let temp = `/${dealerModel}/${modelId}/${query}/pq/${leadId}`;
                                                history.push(temp);
                                            }}>
                                        Yes, I need Finance
                                    </Button>
                                </Grid>

                                <Grid item className={classes.stepPaddingButton} xs={12} md={4} lg={6}>
                                    <Button className={classes.button} color="primary" variant={"contained"}
                                             onClick={() => {
                                                let temp = `/${dealerModel}/${modelId}/${query}/cash/${leadId}`;
                                                history.push(temp);
                                            }}>
                                        No, I do not need Finance
                                    </Button>
                                </Grid>
                                <Grid item className={classes.stepPaddingButton} xs={12} md={4} lg={6}>
                                    <Button variant={"contained"} color="primary" className={classes.button}
                                            onClick={() => {
                                                let temp = `/${dealerModel}/${modelId}/${query}/tradeInQuestion/${leadId}`;
                                                history.push(temp);
                                            }}>
                                        Back
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item className={classes.stepPaddingImage} xs={12} md={4} lg={4}>
                    <Card className={classes.content}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                alt="Features"
                                height="350"
                                image={FINAL_PRODUCT['image']}
                                title={FINAL_PRODUCT['title']}
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
