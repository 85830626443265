import React, {createContext, useState} from 'react';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        background: "#e0e0e0",
        color: "black",
    },
    button: {
        "&.active": {
            background: 'black',
        },
    },
    content: {
        paddingTop: theme.spacing(4),
    }

}));
export const ConfiguratorContext = createContext();

export const ConfiguratorProvider = ({children}) => {
    const classes = useStyles();

    const [derivative, setDerivative] = useState({
        name: "Derivative",
        isActive: true,
        value: {price: 0},
        color: "secondary",
        disabled: false
    });
    const [colour, setColour] = useState({
        name: "Colour",
        isActive: false,
        value: {exterior: 0, interior: 0},
        color: "default",
        disabled: true
    });
    const [transmission, setTransmission] = useState({
        name: "Transmission",
        isActive: false,
        value: {price: 0},
        color: "default",
        disabled: true
    });
    const [accessories, setAccessories] = useState({
        name: "Accessories",
        isActive: false,
        value: {price: 0},
        color: "default",
        disabled: true
    });
    const [yourBrand, setYourBrand] = useState({
        name: "Summary",
        isActive: false,
        value: {price: 0},
        color: "default",
        disabled: true
    });
    const [range, setRange] = useState({ name: "Range", isActive: false, value: "", color: "default", disabled: false });

    const [product, setProduct] = useState({
        price: 0,
        derivativePrice: 0,
        image: "",
        title: "",
        subTitle: "",
        colourExterior: { value: "", price: 0, id: "" },
        colourInterior: { value: "", price: 0, id: "" },
        transmission: { value: "", price: 0, id: "" },
        accessories: { value: "", price: 0, id: "" },
        client: { name: "", email: "", telephone: "" },
        carModelId: "",
        carVariantId: { id: "", modelId: "", dealerModelId: "" }
    });

    const buttonList = {
        "Range": {value: range, setter: setRange},
        "Derivative": {value: derivative, setter: setDerivative},
        "Colour": {value: colour, setter: setColour},
        "Transmission": {value: transmission, setter: setTransmission},
        "Accessories": {value: accessories, setter: setAccessories},
        "Summary": {value: yourBrand, setter: setYourBrand}
    }

    const handleNext = (name, values, nextMenu) => {
        if (values != "") {
            let temp = {...buttonList[name], value: values}
            buttonList[name]['setter'](temp)
        }
        handleMenuClick(nextMenu, false);
    }

    const handleMenuClick = (e, disabled) => {
        resetAllActiveAndColours();
        if (disabled != null) {
            let temp = {...buttonList[e]['value'], isActive: true, color: "secondary", disabled: disabled}
            buttonList[e]['setter'](temp)
        } else {
            let temp = {...buttonList[e]['value'], isActive: true, color: "secondary"}
            buttonList[e]['setter'](temp)
        }
        disableAllInFrontOfCurrent(e);
    }

    const disableAllInFrontOfCurrent = (current) => {
        switch (current) {
            case "Derivative":
                buttonList["Colour"]['setter']({
                    ...colour,
                    isActive: false,
                    color: "default",
                    disabled: true
                });
            case "Colour":
                buttonList["Transmission"]['setter']({
                    ...transmission,
                    isActive: false,
                    color: "default",
                    disabled: true
                });
            case "Transmission":
                buttonList["Accessories"]['setter']({
                    ...accessories,
                    isActive: false,
                    color: "default",
                    disabled: true
                });
            case "Accessories":
                buttonList["Summary"]['setter']({
                    ...yourBrand,
                    isActive: false,
                    color: "default",
                    disabled: true
                })
                break;
            default:
                break;
        }
    }
    const resetAllActiveAndColours = () => {
        buttonList['Accessories']['setter']({...accessories, isActive: false, color: "default"})
        buttonList['Derivative']['setter']({...derivative, isActive: false, color: "default"})
        buttonList['Colour']['setter']({...colour, isActive: false, color: "default"})
        buttonList['Summary']['setter']({...yourBrand, isActive: false, color: "default"})
        buttonList['Transmission']['setter']({...transmission, isActive: false, color: "default"})
        buttonList['Range']['setter']({...range, isActive: false, color: "default"})
    }

    const buttonElement = (name, display) => {
        return (<Button
            variant="text"
            color={buttonList[name]['value']['color']}
            disabled={buttonList[name]['value']['disabled']}
            value={name}
            onClick={(e) => {
                handleMenuClick(e.currentTarget.value)
            }}
            className={classes.button}
            endIcon={<NavigateNextIcon></NavigateNextIcon>}
        >
            {display != null ? `Your ${display}` : name}
        </Button>);
    }

    const setTransmissionData = (i) => {
        setTransmission({...transmission, value: i})
    }

    return (
        <ConfiguratorContext.Provider value={{
            handleNext, buttonElement, handleMenuClick, buttonList, range, setRange, derivative,
            colour, transmission, accessories, yourBrand,
            setTransmissionData, resetAllActiveAndColours
            , product, setProduct
        }}>
            {children}
        </ConfiguratorContext.Provider>
    )
};
