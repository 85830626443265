import React, { useState, useContext, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { BASE_URI } from "../../shared/Global";
import axios from "axios";
import { ConfiguratorContext } from "../../shared/contexts/ConfiguratorContext";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import CardActionArea from "@material-ui/core/CardActionArea";
import { FINAL_PRODUCT, LEAD } from "../../shared/Global";
import CardHeader from "@material-ui/core/CardHeader";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import { useParams, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { CircularProgress } from "material-ui";
import { useSnackbar } from "notistack";
import { CurrencyValue } from "../../shared/TextMask";

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        marginLeft: "2rem",
        marginRight: "2rem",
        marginTop: "2rem",
    },
    stepPaddingButton: {
        marginLeft: "0.5rem",
        marginTop: "0.5rem",
    },
    stepPaddingImage: {
        marginLeft: "1rem",
        marginRight: "2rem",
    },
    left: {
        float: "left"
    },
    right: {
        float: "right"
    },
    card: {
        width: "100%"
    },
    button: {
        width: "100%",
    },
    backButton: {
        marginTop: "2rem",
    },
    image: {
        width: "100%",
        height: "auto",
    },
    uppercase: {
        textTransform: 'uppercase'
    },
    header: {
        borderBottom: '1px solid #dedede',
        marginBottom: '40px',
        paddingBottom: '24px'
    },
    table: {
        '& tr > th:last-child': {
            fontWeight: 700
        },
        '& tr > td:last-child': {
            fontWeight: 700
        }
    }

}));

export default function YourBrandContainer() {
    const classes = useStyles();
    const { dealerModel, modelId } = useParams();
    const history = useHistory();
    const [loader, setLoader] = useState(false)
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        console.log(LEAD);
        if (FINAL_PRODUCT.price == 0) {
            let temp = `/${dealerModel}/${modelId}/builder`;
            history.push(temp);
        }
    }, []);

    let { ...context } = useContext(ConfiguratorContext);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            // '&:nth-of-type(odd)': {
            //     backgroundColor: theme.palette.action.hover,
            // },
        },
    }))(TableRow);
    const StyledTableRowLast = withStyles((theme) => ({
        root: {
            // '&:nth-of-type(odd)': {
            //     backgroundColor: "lightgrey",
            // },
        },
    }))(TableRow);
    const StyledTableCell = withStyles((theme) => ({
        head: {
            // backgroundColor: theme.palette.common.black,
            // color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const createVehicleLead = () => {

        setLoader(true);

        let vehicleParam = {}
        vehicleParam.LeadId = parseInt(LEAD.id)
        vehicleParam.ModelId = FINAL_PRODUCT.carVariantId.modelId
        vehicleParam.OwnedModelId = FINAL_PRODUCT.carVariantId.dealerModelId
        vehicleParam.OwnedVariantId = FINAL_PRODUCT.carVariantId.id
        vehicleParam.AccessoryId = FINAL_PRODUCT.accessories.id != "" ? FINAL_PRODUCT.accessories.id : 0
        vehicleParam.OwnedVariantExteriorsId = FINAL_PRODUCT.colourExterior.id
        vehicleParam.OwnedVariantInteriorsId = FINAL_PRODUCT.colourInterior.id
        vehicleParam.Transmission = FINAL_PRODUCT.transmission.id;
        vehicleParam.price = FINAL_PRODUCT.price;

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios({
            method: 'POST',
            url: `${BASE_URI}/leadVehicles`,
            data: vehicleParam,
            cancelToken: source.token
        }).then((responseSecond) => {
            let temp = `/${dealerModel}/0/buy/finance/0/${LEAD.id}`;
            history.push(temp);

        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get complete the request", { variant: 'error' });
            setLoader(false);
        })
    }

    return (
        <React.Fragment>
            <Grid container direction="row">
                <Grid item xs={12} className={classes.header}>
                    <Typography variant="h5" component="h2" align={'center'}>
                        Total price <CurrencyValue value={FINAL_PRODUCT['price']} />
                    </Typography>
                    <Typography variant="h6" align={'center'} className={classes.uppercase} style={{ fontWeight: 400, fontSize: '1rem' }}>
                        <span>{FINAL_PRODUCT['title']} {FINAL_PRODUCT['subTitle']}</span>
                    </Typography>

                </Grid>

                <Grid item className={classes.stepPaddingImage} xs={12} md={6} lg={6}>
                    <Card className={classes.content} elevation={0}>
                        <CardActionArea>
                            <CardMedia className={classes.image}
                                component="img"
                                alt="Features"
                                image={FINAL_PRODUCT['image']}
                                title={FINAL_PRODUCT['title']}
                            />
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item xs={12} md={5} lg={5}>
                    <Grid container direction="row">
                        <Card elevation={0} className={classes.card}>
                            <CardActionArea>
                                <CardHeader
                                    // title={FINAL_PRODUCT['title']}
                                    // subheader={FINAL_PRODUCT['subTitle']}
                                    title={'Summary'}
                                />
                                <CardContent>
                                    <TableContainer component={Paper} elevation={0}>
                                        <Table className={classes.table} aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>Purchase Price:</StyledTableCell>
                                                    <StyledTableCell
                                                        align="right">R {FINAL_PRODUCT['derivativePrice']}</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {FINAL_PRODUCT.colourExterior['value'] != "" && (
                                                    <StyledTableRow>
                                                        <StyledTableCell>{FINAL_PRODUCT.colourExterior['value']}</StyledTableCell>
                                                        <StyledTableCell
                                                            align="right">R {FINAL_PRODUCT.colourExterior['price']}</StyledTableCell>
                                                    </StyledTableRow>)}
                                                {FINAL_PRODUCT.colourInterior['value'] != "" && (
                                                    <StyledTableRow>
                                                        <StyledTableCell>{FINAL_PRODUCT.colourInterior['value']}</StyledTableCell>
                                                        <StyledTableCell
                                                            align="right">R {FINAL_PRODUCT.colourInterior['price']}</StyledTableCell>
                                                    </StyledTableRow>)}
                                                {FINAL_PRODUCT.transmission['value'] != "" && (
                                                    <StyledTableRow>
                                                        <StyledTableCell>{FINAL_PRODUCT.transmission['value']}</StyledTableCell>
                                                        <StyledTableCell
                                                            align="right">R {FINAL_PRODUCT.transmission['price']}</StyledTableCell>
                                                    </StyledTableRow>)}
                                                {FINAL_PRODUCT.accessories['value'] != "" && (
                                                    <StyledTableRow>
                                                        <StyledTableCell>{FINAL_PRODUCT.accessories['value']}</StyledTableCell>
                                                        <StyledTableCell
                                                            align="right">R {FINAL_PRODUCT.accessories['price']}</StyledTableCell>
                                                    </StyledTableRow>)}
                                                <StyledTableRowLast>
                                                    <StyledTableCell>Total:</StyledTableCell>
                                                    <StyledTableCell
                                                        align={"right"}>R {FINAL_PRODUCT['price']}</StyledTableCell>
                                                </StyledTableRowLast>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                                <CardActions>
                                    <Grid container direction="row">
                                        <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>

                                            {!loader &&
                                                <Button className={classes.button} color="primary" variant={"contained"}
                                                    onClick={createVehicleLead}>
                                                    Checkout
                                            </Button>
                                            }

                                            {loader == true && (
                                                <Button className={classes.button} variant={"contained"}
                                                    color="primary"
                                                    disabled={true}
                                                >
                                                    <CircularProgress />
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
