import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'antd/dist/antd.css';
import { Carousel } from 'antd';
import Image from 'material-ui-image'


const useStyles = makeStyles({
    carouselContainer: {
        '& .slick-slide': {
            textAlign: 'center',
            height: '200px',
            overflow: 'hidden'
        },
        '& .slick-dots': {
            '& li button': {
                height: 10,
                width: 10,
                border:'1px solid blue',
                borderRadius: 100
            },
            '& li.slick-active': {
                width: '16px',
                '& button': {
                    background: '#ee6d23'
                }
            }
        }
    }
});

export default function ImageCarousel({imgArray, vehicle, open}) {
    const classes = useStyles();


    // onClick={()=>open(vehicle.id)}
    return (
        <Carousel dots dotPosition="top" className={classes.carouselContainer} effect="fade">
            {
                imgArray.map((img, index) => {
                    return (
                        <Image src={img.img ? img.img: img.src}
                               imageStyle={{
                                   height: 'initial',
                                   bottom: 0,
                                   margin: 'auto',
                                   cursor: 'pointer'
                               }}
                               key={index} aspectRatio={(16/9)} color='lightgrey'/>
                    )
                })
            }
        </Carousel>
    )
}