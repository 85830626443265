import React, {useState, useContext, useEffect} from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {BASE_URI} from "../../shared/Global";
import axios from "axios";
import {ConfiguratorContext} from "../../shared/contexts/ConfiguratorContext";
import Button from "@material-ui/core/Button";
import {FINAL_PRODUCT, INFORMATION} from "../../shared/Global";
import {useParams, useHistory} from "react-router-dom";
import * as Yup from "yup";
import {Formik, Form} from "formik";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useSnackbar} from 'notistack'

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        marginLeft: "1rem",
        marginRight: "1rem",
        marginTop: "1rem",
    },
    stepPaddingButton: {
        marginTop: "2rem",
    },
    stepPaddingTitle: {
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    card: {
        width: "100%"
    },
    button: {
        width: "100%",
    },
    backButton: {
        marginTop: "2rem",
    }
}));

export default function CashApplicationContainer(props) {
    const classes = useStyles();

    const {enqueueSnackbar} = useSnackbar();

    const {dealerModel, modelId, query, leadId} = useParams();
    const [contactData, setContactData] = useState([{id: 1, name: "Contact data"}]);
    const [titleData, setTitleData] = useState([{id: 1, name: "Mr"}]);
    const [provinceData, setProvinceData] = useState([{id: 1, name: "Gauteng"}])
    const [genderData, setGenderData] = useState([{id: 1, name: "Male"}]);
    const [cityData, setCityData] = useState([]);

    const history = useHistory();

    useEffect(() => {
        if (FINAL_PRODUCT.price == 0) {
            let temp = `/${dealerModel}/${modelId}/builder`;
            //history.push(temp);
        }

        getGender();
        getRegions();
        getTitle();
        getContactMethod()

        INFORMATION.query = true
        context.setTransmissionData({})
    }, []);

    const getGender = () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        axios({
            method: 'GET',
            url: `${BASE_URI}/ApplicationLookup/gender`,
            cancelToken: source.token
        }).then((response) => {
            setGenderData(response.data)
        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get the gender list, please refresh!", {variant: 'error'});
        })
    }

    const getCities = (regionId) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        axios({
            method: 'GET',
            url: `${BASE_URI}/ApplicationLookup/city/${regionId}`,
            cancelToken: source.token
        }).then((response) => {
            console.log(response.data)
            setCityData(response.data)
        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get the city list, please refresh!", {variant: 'error'});
        })
    }

    const getTitle = () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        axios({
            method: 'GET',
            url: `${BASE_URI}/ApplicationLookup/title`,
            cancelToken: source.token
        }).then((response) => {
            setTitleData(response.data)
        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get the title list, please refresh!", {variant: 'error'});
        })
    }
    const getContactMethod = () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        axios({
            method: 'GET',
            url: `${BASE_URI}/ApplicationLookup/contact`,
            cancelToken: source.token
        }).then((response) => {
            setContactData(response.data)
        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get the contact list, please refresh!", {variant: 'error'});
        })
    }

    const getRegions = () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        axios({
            method: 'GET',
            url: `${BASE_URI}/ApplicationLookup/region`,
            cancelToken: source.token
        }).then((response) => {
            setProvinceData(response.data)
        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get the region list, please refresh!", {variant: 'error'});
        })
    }
    const submitCashApplication = (values) => {
        let params = {}
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        params.title = values.titleId;
        params.firstNames = values.firstNames;
        params.surname = values.surname;
        params.initials = values.initials;
        params.email = values.email;
        params.cellCode = values.cellCode + '';
        params.mobile = values.mobile + '';
        params.homeCode = values.homeCode + '';
        params.home = values.home + '';
        params.genderId = values.genderId;
        params.contactMethodId = values.contactMethodId;
        params.unitNumber = values.unitNumber;
        params.unitName = values.unitName;
        params.streetNumber = values.streetNumber;
        params.streetName = values.streetName;
        params.suburb = values.suburb;
        params.city = values.city;
        params.provinceId = values.provinceId;
        params.addressCode = values.addressCode;
        params.idNumber = values.idNumber;
        params.leadId = parseInt(leadId);

        axios({
            method: 'POST',
            url: `${BASE_URI}/CashApplication`,
            data: params,
            cancelToken: source.token
        }).then((responseSecond) => {
            let temp = `/${dealerModel}/${modelId}/${query}/cashDone/${leadId}`;
            history.push(temp);
        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar(`Unable to complete the application. ${error}`, {variant: 'error'});
        })
    }

    let {...context} = useContext(ConfiguratorContext);

    let idRegex = `(((\\d{2}((0[13578]|1[02])(0[1-9]|[12]\\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\\d|30)|02(0[1-9]|1\\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\\d{4})( |-)(\\d{3})|(\\d{7}))`

    const validationSchema = Yup.object({
        title: Yup.string("Select a title").required(
            "Title is required"
        ),
        firstNames: Yup.string("Enter your first names").required(
            "First Names are required"
        ),
        email: Yup.string("Enter your email")
            .email("Enter a valid email")
            .required("Email is required"),
        surname: Yup.string("Enter your surname")
            .required("Surname is required"),
        initials: Yup.string("Enter your Initials")
            .required("Initials are required"),
        idNumber: Yup.string("Enter your SA Id Number")
            .required("ID number is required")
            .matches(idRegex, "ID number is not valid"),
        cellCode: Yup.string("Enter your Cell code")
            .required("Cell code is required"),
        mobile: Yup.string("Enter your mobile number")
            .required("Mobile number is required"),
        homeCode: Yup.string("Enter your Home Code"),
        home: Yup.string("Enter your Home number"),
        gender: Yup.string("Select your gender")
            .required("Gender is required"),
        contactMethod: Yup.string("Select your preferred contact method")
            .required("Contact method is required"),
        unitNumber: Yup.string("Enter your Unit number")
            .required("Unit number is required"),
        unitName: Yup.string("Enter your Unit name")
            .required("Unit name is required"),
        streetNumber: Yup.string("Enter your Street number")
            .required("Street number is required"),
        streetName: Yup.string("Enter your Street Name")
            .required("Street Name is required"),
        suburb: Yup.string("Enter your Suburb")
            .required("Suburb is required"),
        city: Yup.string("Enter your City")
            .required("City is required"),
        province: Yup.string("Select your province")
            .required("Province is required"),
        addressCode: Yup.string("Enter your Address code")
            .required("Address Code is required"),
    });

    const values = {
        title: "Select",
        titleId: 0,
        firstNames: "",
        surname: "",
        initials: "",
        email: "",
        cellCode: "",
        mobile: "",
        homeCode: "",
        home: "",
        gender: "Select",
        genderId: 0,
        contactMethod: "Select",
        contactMethodId: 0,
        unitNumber: "",
        unitName: "",
        streetNumber: "",
        streetName: "",
        suburb: "",
        city: "",
        cityId: "",
        province: "Select",
        provinceId: 0,
        addressCode: "",
        idNumber: "",
    };

    return (
        <React.Fragment>
            <Card className={classes.content} elevation={0}>
                <CardContent>
                    <Typography align={"center"} variant={"h4"}>
                        Cash Application
                        <hr style={{backgroundColor: INFORMATION.buttonColor, height: "2px", border: 0}}/>
                    </Typography>
                    <Typography align={"center"} className={classes.stepPaddingTitle} variant={"subtitle1"}>
                        We need the following information to generate your Offer to Purchase:
                    </Typography>
                    <Formik
                        initialValues={values}
                        isInitialValid={false}
                        onSubmit={async (values, actions) => {
                            await submitCashApplication(values)
                        }}
                        validationSchema={validationSchema}
                    >
                        {(props) => {
                            const {
                                values: {
                                    title,
                                    titleId,
                                    firstNames,
                                    surname,
                                    initials,
                                    email,
                                    cellCode,
                                    mobile,
                                    homeCode,
                                    home,
                                    gender,
                                    genderId,
                                    contactMethod,
                                    contactMethodId,
                                    unitNumber,
                                    unitName,
                                    streetNumber,
                                    streetName,
                                    suburb,
                                    city,
                                    cityId,
                                    province,
                                    provinceId,
                                    addressCode,
                                    idNumber,
                                },
                                touched,
                                errors,
                                handleChange,
                                isSubmitting,
                                isValid,
                                submitForm,
                                setFieldTouched,
                                setFieldValue,
                                resetForm,
                            } = props;
                            return (
                                <Form>
                                    <Grid container className={classes.root} direction={"row"}>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <Grid container className={classes.root} direction={"row"}>
                                                <Grid item className={classes.stepPadding} xs={12} md={12}
                                                      lg={12}>
                                                    <Typography align={"center"} variant={"h6"}>
                                                        Personal Information
                                                    </Typography>
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5}
                                                      lg={4}>
                                                    <Autocomplete
                                                        id="title"
                                                        name="title"
                                                        value={{
                                                            id: titleId.toString(),
                                                            name: title.toString(),
                                                        }}
                                                        options={titleData}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionSelected={(option, value) => {
                                                            return option.name === value.name;
                                                        }}
                                                        onChange={(event, value) => {
                                                            setFieldValue("title", value ? value.name : "");
                                                            setFieldValue("titleId", value ? value.id : "");
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                error={
                                                                    touched.title && Boolean(errors.title)
                                                                }
                                                                helperText={
                                                                    touched.title ? errors.title : ""
                                                                }
                                                                variant="outlined"
                                                                label="Select Your title"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5}
                                                      lg={6}>
                                                    <TextField
                                                        id="initials"
                                                        name="initials"
                                                        label="Initials"
                                                        fullWidth
                                                        value={initials}
                                                        variant="outlined"
                                                        helperText={touched.initials ? errors.initials : ""}
                                                        error={touched.initials && Boolean(errors.initials)}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            setFieldTouched("initials", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5}
                                                      lg={5}>
                                                    <TextField
                                                        id="firstNames"
                                                        name="firstNames"
                                                        label="First names"
                                                        fullWidth
                                                        value={firstNames}
                                                        variant="outlined"
                                                        helperText={touched.firstNames ? errors.firstNames : ""}
                                                        error={touched.firstNames && Boolean(errors.firstNames)}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            setFieldTouched("firstNames", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5}
                                                      lg={5}>
                                                    <TextField
                                                        id="surname"
                                                        name="surname"
                                                        label="Surname"
                                                        fullWidth
                                                        value={surname}
                                                        variant="outlined"
                                                        helperText={touched.surname ? errors.surname : ""}
                                                        error={touched.surname && Boolean(errors.surname)}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            setFieldTouched("surname", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5}
                                                      lg={6}>
                                                    <TextField
                                                        id="email"
                                                        name="email"
                                                        label="Email Address"
                                                        fullWidth
                                                        value={email}
                                                        variant="outlined"
                                                        helperText={touched.email ? errors.email : ""}
                                                        error={touched.email && Boolean(errors.email)}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            setFieldTouched("email", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5}
                                                      lg={4}>
                                                    <TextField
                                                        id="idNumber"
                                                        name="idNumber"
                                                        label="SA ID Number"
                                                        fullWidth
                                                        value={idNumber}
                                                        variant="outlined"
                                                        helperText={touched.idNumber ? errors.idNumber : ""}
                                                        error={touched.idNumber && Boolean(errors.idNumber)}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            setFieldTouched("idNumber", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5}
                                                      lg={3}>
                                                    <TextField
                                                        id="cellCode"
                                                        name="cellCode"
                                                        label="Cell Code"
                                                        type={"number"}
                                                        fullWidth
                                                        value={cellCode}
                                                        variant="outlined"
                                                        helperText={touched.cellCode ? errors.cellCode : ""}
                                                        error={touched.cellCode && Boolean(errors.cellCode)}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            setFieldTouched("cellCode", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5}
                                                      lg={5}>
                                                    <TextField
                                                        id="mobile"
                                                        name="mobile"
                                                        label="Mobile"
                                                        type={"number"}
                                                        fullWidth
                                                        value={mobile}
                                                        variant="outlined"
                                                        helperText={touched.mobile ? errors.mobile : ""}
                                                        error={touched.mobile && Boolean(errors.mobile)}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            setFieldTouched("mobile", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5}
                                                      lg={3}>
                                                    <TextField
                                                        id="homeCode"
                                                        name="homeCode"
                                                        label="Home Code"
                                                        type={"number"}
                                                        fullWidth
                                                        value={homeCode}
                                                        variant="outlined"
                                                        helperText={touched.homeCode ? errors.homeCode : ""}
                                                        error={touched.homeCode && Boolean(errors.homeCode)}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            setFieldTouched("homeCode", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5}
                                                      lg={5}>
                                                    <TextField
                                                        id="home"
                                                        name="home"
                                                        label="Home Phone Number"
                                                        type={"number"}
                                                        fullWidth
                                                        value={home}
                                                        variant="outlined"
                                                        helperText={touched.home ? errors.home : ""}
                                                        error={touched.home && Boolean(errors.home)}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            setFieldTouched("home", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5}
                                                      lg={4}>
                                                    <Autocomplete
                                                        id="gender"
                                                        name="gender"
                                                        value={{
                                                            id: genderId.toString(),
                                                            name: gender.toString(),
                                                        }}
                                                        options={genderData}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionSelected={(option, value) => {
                                                            return option.name === value.name;
                                                        }}
                                                        onChange={(event, value) => {
                                                            setFieldValue("gender", value ? value.name : "");
                                                            setFieldValue("genderId", value ? value.id : "");
                                                            setFieldTouched("gender", true, false);
                                                            setFieldTouched("genderId", true, false);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                error={
                                                                    touched.gender && Boolean(errors.gender)
                                                                }
                                                                helperText={
                                                                    touched.gender ? errors.gender : ""
                                                                }
                                                                variant="outlined"
                                                                label="Select Your gender"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5}
                                                      lg={6}>
                                                    <Autocomplete
                                                        id="contactMethod"
                                                        name="contactMethod"
                                                        value={{
                                                            id: contactMethodId.toString(),
                                                            name: contactMethod.toString(),
                                                        }}
                                                        options={contactData}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionSelected={(option, value) => {
                                                            return option.name === value.name;
                                                        }}
                                                        onChange={(event, value) => {
                                                            handleChange(event);
                                                            setFieldValue("contactMethod", value ? value.name : "");
                                                            setFieldValue("contactMethodId", value ? value.id : "");
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                error={
                                                                    touched.contactMethod &&
                                                                    Boolean(errors.contactMethod)
                                                                }
                                                                helperText={
                                                                    touched.contactMethod ? errors.contactMethod : ""
                                                                }
                                                                variant="outlined"
                                                                label="Select Your preferred contact method"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <Grid container className={classes.root} direction="row">
                                                <Grid item className={classes.stepPadding} xs={12} md={12}
                                                      lg={12}>
                                                    <Typography align={"center"} variant={"h6"}>
                                                        Residential
                                                    </Typography>
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5}
                                                      lg={5}>
                                                    <TextField
                                                        id="unitNumber"
                                                        name="unitNumber"
                                                        label="Unit Number"
                                                        fullWidth
                                                        value={unitNumber}
                                                        variant="outlined"
                                                        helperText={touched.unitNumber ? errors.unitNumber : ""}
                                                        error={touched.unitNumber && Boolean(errors.unitNumber)}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            setFieldTouched("unitNumber", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5}
                                                      lg={5}>
                                                    <TextField
                                                        id="unitName"
                                                        name="unitName"
                                                        label="Unit Name"
                                                        fullWidth
                                                        value={unitName}
                                                        variant="outlined"
                                                        helperText={touched.unitName ? errors.unitName : ""}
                                                        error={touched.unitName && Boolean(errors.unitName)}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            setFieldTouched("unitName", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5}
                                                      lg={5}>
                                                    <TextField
                                                        id="streetNumber"
                                                        name="streetNumber"
                                                        label="Street Number"
                                                        fullWidth
                                                        value={streetNumber}
                                                        variant="outlined"
                                                        helperText={touched.streetNumber ? errors.streetNumber : ""}
                                                        error={touched.streetNumber && Boolean(errors.streetNumber)}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            setFieldTouched("streetNumber", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5}
                                                      lg={5}>
                                                    <TextField
                                                        id="streetName"
                                                        name="streetName"
                                                        label="Street Name"
                                                        fullWidth
                                                        value={streetName}
                                                        variant="outlined"
                                                        helperText={touched.streetName ? errors.streetName : ""}
                                                        error={touched.streetName && Boolean(errors.streetName)}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            setFieldTouched("streetName", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5}
                                                      lg={5}>
                                                    <Autocomplete
                                                        id="province"
                                                        name="province"
                                                        value={{
                                                            id: provinceId.toString(),
                                                            name: province.toString(),
                                                        }}
                                                        options={provinceData}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionSelected={(option, value) => {
                                                            return option.name === value.name;
                                                        }}
                                                        onChange={(event, value) => {
                                                            handleChange(event);
                                                            setFieldValue("province", value ? value.name : "");
                                                            setFieldValue("provinceId", value ? value.id : 0);

                                                            getCities(value.id)
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                error={
                                                                    touched.province &&
                                                                    Boolean(errors.province)
                                                                }
                                                                helperText={
                                                                    touched.province ? errors.province : ""
                                                                }
                                                                variant="outlined"
                                                                label="Select Your Region"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5}
                                                      lg={5}>
                                                    <TextField
                                                        id="city"
                                                        name="city"
                                                        label="City"
                                                        fullWidth
                                                        value={city}
                                                        variant="outlined"
                                                        helperText={touched.city ? errors.city : ""}
                                                        error={touched.city && Boolean(errors.city)}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            setFieldTouched("city", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5}
                                                      lg={5}>
                                                    <TextField
                                                        id="suburb"
                                                        name="suburb"
                                                        label="Suburb"
                                                        fullWidth
                                                        value={suburb}
                                                        variant="outlined"
                                                        helperText={touched.suburb ? errors.suburb : ""}
                                                        error={touched.suburb && Boolean(errors.suburb)}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            setFieldTouched("suburb", true, false);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item className={classes.stepPadding} xs={12} md={5}
                                                      lg={4}>
                                                    <TextField
                                                        id="addressCode"
                                                        name="addressCode"
                                                        label="Address Code"
                                                        fullWidth
                                                        value={addressCode}
                                                        variant="outlined"
                                                        helperText={touched.addressCode ? errors.addressCode : ""}
                                                        error={touched.addressCode && Boolean(errors.addressCode)}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            setFieldTouched("addressCode", true, false);
                                                        }}
                                                    />
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" justify={"center"}>
                                        <Grid item className={classes.stepPaddingButton} xs={12} md={4}
                                              lg={5}>
                                            <Grid item className={classes.stepPaddingButton} xs={12}
                                                  md={4}
                                                  lg={12}>
                                                <Button className={classes.button} variant={"contained"}
                                                        color="primary" type="submit"
                                                        disabled={!isValid}
                                                >
                                                    Done
                                                </Button>
                                            </Grid>
                                            <Grid item className={classes.stepPaddingButton} xs={12}
                                                  md={4}
                                                  lg={12}>
                                                <Button variant={"contained"} color="primary"
                                                        className={classes.button}
                                                        onClick={() => {
                                                            let temp = `/${dealerModel}/${modelId}/${query}/financeQuestion/${leadId}`;
                                                            history.push(temp);
                                                        }}>
                                                    Back
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </CardContent>
            </Card>
        </React.Fragment>
    );
}
