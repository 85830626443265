export const INFORMATION = {
    title: "",
    subTitle: "",
    image: "",
    name: "",
    queryTitle: "",
    buttonColor: "",
    textColor: "",
    query: false,
    manual: {title: "", image: "", price: 0},
    automatic: {title: "", image: "", price: 0},
    dealerVariantId: "",
    dealerId: ""
};

export const FINAL_PRODUCT = {
    price: 0,
    derivativePrice: 0,
    image: "",
    title: "",
    subTitle: "",
    colourExterior: {value: "", price: 0, id: ""},
    colourInterior: {value: "", price: 0, id: ""},
    transmission: {value: "", price: 0,id:""},
    accessories: {value: "", price: 0, id: ""},
    client: {name: "", email: "", telephone: ""},
    carModelId: "",
    carVariantId: {id: "", modelId: "", dealerModelId: ""}
};

export const BANNER = {
    title: "",
    subTitle: "",
    selectTitle: "",
    image: "",
    price: 0
};

export const LEAD = {
    id: ""
}
export const SET_LEAD = (id) => {
    LEAD.id = (id != "" ? id : LEAD.id);
    localStorage.setItem("lead", JSON.stringify(LEAD));
}
export const BASE_URI = "https://c2-marketdealer-leadcentre.azurewebsites.net/api";

export const SET_FINAL_PRODUCT = (title, subTitle, image, derivativePrice, colourExterior, colourInterior,
                                  transmission, accessories, carId, client, variantId) => {
    FINAL_PRODUCT.derivativePrice = (derivativePrice != "" ? derivativePrice : FINAL_PRODUCT.derivativePrice);
    FINAL_PRODUCT.image = (image != "" ? image : FINAL_PRODUCT.image);
    FINAL_PRODUCT.title = (title != "" ? title : FINAL_PRODUCT.title)
    FINAL_PRODUCT.subTitle = (subTitle != "" ? subTitle : FINAL_PRODUCT.subTitle);
    FINAL_PRODUCT.colourExterior = (colourExterior != "" ? colourExterior : FINAL_PRODUCT.colourExterior);
    FINAL_PRODUCT.colourInterior = (colourInterior != "" ? colourInterior : FINAL_PRODUCT.colourInterior);
    FINAL_PRODUCT.transmission = (transmission != "" ? transmission : FINAL_PRODUCT.transmission);
    FINAL_PRODUCT.accessories = (accessories != "" ? accessories : FINAL_PRODUCT.accessories);
    FINAL_PRODUCT.carModelId = (carId != "" ? carId : FINAL_PRODUCT.carModelId);
    FINAL_PRODUCT.client = (client != "" ? client : FINAL_PRODUCT.client);
    FINAL_PRODUCT.carVariantId = (variantId != "" ? variantId : FINAL_PRODUCT.carVariantId);

    FINAL_PRODUCT.price = +FINAL_PRODUCT.derivativePrice +
        +FINAL_PRODUCT.colourExterior.price +
        +FINAL_PRODUCT.colourInterior.price +
        +FINAL_PRODUCT.transmission.price +
        +FINAL_PRODUCT.accessories.price;

    localStorage.setItem("final_product", JSON.stringify(FINAL_PRODUCT));
}

export const SET_BANNER = (title, subTitle, selectedTitle, image) => {
    BANNER.price = FINAL_PRODUCT.price;
    BANNER.image = (image != "" ? image : BANNER.image);
    BANNER.title = (title != "" ? title : BANNER.title)
    BANNER.subTitle = (subTitle != "" ? subTitle : BANNER.subTitle);
    BANNER.selectTitle = (selectedTitle != "" ? selectedTitle : BANNER.selectTitle);
    localStorage.setItem("banner", JSON.stringify(BANNER));
}

export const SET_INFORMATION = (title, subTitle, image, name, queryTitle,
                                buttonColor, textColor, manual, automatic, dealerVariantId, dealerId) => {
    INFORMATION.image = (image != "" ? image : INFORMATION.image);
    INFORMATION.title = (title != "" ? title : INFORMATION.title)
    INFORMATION.subTitle = (subTitle != "" ? subTitle : INFORMATION.subTitle);
    INFORMATION.name = (name != "" ? name : INFORMATION.name);
    INFORMATION.queryTitle = (queryTitle != "" ? queryTitle : INFORMATION.queryTitle)
    INFORMATION.buttonColor = (buttonColor != "" ? buttonColor : INFORMATION.buttonColor);
    INFORMATION.textColor = (textColor != "" ? textColor : INFORMATION.textColor);
    INFORMATION.manual = (manual != "" ? manual : INFORMATION.manual);
    INFORMATION.automatic = (automatic != "" ? automatic : INFORMATION.automatic);
    INFORMATION.dealerVariantId = (dealerVariantId != "" ? dealerVariantId : INFORMATION.dealerVariantId);
    INFORMATION.dealerId = (dealerId != "" ? dealerId : INFORMATION.dealerId);
    localStorage.setItem("information", JSON.stringify(INFORMATION));

}
