import React, { useState, useContext, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { BASE_URI } from "../../shared/Global";
import axios from "axios";
import { ConfiguratorContext } from "../../shared/contexts/ConfiguratorContext";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CheckIcon from '@material-ui/icons/Check';
import CardMedia from "@material-ui/core/CardMedia";
import { FINAL_PRODUCT, INFORMATION, SET_BANNER, SET_FINAL_PRODUCT } from "../../shared/Global";
import { CurrencyValue } from "../../shared/TextMask";
import { useSnackbar } from 'notistack'
import CardActionArea from "@material-ui/core/CardActionArea";
import { CircularProgress } from "@material-ui/core";
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        marginLeft: "2rem",
        marginRight: "2rem",
        marginTop: "2rem",
        display: 'flex'
    },
    stepPaddingButtons: {
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    backButton: {
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    stepPaddingColours: {
        marginLeft: "1em",
        marginRight: "1em",
        marginTop: "0.5em",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        minWidth: "100%"
    },
    buttonsBelow: {
        marginTop: "2rem",
    },
    cardImage: {
        height: 300,
        width: '50%',
        margin: '0 auto',
        '@media (max-width: 815px)': {
            height: 'auto',
            width: '100%'
        }
    },
    colorsContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    colourName: {
        textTransform: 'uppercase',
        fontSize: '0.9rem',
        marginTop: '0.8rem'
    },
    colourPrice: {
        fontWeight: 700
    }
}));

export default function ColourContainer(props) {
    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    const [checkedExterior, setCheckedExterior] = useState("");
    const [coloursExterior, setColoursExterior] = useState([{
        title: "",
        price: 0,
        image: "",
        colour: "#ffffff"
    }]);
    const [imageExterior, setImageExterior] = useState(0);
    const [exterior, setExterior] = useState({ value: "", price: 0 });

    const [checkedInterior, setCheckedInterior] = useState("");
    const [coloursInterior, setColoursInterior] = useState([{
        title: "",
        price: 0,
        image: "",
        colour: "#ffffff"
    }]);
    const [interior, setInterior] = useState({ value: "", price: 0 });
    const [imageInterior, setImageInterior] = useState(0);

    let { ...context } = useContext(ConfiguratorContext);

    useEffect(() => {
        let params = {};

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        params.dealerVariantId = INFORMATION.dealerVariantId;

        getExteriorColours(source, params)
        getInteriorColours(source, params)

        SET_BANNER("", "",
            "Select your colour option",
            ""
        );
        context.setTransmissionData({})
    }, [])

    const getExteriorColours = (source, params) => {
        axios({
            method: 'GET',
            url: `${BASE_URI}/colours/exteriors`,
            params,
            cancelToken: source.token
        }).then((response) => {
            if (response.data?.list.length > 0) {
                setColoursExterior(response.data.list);
                let indexExterior = response.data.list.findIndex(x => x["title"] === FINAL_PRODUCT.colourExterior.value);
                setExterior({
                    value: response.data.list[0]['title'],
                    price: response.data.list[0]['price']
                })
                if (indexExterior != -1) {
                    setCheckedExterior(response.data.list[indexExterior]['title']);
                    setExterior({
                        value: response.data.list[indexExterior]['title'],
                        price: response.data.list[indexExterior]['price']
                    })
                }

                if (response.data.list.length == 1) {
                    setCheckedExterior(response.data.list[0]['title'])
                    SET_FINAL_PRODUCT("", "", response.data.list[0]["image"], "",
                        {
                            value: response.data.list[0]['title'],
                            price: response.data.list[0]['price'],
                            id: response.data.list[0]['id']
                        }, "", "", "", "", "", "");
                    SET_BANNER("", "", "", response.data.list[0]["image"])
                    setExterior({ value: response.data.list[0]['title'], price: +response.data.list[0]['price'] })
                }
            }
        }).catch((error) => {
            setLoading(false);
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get exterior colours, please refresh", { variant: 'error' });

        })
    }
    const getInteriorColours = (source, params) => {
        axios({
            method: 'GET',
            url: `${BASE_URI}/colours/interiors`,
            params,
            cancelToken: source.token
        }).then((responseInterior) => {
            if (responseInterior.data?.list.length > 0) {
                setColoursInterior(responseInterior.data.list);

                let indexInterior = responseInterior.data.list.findIndex(x => x.title === FINAL_PRODUCT.colourInterior.value);

                if (responseInterior.data.list.length == 1) {
                    setCheckedInterior(responseInterior.data.list[0]['title'])
                    context.setTransmissionData({})
                    SET_FINAL_PRODUCT("", "", "", "",
                        "", {
                        value: responseInterior.data.list[0]['title'],
                        price: responseInterior.data.list[0]['price'],
                        id: responseInterior.data.list[0]['id']
                    },
                        "", "", "", "", "");
                    SET_BANNER("", "", "", "");

                    setInterior({
                        value: responseInterior.data.list[0]['title'],
                        price: +responseInterior.data.list[0]['price']
                    })
                }
                setInterior({
                    value: responseInterior.data.list[0]['title'],
                    price: +responseInterior.data.list[0]['price']
                })
                if (indexInterior != -1) {
                    setInterior({
                        value: responseInterior.data.list[indexInterior]['title'],
                        price: responseInterior.data.list[indexInterior]['price']
                    })
                    setCheckedInterior(responseInterior.data.list[indexInterior]['title']);
                }
            }
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get interior colours, please refresh", { variant: 'error' });
        })
    }
    const invertColor = (hex) => {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
        }
        // invert color components
        let r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
            g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
            b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
        // pad each with zeros and return
        return '#' + padZero(r) + padZero(g) + padZero(b);
    }

    const padZero = (str, len) => {
        len = len || 2;
        let zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }

    const isDisabled = () => {
        return (checkedInterior !== "" && checkedExterior !== "");
    }

    return (
        <React.Fragment>
            <Grid item className={classes.backButton} xs={12} md={4} lg={4}>
                <Button variant={"contained"} color="primary"
                    onClick={() => {
                        props.props("Range", "", "Derivative")
                    }}>
                    Back
                </Button>
            </Grid>
            {(loading && (
                <Grid container direction="row" justify="center">
                    <Card className={classes.root}>
                        <CardActionArea>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    <CircularProgress />
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))}
            {(loading == false && (exterior.value == "" || interior.value == "") && (
                <Grid container direction="row" justify="center">
                    <Card className={classes.root}>
                        <CardActionArea>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    No Colours for this model
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))}
            {(loading == false && (exterior.value != "" && interior.value != "") && (
                <Grid container direction="row" justify="center">
                    <Grid item xs={12}>
                        <Typography variant="h5">What is your preferred colour?</Typography>
                    </Grid>
                    <Grid item className={classes.stepPadding} xs={12}>
                        <Card className={classes.content} elevation={0}>
                            <Typography align={"center"} variant={"h6"} style={{ fontWeight: 700 }}>
                                Exterior
                            </Typography>
                            <CardMedia className={classes.cardImage}
                                component="img"
                                alt={coloursExterior[imageExterior]['title']}
                                image={coloursExterior[imageExterior]['image']}
                            />
                            <CardContent className={classes.colorsContainer}>
                                {coloursExterior.map((colour, index) => (
                                    <Box className={classes.stepPaddingColours} key={index}>
                                        <Button value={colour.title} style={{
                                            backgroundColor: colour.colour, height: '70px',
                                            width: '70px', minWidth: '70px', borderRadius: '50%'
                                        }} variant={"contained"}
                                            onClick={(v) => {
                                                setCheckedExterior(v.currentTarget.value)
                                                context.setTransmissionData({})

                                                SET_FINAL_PRODUCT("", "", colour["image"], "",
                                                    {
                                                        value: colour['title'],
                                                        price: colour['price'],
                                                        id: colour['id']
                                                    }, interior, "", "", "", "", "");
                                                SET_BANNER("", "", "", colour["image"])

                                                setImageExterior(index);
                                                setExterior({ value: colour['title'], price: +colour['price'] })
                                            }}
                                        >
                                            {checkedExterior == colour.title ?
                                                <CheckIcon style={{ color: invertColor(colour.colour) }} /> : ""}
                                        </Button>
                                        <Typography className={classes.colourName}>{colour.title}</Typography>
                                        <Typography className={classes.colourPrice}><CurrencyValue value={colour.price} /></Typography>

                                    </Box>
                                ))}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item className={classes.stepPadding} xs={12}>
                        <Card className={classes.content} elevation={0}>
                            <Typography align={"center"} variant={"h6"} style={{ fontWeight: 700 }}>
                                Interior
                            </Typography>

                            <CardMedia className={classes.cardImage}
                                component="img"
                                alt={coloursInterior[imageInterior]['title']}
                                image={coloursInterior[imageInterior]['image']}
                            />
                            <CardContent>
                                {coloursInterior.map((colourI, index) => (
                                    <Box className={classes.stepPaddingColours} key={index}>
                                        <Button value={colourI.title} style={{
                                            backgroundColor: colourI.colour, height: '70px',
                                            width: '70px', minWidth: '70px', borderRadius: '50%'
                                        }} variant={"contained"}
                                            onClick={(v) => {
                                                setCheckedInterior(v.currentTarget.value)
                                                context.setTransmissionData({})
                                                SET_FINAL_PRODUCT("", "", "", "",
                                                    exterior, {
                                                    value: colourI['title'],
                                                    price: colourI['price'],
                                                    id: colourI['id']
                                                },
                                                    "", "", "", "", "");
                                                SET_BANNER("", "", "", "");

                                                setImageInterior(index);
                                                setInterior({ value: colourI['title'], price: +colourI['price'] })
                                            }}
                                        >
                                            {checkedInterior == colourI.title ?
                                                <CheckIcon style={{ color: invertColor(colourI.colour) }} /> : ""}
                                        </Button>
                                        <Typography className={classes.colourName}>Colour Name</Typography>
                                        <Typography className={classes.colourPrice}>Free</Typography>
                                    </Box>
                                ))}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item className={classes.buttonsBelow} xs={12} md={4} lg={12}>
                        <Card className={classes.content} elevation={0}>
                            <Grid container direction="row" justify="center">
                                <Typography gutterBottom variant="caption">
                                    *Please note metallic colours have an additional cost
                                </Typography>
                            </Grid>
                            <CardContent>
                                <Grid container direction="row" justify="center">
                                    <Button variant={"contained"} color="primary"
                                        disabled={!isDisabled()}
                                        onClick={() => {
                                            SET_BANNER("", "", "Select your transmission", "");
                                            props.props("Colour", "", "Transmission")
                                        }}>
                                        Continue
                                    </Button>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            ))}
        </React.Fragment>
    );
}
