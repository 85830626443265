import React, { useState, useContext, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Image from 'material-ui-image'
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import { ConfiguratorContext } from "./contexts/ConfiguratorContext";
import logo from './assets/logo.png';
import mobileLogo from './assets/mobile-logo.svg'
import Container from "@material-ui/core/Container";
import { useMediaQuery as mq } from 'react-responsive'
import axios from "axios";
import { BASE_URI, SET_BANNER, SET_FINAL_PRODUCT, SET_INFORMATION } from "./Global";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    image: {
        maxWidth: 160,
        '@media screen and (max-width: 717px)': {
            marginTop: '18px'
        }
    },
    imageSingle: {
        height: 50,
        width: "70%",
    },
    grid: {
        paddingTop: 5,
    },
    paddingTitle: {
        paddingTop: 15
    },
    logo: {
        justifyContent: 'flex-end'
    }
}));

export default function TopBarContainer() {
    const classes = useStyles();

    let { ...context } = useContext(ConfiguratorContext);
    const [information, setInformation] = useState({})
    const isMobile = mq({ query: '(max-width: 717px)' })
    const { dealerModel } = useParams();

    const invertColor = (bgColor, lightColor, darkColor) => {
        let color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
        let r = parseInt(color.substring(0, 2), 16); // hexToR
        let g = parseInt(color.substring(2, 4), 16); // hexToG
        let b = parseInt(color.substring(4, 6), 16); // hexToB
        return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
            darkColor : lightColor;
    }

    useEffect(() => {
        let params = { dealerId: dealerModel }

        axios({
            method: 'GET',
            url: `${BASE_URI}/dealerWebsites/${params.dealerId}`
        }).then((response) => {
            let s = JSON.parse(response.data.styles)
            var buttonColor = '#ffffff';

            if (s) {
                buttonColor = s.buttonColor;
            }

            setInformation(response.data);

            document.title = `${response.data.title} Configurator`

            var link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }

            link.href = response.data.websiteUrl + '/favicon.ico';

            SET_INFORMATION(response.data.title, response.data.subTitle,
                response.data.image, response.data.name,
                "Let's go...", buttonColor, invertColor(buttonColor, "#ffffff", "#000000"),
                "", "", "", dealerModel);
        });

    }, []);

    return (
        <div className={classes.root}>
            <AppBar position="sticky" color={"transparent"}>
                <Container maxWidth="lg">
                    <Grid container direction="row" className={classes.grid} justify={"center"}>

                        {information.image && (
                            <React.Fragment>
                                <Grid item xs={4} md={3} lg={3}>
                                    <Grid container direction="row">
                                        <Grid item xs={1} md={11} lg={10}>
                                            <Toolbar>
                                                {<img src={information.image} className={classes.image} alt="logo" />}
                                            </Toolbar>
                                        </Grid>
                                        <Grid item xs={1}>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={8} md={9} lg={9}>
                                    <Grid container direction="row" style={{ paddingTop: 10 }}>
                                        <Grid item xs={1} md={2} lg={2}>
                                        </Grid>
                                        <Grid item xs={11} md={10} lg={10}>
                                            <Typography variant="h5" align={'right'} style={{
                                                // color: INFORMATION.buttonColor,
                                            }}>
                                                {information.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1} md={2} lg={2}>
                                        </Grid>
                                        <Grid item xs={11} md={10} lg={10}>
                                            <Typography variant="subtitle1" align={'right'} color="textSecondary">
                                                {information.subTitle}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                       
                    </Grid>
                </Container>
            </AppBar>
        </div>
    );
}