import React, { useState, useContext, useEffect } from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {ConfiguratorContext} from "../../shared/contexts/ConfiguratorContext";
import {BANNER} from "../../shared/Global";
import GridListTile from "@material-ui/core/GridListTile";
import GridList from "@material-ui/core/GridList";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URI, SET_BANNER, SET_FINAL_PRODUCT, SET_LEAD } from "../../shared/Global";
import { CurrencyValue } from "../../shared/TextMask";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        borderBottom: '1px solid #dedede'
    },
    uppercase: {
        textTransform: 'uppercase'
    }
}));

export default function BannerContainer() {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const { dealerModel, modelId, leadId } = useParams();

    console.log(useParams());

    useEffect(() => {

        if (leadId) {
            SET_LEAD(leadId);
        }

        if (modelId) {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();


            axios.get(`${BASE_URI}/dealerModels/${modelId}`, {
                cancelToken: source.token
            }).then((result) => {
                var car = result.data;

                SET_FINAL_PRODUCT(car.title, car.model, "", car.price, "",
                    "", "", "", car['id'], "", "");

                SET_BANNER(car.make + " " + car.title, car.model,
                    `Select your ${car.model} model`, car.image)
            });
        }

    },[]);

    useEffect(() => {
    }, [BANNER, BANNER.price, BANNER.title, BANNER.subTitle,
        BANNER.selectTitle, BANNER.image]);

    return (
        <React.Fragment>
            <Card className={classes.root} elevation={0}>
                <CardContent>
                    <Grid container direction="row">
                        {/* <Grid item xs={5} md={3} lg={5}>
                            <Typography variant="h6" color="textSecondary">
                                {BANNER['selectTitle']}
                            </Typography>
                        </Grid> */}
                        {/* <Grid item xs={3} md={3} lg={4}>
                            <CardMedia
                                component="img"
                                alt={BANNER['title']}
                                height="140"
                                image={BANNER['image']}
                            />
                        </Grid> */}
                        <Grid item xs={12}>
                            {/* <Typography gutterBottom variant="h5" align={"right"} component="h2">
                              <strong>Starting From:</strong>
                            </Typography> */}
                            <Typography variant="h5" component="h2" align={'center'}>
                                {BANNER['price'] == 0 ? <></> : <>
                                    Total price <CurrencyValue value={BANNER['price']} /></>}
                            </Typography>
                            <Typography variant="h6" align={'center'} className={classes.uppercase} style={{fontWeight: 400, fontSize: '1rem'}}>
                              <span>{BANNER['title']} {BANNER['subTitle']}</span>
                            </Typography>
                            
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </React.Fragment>
    )
        ;
}
