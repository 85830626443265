import React, { useState, useContext, useEffect } from "react";
import './App.css';
import { Route, useHistory, matchPath } from 'react-router-dom';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import axios from "axios";
import { BASE_URI, FINAL_PRODUCT, SET_BANNER, SET_FINAL_PRODUCT } from "./shared/Global";
import TopBarContainer from "./shared/TopBarContainer";
import TopBarRenaultDigitalContainer from "./shared/TopBarRenaultDigitalContainer";
import { ConfiguratorContext } from "./shared/contexts/ConfiguratorContext";
import Container from "@material-ui/core/Container";
import RangeContainer from "./components/Builder/RangeContainer";
import BuilderContainer from "./components/Builder/BuilderContainer";
import { useParams } from "react-router-dom";
import YourBrandContainer from "./components/Builder/YourBrandContainer";
import RenaultBrandContainer from "./components/Builder/RenaultBrandContainer";
import GetInfoContainer from "./components/PostBuilder/GetInfoContainer";
import { SET_INFORMATION, SET_LEAD } from "./shared/Global";
import TradeInQuestionContainer from "./components/PostBuilder/TradeInQuestionContainer";
import TradeInContainer from "./components/PostBuilder/TradeInContainer";
import FinanceQuestionContainer from "./components/PostBuilder/FinanceQuestionContainer";
import CashApplicationContainer from "./components/PostBuilder/CashApplicationContainer";
import CashApplicationDoneContainer from "./components/PostBuilder/CashApplicationDoneContainer";
import SummaryDoneContainer from "./components/PostBuilder/SummaryDoneContainer";
import EnquireDoneContainer from "./components/PostBuilder/EnquireDoneContainer";
import PQContainer from "./components/PostBuilder/PQContainer";
import RenaultPQContainer from "./components/PostBuilder/RenaultPQContainer";
import PQContainerAuto from "./components/PostBuilder/PQContainerAuto";
import PQContainerDone from "./components/PostBuilder/PQContainerDone";
import PQContainerDecline from "./components/PostBuilder/PQContainerDecline";
import ApplicationContainer from './components/FinanceApplication/ApplicationContainer'
import { SnackbarProvider } from 'notistack';
import DocumentUploadForm from './components/DocumentsContainer/DocumentUploadForm'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import CssBaseline from '@material-ui/core/CssBaseline';
import RenaultLifeWebRegular from './fonts/RenaultLifeWeb-Regular.woff'
import RenaultLifeWebBold from './fonts/RenaultLifeWeb-Bold.woff'
import Footer from './shared/Footer';

const renaultRegular = {
    fontFamily: 'Renault',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
      local('Renault'),
      local('Renault-Regular'),
      url(${RenaultLifeWebRegular}) format('woff')
    `
}

const renaultBold = {
    fontFamily: 'Renault',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: `
      local('Renault'),
      local('Renault-Bold'),
      url(${RenaultLifeWebBold}) format('woff')
    `
}


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& .MuiTextField-root": {
            margin: theme.spacing(2),
            width: "45ch",
        },
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    appBarSpacer: {},
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
}));

function App() {
    const classes = useStyles();
    const { dealerModel } = useParams();
    let { ...context } = useContext(ConfiguratorContext);

    const [buttonColor, setButtonColor] = useState("#ffffff");
    const [textColor, setTextColor] = useState("#000000");

    const [finalProduct, setFinalProduct] = useState(localStorage.getItem("final_product") != null ?
        localStorage.getItem("final_product") : "");
    const [lead, setLead] = useState(localStorage.getItem("lead") != null ?
        localStorage.getItem("lead") : "");
    const [banner, setBanner] = useState(localStorage.getItem("banner") != null ?
        localStorage.getItem("banner") : "")
    const [information, setInformation] = useState(localStorage.getItem("information") != null ?
        localStorage.getItem("information") : "")

    const history = useHistory();

    let theme = createMuiTheme({
        typography: {
            fontFamily: [
                'Renault',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        overrides: {
            MuiButton: {
                outlinedPrimary: {
                    backgroundColor: buttonColor,
                    color: textColor
                },
                containedPrimary: {
                    backgroundColor: buttonColor,
                    color: '#000',
                    fontWeight: 700,
                    paddingLeft: 34,
                    paddingRight: 34    , 
                    '&:hover': {
                        backgroundColor: buttonColor,
                        opacity: 0.8
                    }
                },
                textSecondary: {
                    // borderBottom: `1px solid`,
                    color: '#3f51b5',

                    containedPrimary: {
                        backgroundColor: buttonColor,
                        color: textColor,
                        '&:hover': {
                            backgroundColor: buttonColor,
                            opacity: 0.8
                        }
                    }
                },
                MuiLinearProgress: {
                    barColorPrimary: {
                        backgroundColor: buttonColor
                    }
                },
                MuiGridListTileBar: {
                    root: {
                        backgroundColor: buttonColor
                    },
                    title: {
                        color: textColor
                    }
                }
            },
            MuiCssBaseline: {
                '@global': {
                  '@font-face': [renaultRegular,renaultBold],
                  body: {
                    backgroundColor: '#ffffff',
                    fontSize: '100%'
                  }
                }
            },
            MuiTypography: {
                h3: {
                    fontWeight: 700
                },
                h4: {
                    fontWeight: 700
                },
                h5: {
                    fontWeight: 700
                }
            }
        }
    });

    const invertColor = (bgColor, lightColor, darkColor) => {
        let color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
        let r = parseInt(color.substring(0, 2), 16); // hexToR
        let g = parseInt(color.substring(2, 4), 16); // hexToG
        let b = parseInt(color.substring(4, 6), 16); // hexToB
        return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
            darkColor : lightColor;
    }

    const getParamFromPath = (path, paramName) => {

        const match = matchPath(history.location.pathname, {
            path: path
        });

        if (match && match.params[paramName]) {
            return match.params[paramName];
        }

        return undefined;
    }

    const isRenaultDigital = () => {
        if (window.location.href.toLowerCase().indexOf('renaultdigital') > 0) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        let params = { dealerId: dealerModel }

        axios({
            method: 'GET',
            url: `${BASE_URI}/dealerWebsites/${params.dealerId}`
        }).then((response) => {
            let s = JSON.parse(response.data.styles)
            var buttonColor = '#ffffff';

            if (s) {
                buttonColor = s.buttonColor;
            }

            setButtonColor(buttonColor)
            setTextColor(invertColor(buttonColor, "#ffffff", "#000000"))

            SET_INFORMATION(response.data.title, response.data.subTitle,
                response.data.image, response.data.name,
                "Let's go...", buttonColor, invertColor(buttonColor, "#ffffff", "#000000"),
                "", "", "", dealerModel);

            document.title = `${response.data.title} Configurator`


            if (lead !== "" && lead !== "{}") {
                let parsedLead = JSON.parse(lead);

                SET_LEAD(parsedLead.id);

                let temp = JSON.parse(finalProduct);
                SET_FINAL_PRODUCT(temp.title, temp.subTitle, temp.image, temp.derivativePrice, temp.colourExterior,
                    temp.colourInterior, temp.transmission, temp.accessories, temp.carModelId, temp.client, temp.carVariantId);

                context.setProduct({ client: temp.client });

                let tempBanner = JSON.parse(banner);
                SET_BANNER(tempBanner.title, tempBanner.subTitle, tempBanner.selectedTitle, tempBanner.image);

                let tempInformation = JSON.parse(information);
                SET_INFORMATION(tempInformation.title, tempInformation.subTitle, tempInformation.image,
                    tempInformation.name, tempInformation.queryTitle, tempInformation.buttonColor,
                    tempInformation.textColor, tempInformation.manual, tempInformation.automatic,
                    tempInformation.dealerVariantId, tempInformation.dealerId)
            }
            else {
                let leadId = 0;

                if (history.location.pathname.indexOf('builder') > 0) {
                    leadId = getParamFromPath("/:dealerModel/:modelId/builder/:leadId", "leadId");
                }
                else {
                    leadId = getParamFromPath("/:dealerModel/range/:leadId", "leadId");
                }

                if (leadId) {
                    SET_LEAD(parseInt(leadId));

                    const CancelToken = axios.CancelToken;
                    const source = CancelToken.source();

                    axios.get(`${BASE_URI}/leads/${leadId}`, {
                        cancelToken: source.token
                    }).then((result) => {
                        var lead = result.data;

                        var client = {
                            firstName: lead.name,
                            name: lead.name,
                            email: lead.emailAddress,
                            telephone: lead.phone
                        };

                        context.setProduct({ client: client });

                        SET_FINAL_PRODUCT("", "", "", "",
                            "", "", "", "", "",
                            client, "");
                    });

                }
            }
            
        })
    }, [dealerModel])

    return (
        <MuiThemeProvider>
            <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3} anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}>
                    <CssBaseline />
                    <main className={classes.content}>
                        {!isRenaultDigital() && <TopBarContainer />}
                        {isRenaultDigital() && <TopBarRenaultDigitalContainer />}
                        <div className={classes.appBarSpacer} />
                        <Container maxWidth="lg" className={classes.container}>
                            <Route exact
                                path="/:dealerModel/range"
                                render={(props) => <RangeContainer />}
                            />
                            <Route exact
                                path="/:dealerModel/range/:leadId"
                                render={(props) => <RangeContainer />}
                            />
                            <Route exact
                                path="/:dealerModel/:modelId/builder/:leadId"
                                render={(props) => <BuilderContainer />}
                            />
                            <Route exact
                                path={`/:dealerModel/:modelId/builder`}
                                render={(props) => <BuilderContainer />}
                            />
                            
                            <Route exact
                                path={`/:dealerModel/:modelId/build`}
                                render={(props) => <YourBrandContainer />}
                            />

                            <Route exact
                                path={`/:dealerModel/renaultDigital/:renaultDigitalId`}
                                render={(props) => <RenaultBrandContainer />}
                            />

                            <Route exact
                                path={`/:dealer/step1/renaultDigital/:renaultDigitalId`}
                                render={(props) => <RenaultPQContainer />}
                            />

                            <Route exact
                                path={`/:dealerModel/:modelId/:query/info`}
                                render={(props) => <GetInfoContainer />}
                            />

                            <Route exact
                                path={`/:dealerModel/:modelId/:query/finance/:applicationStep/:leadId`}
                                render={(props) => <ApplicationContainer {...props} />}
                            />

                            <Route exact
                                path={`/:dealer/step1`}
                                render={(props) => <PQContainer />}
                            />

                            <Route exact
                                path={`/:dealer/step1/:leadGuid`}
                                render={(props) => <PQContainer />}
                            />

                            <Route exact
                                path={`/:dealerModel/pq/:idNumber`}
                                render={(props) => <PQContainerAuto />}
                            />

                            <Route exact
                                path={`/pqComplete/leadId/:leadId/a/:a/i/:i`}
                                render={(props) => <PQContainerDone />}
                            />

                            <Route exact
                                path={`/:dealerModel/:modelId/:query/pqDecline/:leadId`}
                                render={(props) => <PQContainerDecline />}
                            />

                            <Route exact
                                path={`/:dealerModel/:modelId/:query/documents/:leadId`}
                                render={(props) => <DocumentUploadForm {...props} />}
                            />

                            <Route exact
                                path={`/:dealerModel/:modelId/:query/tradeInQuestion/:leadId`}
                                render={(props) => <TradeInQuestionContainer />}
                            />
                            <Route exact
                                path={`/:dealerModel/:modelId/:query/tradeIn/:leadId`}
                                render={(props) => <TradeInContainer />}
                            />
                            <Route exact
                                path={`/:dealerModel/:modelId/:query/financeQuestion/:leadId`}
                                render={(props) => <FinanceQuestionContainer />}
                            />
                            <Route exact
                                path={`/:dealerModel/:modelId/:query/cash/:leadId`}
                                render={(props) => <CashApplicationContainer />}
                            />
                            <Route exact
                                path={`/:dealerModel/:modelId/:query/cashDone/:leadId`}
                                render={(props) => <CashApplicationDoneContainer />}
                            />
                            <Route exact
                                path={`/:dealerModel/:modelId/summary/done/:leadId`}
                                render={(props) => <SummaryDoneContainer />}
                            />
                            <Route exact
                                path={`/:dealerModel/:modelId/enquire/done/:leadId`}
                                render={(props) => <EnquireDoneContainer />}
                            />
                        </Container>
                        {isRenaultDigital() && <Footer />}
                    </main>
                </SnackbarProvider>
            </ThemeProvider>
        </MuiThemeProvider>
    );
}

export default App;